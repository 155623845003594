/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faEdit, faSave } from '@fortawesome/pro-solid-svg-icons'
import { type z } from 'zod'
import { type Control, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'

import { Badge, Button } from '@olino/design-system'

type EditableInfoProps<TData extends { [x: string]: any }> = {
  schema: z.ZodType<TData>
  value: TData
  displayValue: (value: TData) => string

  label: string
  onCancel?: () => void
  onSave?: (data: TData) => void
  input: (params: { control: Control<TData, any> }) => React.ReactNode
}

export const SummaryEditableProperty = <TData extends { [x: string]: any }>({
  schema,
  value,
  displayValue,

  label,
  input,

  onCancel,
  onSave,
}: EditableInfoProps<TData>) => {
  const { control, handleSubmit } = useForm<TData>({
    resolver: zodResolver(schema),
    // @ts-expect-error Can't be bothered to fix this
    defaultValues: value,
  })

  const [isEditing, setIsEditing] = useState(false)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _onSave = handleSubmit((data) => {
    onSave?.(data)
    setIsEditing(false)
  })

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _onCancel = () => {
    onCancel?.()
    setIsEditing(false)
  }

  return (
    <form className="grid grid-cols-1 gap-3 md:grid-cols-3" onSubmit={_onSave}>
      <div className="font-medium">{label}</div>

      <div className="flex items-center gap-3 md:col-span-2">
        {isEditing && input ? (
          <div className="w-full">{input({ control })}</div>
        ) : (
          <Badge className="normal-case">{displayValue(value)}</Badge>
        )}

        <div className="flex items-center gap-1">
          {!isEditing ? (
            <Button
              size="sm"
              variant="text"
              onClick={(e) => {
                e.preventDefault()
                setIsEditing(true)
              }}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          ) : (
            <>
              <Button type="submit" size="sm" variant="text" color="primary">
                <FontAwesomeIcon icon={faSave} />
              </Button>

              <Button size="sm" variant="text" color="danger" onClick={() => _onCancel()}>
                <FontAwesomeIcon icon={faCancel} />
              </Button>
            </>
          )}
        </div>
      </div>
    </form>
  )
}

import { Switch } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import { noop } from '../utils'

interface Props {
  value: boolean
  onChange?: (value: boolean) => void
  className?: string
  label?: string
  error?: boolean
  disabled?: boolean
  name?: string
  labelPosition?: 'left' | 'right'
  labelClassName?: string
}

export const Checkbox: React.FC<Props> = ({
  value,
  onChange = noop,
  className,
  label,
  error = false,
  disabled = false,
  name,
  labelPosition = 'left',
  labelClassName,
}) => (
  <Switch.Group
    as="div"
    className={twMerge(
      'my-1 flex items-center',
      labelPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
      className
    )}
  >
    {label && (
      <Switch.Label
        className={
          labelClassName
            ? twMerge(labelClassName, error && 'text-danger-400', disabled && 'text-grey-400')
            : twMerge(
                'ml-0.5 mr-2 flex-shrink text-sm transition-colors',
                error && 'text-danger-400',
                disabled && 'text-grey-400',
                !error && !disabled && 'text-grey-600 focus-within:text-primary-950'
              )
        }
      >
        {label}
      </Switch.Label>
    )}

    <Switch
      checked={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      className={twMerge(
        'relative inline-flex h-6 w-6 flex-shrink-0 items-center rounded-md bg-grey-200',
        disabled && 'cursor-not-allowed'
      )}
    >
      {label && <span className={twMerge('sr-only')}>{label}</span>}
      <div
        className={twMerge(
          'absolute inset-0 transform rounded-md bg-primary-500 transition duration-200 ease-in-out',
          value ? 'scale-100 opacity-100' : 'scale-50 opacity-0',
          error && '',
          disabled && 'bg-grey-400'
        )}
      >
        <div
          className={twMerge(
            'absolute inset-0 left-1/2 top-1/2 -mt-[2px] h-[62%] w-[35%] -translate-x-1/2 -translate-y-1/2 rotate-45'
          )}
        >
          <div
            className={twMerge(
              'absolute bottom-0 h-[2px] rounded-md bg-white transition-all duration-200 ease-in-out',
              value ? 'w-full delay-200' : 'w-0'
            )}
          />
          <div
            className={twMerge(
              'absolute bottom-0 right-0  w-[2px] rounded-md bg-white transition-all duration-150 ease-in-out',
              value ? 'h-full delay-500' : 'h-0'
            )}
          />
        </div>
      </div>
    </Switch>
  </Switch.Group>
)

Checkbox.displayName = 'Checkbox'

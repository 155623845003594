import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { faCheckCircle, faCaretDown } from '@fortawesome/pro-solid-svg-icons'

import { noop } from '../utils'

interface SelectProps<T> {
  label?: string
  error?: boolean

  value?: T
  onChange?: (value: T | undefined) => void

  getOptionLabel?: (option: T) => string
  getOptionDisabled?: (option: T) => boolean
  getOptionKey?: (option: T) => string | number
  render?: (option: T) => React.ReactNode

  options: T[]
  disabled?: boolean
  className?: string
}

// TODO: FIX --> OPTIONS ARE BROKEN

export const Select = <T,>({
  label,
  error,
  options,
  disabled,
  className,

  value,
  onChange = noop,

  getOptionLabel = (option) => `${option}`,
  getOptionDisabled = () => false,
  getOptionKey = (option) => `${option}`,
  render = (option) => getOptionLabel(option),
}: SelectProps<T>) => {
  return (
    <div className={clsx('group relative', className)}>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label
                className={clsx(
                  'ml-0.5 text-sm transition-colors',
                  error && 'text-red-400',
                  disabled && 'text-grey-400',
                  !error && !disabled && 'text-grey-600 focus-within:text-primary-950'
                )}
              >
                {label}
              </Listbox.Label>
            )}
            <Listbox.Button
              className={clsx(
                'group relative flex w-full flex-row items-center justify-center rounded-md px-3 py-2 transition-colors',
                'border bg-white focus-within:-m-px focus-within:border-2 focus-within:shadow-sm',
                error &&
                  !disabled &&
                  'border-red-300 focus-within:border-red-500 group-hover:border-red-400 group-hover:focus-within:border-red-500',
                disabled && 'pointer-events-none border-grey-300 bg-grey-100',
                !disabled &&
                  'group:border-grey-400 group:focus-within:border-primary-950 border-grey-300 focus-within:border-primary-950'
              )}
              // @ts-expect-error - We will be rewriting this component soon
              disabled={disabled}
            >
              <span
                className={clsx(
                  'inline-block max-w-full flex-grow cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap p-0 text-left',
                  value == null && 'text-black text-opacity-40'
                )}
              >
                {value != null ? getOptionLabel(value) : !disabled && 'Choisissez une option'}
              </span>
              <FontAwesomeIcon
                icon={faCaretDown}
                className={clsx(
                  'ml-3 transform text-grey-500 transition-transform duration-100',
                  open && 'rotate-180'
                )}
              />
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute inset-x-0 z-20 mt-2 overflow-hidden rounded-md border border-grey-300 bg-white py-1 shadow-sm">
                {options.map((option: T) => (
                  <Listbox.Option
                    key={getOptionKey(option)}
                    value={option}
                    disabled={getOptionDisabled(option)}
                    as={Fragment}
                  >
                    {({ active, selected, disabled }) => (
                      <li
                        className={clsx(
                          'cursor relative flex flex-row py-2 pl-3 pr-10',
                          disabled && 'text-black text-opacity-30',
                          selected && 'bg-primary-500 text-white',
                          active && !selected && 'bg-grey-100 text-black',
                          !selected && !active && 'bg-white text-black'
                        )}
                      >
                        {render(option)}
                        {selected && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <FontAwesomeIcon icon={faCheckCircle} className="h-5 w-5" />
                          </span>
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}

Select.displayName = 'Select'

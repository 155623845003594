/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react'

import { cn } from '../utils'
import { Hint } from '../hint/Hint'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  error?: boolean
  label?: string
  hint?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, startAdornment, label, hint, id, endAdornment, disabled, error, ...rest }, ref) => (
    <div className={cn('group', className)}>
      <label htmlFor={id} className="space-y-1">
        <div className="flex items-center gap-2">
          {label && (
            <span
              className={cn(
                'ml-0.5 text-sm transition-colors',
                error && 'text-danger-400',
                disabled && 'text-gray-400',
                !error && !disabled && 'text-grey-600/90 focus-within:text-primary-950'
              )}
            >
              {label}
            </span>
          )}
          {hint && <Hint text={hint} />}
        </div>

        <div
          className={cn(
            'group relative flex w-full flex-row items-center justify-center rounded-lg py-2 transition-colors',
            'bg-white outline outline-1 focus-within:shadow-sm focus-within:outline-2',
            startAdornment ? 'pl-2' : ' pl-3',
            endAdornment ? 'pr-2' : ' pr-3',
            disabled && 'bg-gray-100 outline-grey-300',
            error &&
              !disabled &&
              'outline-danger-300 focus-within:outline-danger-500 group-hover:outline-danger-400 group-hover:focus-within:outline-danger-500',
            !error &&
              !disabled &&
              'group-hover:outline-gray-400 outline-grey-300 focus-within:outline-primary-700 group-hover:focus-within:outline-primary-700'
          )}
        >
          {startAdornment && <div className={cn('mr-2 text-grey-300')}>{startAdornment}</div>}

          <input
            className={cn(
              'min-w-0 flex-grow border-0 bg-transparent p-0 placeholder-grey-300 focus:border-0 focus:outline-none focus:ring-0'
            )}
            id={id}
            disabled={disabled}
            size={1}
            {...rest}
            ref={ref}
          />

          {endAdornment && <div className={cn('ml-2 text-grey-300')}>{endAdornment}</div>}
        </div>
      </label>
    </div>
  )
)
Input.displayName = 'Input'

import { faArrowDownToLine, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'
import { useMemo } from 'react'
import { saveAs } from 'file-saver'

import {
  useGetQuotationDocumentQuery,
  type ProductEnum,
  QuotationDocument,
} from '../../../generated/graphql'
import { PRODUCTS } from '../../../constants/products'
import b64toBlob from '../../../utils/b64toBlob'
import { track } from '../../../utils/analytics'
import { useQuotation } from '../../../onboarding/context'

import { Button } from '@olino/design-system'

const DocumentItem = ({
  name,
  product,
  document,
}: {
  name: string
  product: ProductEnum
  document: QuotationDocument
}) => {
  const { quotation } = useQuotation()

  const { isFetching, refetch } = useGetQuotationDocumentQuery(
    {
      quotationId: quotation.id,
      product,
      document,
    },
    {
      enabled: false,
    }
  )

  const onClick = async () => {
    track({
      event: 'downloads_document',
      quotation_id: quotation.id,
      document_type: document.toLowerCase(),
    })

    const res = await refetch()
    if (res.data?.getQuotationDocument)
      saveAs(
        b64toBlob(res.data.getQuotationDocument, 'application/pdf'),
        `${name} - ${PRODUCTS[product].name}.pdf`
      )
  }

  return (
    <li className="w-full">
      <Button
        variant="link"
        className="flex w-full flex-row items-center p-2 font-normal"
        onClick={() => onClick()}
      >
        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
        <span className="text-sm">{name}</span>
        <div className="spacer flex-1" />
        <FontAwesomeIcon
          icon={isFetching ? faSpinnerThird : faArrowDownToLine}
          className={twMerge('ml-2', isFetching && 'animate-spin')}
        />
      </Button>
    </li>
  )
}

const DocumentsSection = ({
  className,
  showQuote,
  ...rest
}: React.ComponentProps<'section'> & { showQuote?: boolean }) => {
  const { quotation } = useQuotation()

  const product = useMemo(() => quotation.details.products?.at(0), [quotation])

  if (!product) return null

  return (
    <section {...rest} className={twMerge('flex w-full flex-col gap-3', className)}>
      <ul className="divide-y">
        <DocumentItem
          name="Conditions générales"
          product={product}
          document={QuotationDocument.GeneralTerms}
        />

        <DocumentItem
          name="Document d'information"
          product={product}
          document={QuotationDocument.Ipid}
        />

        {showQuote && (
          <DocumentItem name="Devis" product={product} document={QuotationDocument.Quotation} />
        )}
      </ul>
    </section>
  )
}

export default DocumentsSection

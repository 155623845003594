import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { v4 as uuid } from 'uuid'
import { twMerge } from 'tailwind-merge'

import { Tooltip } from '../tooltip/Tooltip'

type HintProps = {
  text?: string
  className?: string
}

export const Hint = React.forwardRef<HTMLDivElement, React.PropsWithChildren<HintProps>>(
  ({ text, children, className }, ref) => {
    const id = uuid()
    return (
      <>
        <span data-tooltip-id={id} className={twMerge('font-medium', className)} ref={ref}>
          <FontAwesomeIcon icon={faInfoCircle} className="text-grey-400" />
        </span>
        <Tooltip id={id} classNameArrow="hidden">
          {text && <span>{text}</span>}
          {children}
        </Tooltip>
      </>
    )
  }
)

import { faHandshake, faPlaneDeparture, faSignature } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useParams } from 'react-router'
import useWindowSize from 'react-use/lib/useWindowSize'

import { type OnboardingPage } from '../routes'
import useMe from '../../utils/useMe'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { track } from '../../utils/analytics'

import { Button, Card, CardActions, CardContent } from '@olino/design-system'

const Main = () => {
  const { id } = useParams()
  const { width, height } = useWindowSize()
  const user = useMe()
  const navigate = useNavigate()

  const [hasUserRead, setHasUserRead] = useState(false)

  // Adding a timer to make sure the user has read the message
  useEffect(() => {
    setTimeout(() => {
      setHasUserRead(true)
    }, 2000)
  }, [])

  useEffect(() => {
    track({
      event: 'visited_success_page',
      quotation_id: id,
    })
  }, [id])

  useEffect(() => {
    // Clearing the quotation from the local storage to avoid the user going back to a quotation that's already completed
    localStorage.removeItem('lastQuotationId')
  }, [])

  return (
    <>
      <Confetti width={width} height={height} recycle={false} className="fixed" />

      <OnboardingTitle.H1>
        <span className="capitalize">{user?.firstName}</span>, bienvenue chez Olino !
      </OnboardingTitle.H1>

      <div className="mb-5 font-medium">
        Votre souscription a bien été finalisée, voici les prochaines étapes.
      </div>

      <Card className="sm:pt-4">
        <CardContent>
          <div className="grid max-w-4xl grid-cols-1 space-y-4 text-center text-sm sm:gap-6 md:grid-cols-3 md:flex-row md:space-y-0">
            <div>
              <div className="mx-auto mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-primary-200 text-primary-900">
                <FontAwesomeIcon icon={faSignature} className="h-6 w-6" />
              </div>
              <h2 className="font-serif mb-3 text-xl font-black">Récupérez votre attestation</h2>
              <p>
                Vous pourrez récupérer votre attestation dès la date d'effet de votre contrat. Si
                vous avez choisi la date du jour, votre attestation est déjà disponible.
                {/* <span className="font-bold">
                  Dès la signature, vous pourrez retrouver votre attestation et l'ensemble de vos
                  documents sur le Cockpit.
                </span> */}
              </p>
            </div>
            <div>
              <div className="mx-auto mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-primary-200 text-primary-900">
                <FontAwesomeIcon icon={faPlaneDeparture} className="h-6 w-6" />
              </div>
              <h2 className="font-serif mb-3 text-xl font-black">Découvrez le Cockpit</h2>
              <p>
                Il vous permettra de gérer vos couvertures, retrouvez votre attestation, vos
                factures et de rester en contact avec votre Account Manager.
              </p>
            </div>
            <div>
              <div className="mx-auto mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-primary-200 text-primary-900">
                <FontAwesomeIcon icon={faHandshake} className="h-6 w-6" />
              </div>
              <h2 className="font-serif mb-3 text-xl font-black">Découvrez nos partenaires</h2>
              <p>
                Expert comptable, banque en ligne, financements. Nous avons négocié pour vous les
                meilleures offres, alors profitez en !
              </p>
            </div>
          </div>
        </CardContent>

        <CardActions>
          <div className="mt-6 flex flex-col items-center justify-center gap-6 sm:flex-row">
            <Button disabled={!hasUserRead} variant="outlined" onClick={() => navigate('/')}>
              Voir mes contrats
            </Button>
            <Button onClick={() => navigate('/onboarding')}>Nouveau devis</Button>
          </div>
        </CardActions>
      </Card>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FieldError } from 'react-hook-form'

interface FormErrorProps {
  error?: FieldError | string
}

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
  if (!error) return null

  const message = typeof error === 'string' ? error : error.message

  return (
    <p className="mt-1 inline-flex items-center text-xs font-medium text-danger-500">
      <FontAwesomeIcon icon={faCircleExclamation} className="mr-1" />
      {message}
    </p>
  )
}

import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useQuotation } from '../context'
import { ProductEnum } from '../../generated/graphql'
import { type OnboardingPage } from '../routes'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingButtons } from '../components/OnboardingButtons'
import { track } from '../../utils/analytics'
import { TipsCard } from '../components/TipsCard'

import { RadioGroup } from '@olino/design-system'

const Main = () => {
  const { id } = useParams()
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const [turnoverOverHalf, setTurnoverOverHalf] = useState<boolean>(
    quotation.details.products?.at(0) === ProductEnum.PublicLiabilityEcomm
  )

  const onSubmit = () => {
    track({
      event: 'submitted_ecommerce_turnover',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          products: [
            turnoverOverHalf ? ProductEnum.PublicLiabilityEcomm : ProductEnum.PublicLiability,
          ],
        },
      })
    )
  }

  useEffect(() => {
    track({
      event: 'visited_ecommerce_turnover',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>
        En moyenne, quelle proportion de votre chiffre d'affaires est réalisée en&nbsp;ligne&nbsp;?
      </OnboardingTitle.H1>

      <div className="space-y-8">
        <div className="space-y-3">
          <RadioGroup
            label="Vendez-vous plus de 70% de vos produits en ligne ?"
            options={[true, false]}
            value={turnoverOverHalf}
            getOptionLabel={(option) => (option ? 'Oui, plus de 70%' : 'Non, moins de 70%')}
            onChange={setTurnoverOverHalf}
            direction="col"
          />
        </div>

        <OnboardingButtons>
          <OnboardingNextButton onClick={onSubmit} />
        </OnboardingButtons>
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <div className="space-y-3">
      <TipsCard
        title="Vous n'êtes pas sûr de votre chiffre d'affaires en ligne ?"
        content={[
          "Si la proportion varie significativement d'un mois sur l'autre, choisissez l'option qui correspond à votre plus haut chiffre d'affaire mensuel.",
        ]}
      />
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import graphQLClient from './graphqlClient'

import client from '@olino/auth-client/js'

/* eslint-disable import/prefer-default-export */

// custom fetcher for react query graphql codegen
// https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-react-query#usage-example-isreacthook-false
export const fetchData =
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: RequestInit['headers']
  ): (() => Promise<TData>) =>
  async () => {
    const accessToken = await client.getAccessToken()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: Record<string, any> = {
      'Content-Type': 'application/json',
      'Hasura-Client-Name': `frontend-${import.meta.env.VITE_ENV}`,
      ...(options ?? {}),
    }

    if (accessToken) headers.Authorization = `Bearer ${accessToken}`

    try {
      const data = await graphQLClient.request(query, variables, headers)
      return data
    } catch (error) {
      console.warn(error)
      console.warn('The error above happened while fetching the query: \n', query)
      throw error
    }
  }

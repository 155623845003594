import { format } from 'date-fns'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { type OnboardingPage } from '../routes'
import { type CompanySuggestion, CountryEnum } from '../../generated/graphql'
import SiretAutoComplete from '../../components/quotation/siretAutoComplete/SiretAutoComplete'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingButtons } from '../components/OnboardingButtons'
import { track } from '../../utils/analytics'

import { Button } from '@olino/design-system'

const assertValid = <T,>(value: T): T | undefined => {
  if (
    typeof value === 'string' &&
    (value.includes('[NON-DIFFUSIBLE]') || value.trim().length === 0)
  ) {
    return undefined
  }

  return value
}

const Main = () => {
  const { updateQuotation, quotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const { id } = useParams()

  const [data, setData] = useState<CompanySuggestion | undefined>(
    quotation?.details?.company?.name && quotation?.details?.company?.siret
      ? {
          name: quotation?.details?.company?.name,
          siret: quotation?.details?.company?.siret,
        }
      : undefined
  )

  const onSubmit = () => {
    track({
      event: 'submitted_company_autocomplete',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation(
        data
          ? {
              details: {
                company: {
                  name: assertValid(data.name.trim()) ?? quotation.details.company?.name,
                  siret: assertValid(data.siret.trim()) ?? quotation.details.company?.siret,
                  nafCode:
                    assertValid(data.activityCode?.trim()) ?? quotation.details.company?.nafCode,
                  legalStatus:
                    assertValid(data.legalStatus?.trim()) ?? quotation.details.company?.legalStatus,
                  foundedAt:
                    data.foundedAt && assertValid(data.foundedAt)
                      ? format(new Date(data.foundedAt), 'yyyy-MM-dd')
                      : quotation.details.company?.foundedAt,
                  address:
                    data.address?.line1 &&
                    assertValid(data.address?.line1) &&
                    data.address?.city &&
                    assertValid(data.address?.city) &&
                    data.address?.postalCode &&
                    assertValid(data.address?.postalCode)
                      ? {
                          line1: data.address.line1.trim(),
                          line2: data.address.line2?.trim(),
                          postalCode: data.address.postalCode.trim(),
                          city: data.address.city.trim(),
                          country: CountryEnum.France,
                        }
                      : quotation.details.company?.address,
                },
              },
            }
          : {
              details: {
                company: quotation.details.company,
              },
            }
      )
    )
  }

  useEffect(() => {
    track({
      event: 'visited_company_autocomplete',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Avant toute chose, trouvons votre entreprise</OnboardingTitle.H1>

      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <SiretAutoComplete
            value={data}
            onChange={setData}
            hint="Si votre société est en cours de création, il se peut que vous ne trouviez pas votre
            SIRET."
          />

          {/* {companyExists ? (
            <Link to="/auth/login" className="text-xs text-danger-900 hover:underline sm:text-sm">
              Cette entreprise existe déjà. Si vous êtes son administrateur, connectez-vous.
            </Link>
          ) : (
            <div />
            // <div className="flex items-center text-xs text-info-400 sm:text-sm">
            //   <FontAwesomeIcon icon={faInfoCircle} className="mr-3 h-4 w-4 flex-shrink-0" />
            //   <p className="text-justify font-light sm:text-left">
            //     Si votre société est en cours de création, il se peut que vous ne trouviez pas votre
            //     SIRET.
            //   </p>
            // </div>
          )} */}
        </div>

        <OnboardingButtons>
          <OnboardingNextButton
            className="order-1 sm:order-2"
            disabled={data == null}
            onClick={onSubmit}
          />

          <Button
            variant="outlined"
            asChild
            className="order-2 sm:order-1"
            onClick={() => proceed()}
          >
            <Link to="../company-details">Je n'ai pas trouvé</Link>
          </Button>
        </OnboardingButtons>
      </div>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

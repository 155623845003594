import React, { useState } from 'react'

import formatPrice from '../../../utils/formatPrice'
import { Modal } from '../../../onboarding/components/Modal'
import { FormattedPrice } from '../../../onboarding/components/FormattedPrice'
import { ShareOnboardingButton } from '../../../onboarding/components/ShareOnboardingButton'
import { useQuotation } from '../../../onboarding/context'
import { PaymentFrequencyInput } from '../../../onboarding/components/PaymentFrequencyInput'
import { PromoCodeButton } from '../../../onboarding/components/PromoCodeButton'
import { PaymentFrequency } from '../../../generated/graphql'

interface Props {
  deductible: number
  guaranteeAmount?: number
}

const CoversContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  deductible,
  guaranteeAmount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { quotation, updateQuotation } = useQuotation()

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <FormattedPrice
              size="lg"
              originalAmount={quotation.premium?.originalTotal}
              discountedAmount={quotation.premium?.discountedTotal}
            />
            <ShareOnboardingButton minified />
          </div>

          {/* <Button variant="text" className="w-fit" onClick={() => setIsModalOpen(true)}>
            Voir le détail
            <FontAwesomeIcon icon={faChevronDown} />
          </Button> */}

          <PaymentFrequencyInput
            value={quotation.details.paymentFrequency ?? PaymentFrequency.Annually}
            onChange={(pf) =>
              updateQuotation({
                details: {
                  paymentFrequency: pf,
                },
              })
            }
          />

          {!!(
            quotation.premium?.applicationFeesTotal && quotation.premium?.applicationFeesTotal > 0
          ) && (
            <div className="flex items-center justify-between text-sm">
              <span className="font-medium">Frais de dossier :</span>
              {/* <Hint>Montant ... todo ...</Hint> */}

              <span className="font-light">
                {formatPrice(quotation.premium.applicationFeesTotal, 0)}
              </span>
            </div>
          )}
        </div>

        <div className="w-full border-b border-grey-200" />

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <span className="font-bold">Franchise :</span>
              {/* <Hint>Montant ... todo ...</Hint> */}
              <span className="font-light">{formatPrice(deductible, 0)}</span>
            </div>

            {!!guaranteeAmount && (
              <div className="flex items-center justify-between">
                <span className="font-bold">Plafond de garantie :</span>
                {/* <Hint>Montant ... todo ...</Hint> */}

                <span className="font-light">{formatPrice(guaranteeAmount, 0)}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Détail de votre couverture"
      >
        {children}
      </Modal>
    </>
  )
}

export default CoversContainer

export const CoversContainerExpanded: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  deductible,
  guaranteeAmount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { quotation } = useQuotation()

  return (
    <>
      <div className="flex flex-col gap-3">
        <FormattedPrice
          size="lg"
          originalAmount={quotation.premium?.originalTotal}
          discountedAmount={quotation.premium?.discountedTotal}
        />
        {!!(
          quotation.premium?.applicationFeesTotal && quotation.premium?.applicationFeesTotal > 0
        ) && (
          <div className="flex items-center justify-between text-sm">
            <span className="font-medium">Frais de dossier :</span>
            {/* <Hint>Montant ... todo ...</Hint> */}

            <span className="font-light">
              {formatPrice(quotation.premium.applicationFeesTotal, 0)}
            </span>
          </div>
        )}

        <div className="w-full border-b border-grey-200" />

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="font-bold">Franchise</span>
                {/* <Hint>Montant ... todo ...</Hint> */}
              </div>
              <span className="font-light">{formatPrice(deductible, 0)}</span>
            </div>

            {!!guaranteeAmount && (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="font-bold">Plafond de garantie</span>
                  {/* <Hint>Montant ... todo ...</Hint> */}
                </div>
                <span className="font-light">{formatPrice(guaranteeAmount, 0)}</span>
              </div>
            )}
          </div>

          <PromoCodeButton />
        </div>

        <div className="w-full border-b border-grey-200" />

        <div>{children}</div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Détail de votre couverture"
      >
        {children}
      </Modal>
    </>
  )
}

/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { Mutex } from 'async-mutex'

import { client } from './client'
import { graphql } from './gql'
import { parseJwtPayload } from './jwt-helper'
import {
  clearStoredTokens,
  getStoredTokens,
  storeAccessToken,
  storeRefreshToken,
  updateAuthState,
} from './state'

const refreshTokensMutation = graphql(/* GraphQL */ `
  mutation refreshAccessToken($refreshToken: String!) {
    tokens: refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`)

const getRefreshedTokens = async (refreshToken: string) => {
  const data = await client.request(refreshTokensMutation, { refreshToken }, {})
  return { ...data.tokens }
}

// using a mutex to avoid sending multiple refresh token requests at the same time when the token is expired
const mutex = new Mutex()

export const getAccessToken = async (forceRefresh = false): Promise<string | undefined> => {
  const release = await mutex.acquire()

  try {
    const localTokens = getStoredTokens()

    if (!localTokens.accessToken || !localTokens.refreshToken) {
      clearStoredTokens()
      return undefined
    }

    // Adding an overlap in case the request takes a bit of time, and the token expires in the meantime
    const overlap = 60 * 1000 // 1 minute

    const isAccessTokenExpired =
      parseJwtPayload(localTokens.accessToken).tokenExpiresAt.getTime() < Date.now() + overlap

    if (!isAccessTokenExpired && !forceRefresh) return localTokens.accessToken

    try {
      const tokens = await getRefreshedTokens(localTokens.refreshToken)

      storeAccessToken(tokens.accessToken)
      storeRefreshToken(tokens.refreshToken)
      updateAuthState()

      return tokens.accessToken
    } catch (error) {
      console.error(error)
      console.error('The above error happened while refreshing the access token.')

      clearStoredTokens()
      updateAuthState()
      return undefined
    }

    //
  } finally {
    release()
  }
}

import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import locale from '../../utils/locale'
import { type OnboardingPage } from '../routes'
import { track } from '../../utils/analytics'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'

import { Input, FormError, RadioGroup, Hint, Checkbox } from '@olino/design-system'

// This is a duplicate of the WelcomePage component in src/pages/onboarding/00-Welcome/Welcome.tsx. The goal is to show how to use the new layout in the onboarding flow.
// When the new layout is ready, we will solely use this WelcomePage and remove the one in src/pages/onboarding/00-Welcome/Welcome.tsx.

const options = [
  'Solo-entrepreneur / Freelance',
  'Petite ou moyenne entreprise',
  'Entreprise de taille intermédiaire',
] as const

const OptionTPEPME = () => (
  <div className="flex items-center gap-2">
    <span>Petite ou moyenne entreprise</span>
    <Hint>Moins de 250 employés</Hint>
  </div>
)

const OptionIntermediate = () => (
  <div className="flex items-center gap-2">
    <span>Entreprise de taille intermédiaire</span>
    <Hint>Plus de 250 employés</Hint>
  </div>
)

const schema = z.object({
  turnover: z
    .number({ required_error: 'Ce champ est requis' })
    .positive({ message: "Le chiffre d'affaire doit être supérieur à 0" }),
  usaCanadaTurnover: z
    .number({ required_error: 'Ce champ est requis' })
    .nonnegative({
      message: "Le chiffre d'affaire en pourcentage doit être supérieur ou égal à 0%",
    })
    .max(100, { message: "Le pourcentage du chiffre d'affaire doit être inférieur ou égal à 100%" })
    .default(0),
  companySizeLabel: z.enum(options, { required_error: 'Ce champ est requis' }),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const { id } = useParams()

  const [isAmericanTurnoverDisplayed, setIsAmericanTurnoverDisplayed] = useState(false)

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      turnover: quotation?.details.turnover || 0,
      usaCanadaTurnover: 0,
      companySizeLabel: options[0],
    },
  })

  const onSubmit = handleSubmit((data) => {
    track({
      event: 'submitted_turnover',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          turnover: data.turnover,
        },
      })
    )
  })

  useEffect(() => {
    track({
      event: 'visited_turnover',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Quelle est la taille de votre&nbsp;entreprise?</OnboardingTitle.H1>

      <form className="space-y-8" onSubmit={onSubmit}>
        {/* <div className="max-w-lg mx-auto pb-8 text-sm text-center">
          Le nombre d'employés et les revenus de votre entreprise sont des indicateurs
          indispensables pour correctement évaluer les risques de votre société. Inutile pour le
          moment d'embêter votre RH ou votre comptable pour avoir les valeurs exactes.
        </div> */}

        <OnboardingTitle.H2>Type d'entreprise</OnboardingTitle.H2>

        <div>
          <Controller
            control={control}
            name="companySizeLabel"
            render={({ field }) => (
              <RadioGroup
                options={options}
                getOptionLabel={(option) => {
                  switch (option) {
                    case 'Solo-entrepreneur / Freelance':
                      return option
                    case 'Petite ou moyenne entreprise':
                      return <OptionTPEPME />
                    case 'Entreprise de taille interm\u00E9diaire':
                      return <OptionIntermediate />
                    default:
                      return ''
                  }
                }}
                value={field.value}
                onChange={field.onChange}
                direction="col"
                optionsContainerClassName="space-y-3"
                optionClassName="flex items-center"
              />
            )}
          />
        </div>

        <OnboardingTitle.H2>
          Chiffre d'affaires estimé en {new Date().getFullYear()}
        </OnboardingTitle.H2>

        <Controller
          control={control}
          name="turnover"
          render={({ field }) => (
            <div className="flex flex-col ">
              <Input
                label="Total monde"
                value={field?.value?.toLocaleString(locale) || ''}
                onBlur={field.onBlur}
                onChange={(e) => {
                  const value = parseInt(e.target.value.replace(/\D/g, ''), 10)
                  field.onChange(Number.isNaN(value) ? 0 : value)
                }}
                endAdornment="€"
                className="w-full md:w-[200px]"
              />
              <FormError error={errors.turnover} />
            </div>
          )}
        />

        <div className="w-fit">
          <Checkbox
            labelPosition="right"
            value={isAmericanTurnoverDisplayed}
            label="Votre entreprise est-elle implantée aux USA ou au Canada ?"
            onChange={() => setIsAmericanTurnoverDisplayed(!isAmericanTurnoverDisplayed)}
          />
        </div>

        {isAmericanTurnoverDisplayed && (
          <Controller
            control={control}
            name="usaCanadaTurnover"
            render={({ field }) => (
              <div>
                <Input
                  label="Proportion aux Etats-Unis ou au Canada"
                  value={field?.value?.toLocaleString(locale) || ''}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    const value = parseInt(e.target.value.replace(/\D/g, ''), 10)

                    field.onChange(Number.isNaN(value) ? 0 : value)
                  }}
                  endAdornment="%"
                  className="w-full md:w-fit"
                />
                <FormError error={errors.usaCanadaTurnover} />
              </div>
            )}
          />
        )}

        <div className="flex justify-end">
          <OnboardingNextButton />
        </div>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <div className="space-y-4">
      <TipsCard
        title="Vous ne connaissez pas la valeur précise ?"
        content={[
          'Pas besoin d’embêter votre comptable ou votre DAF ! Une simple estimation sur le chiffre d’affaire que vous pensez faire cette année nous suffit. Nous voulons en effet nous assurer que nous sommes en mesure de vous accompagner.',
          'Votre chiffre d’affaires fait partie des éléments qui vont influencer le prix de votre assurance. Si vous effectuez une partie de votre activité en Amérique du Nord (Etats Unis ou Canada), cela aura aussi un impact sur votre prix car les assurances professionnelles y sont beaucoup plus sollicitées qu’en France.',
        ]}
      />
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { twMerge } from 'tailwind-merge'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BadgeProps {
  leftIcon?: IconDefinition
  rightIcon?: IconDefinition
  className?: string
  size?: 'medium' | 'small'
  children?: React.ReactNode
}

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, leftIcon, rightIcon, className, size = 'medium' }, ref) => (
    <div
      ref={ref}
      className={twMerge(
        `inline-flex flex-row items-center whitespace-nowrap rounded-full bg-primary-100 font-semibold uppercase text-primary-950`,
        size === 'small' && 'px-2 py-0.5 text-xs',
        size === 'medium' && 'px-3 py-1 text-sm',
        className
      )}
    >
      {leftIcon && <FontAwesomeIcon className={twMerge('mr-2')} icon={leftIcon} />}
      {children}
      {rightIcon && <FontAwesomeIcon className={twMerge('ml-2')} icon={rightIcon} />}
    </div>
  )
)
Badge.displayName = 'Badge'

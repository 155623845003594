import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'

import { FormError, Input } from '@olino/design-system'

export const SummaryFirstName = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      label="Prénom :"
      schema={z.object({
        firstName: z
          .string({ required_error: 'Ce champ est requis' })
          .trim()
          .min(1, { message: 'Le prénom est requis' }),
      })}
      value={{ firstName: quotation?.details?.contact?.firstName ?? '' }}
      displayValue={({ firstName }) => firstName}
      onSave={({ firstName }) =>
        updateQuotation({
          details: {
            contact: {
              firstName,
            },
          },
        })
      }
      input={({ control }) => (
        <Controller
          control={control}
          name="firstName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <Input type="text" autoComplete="given-name" value={value} onChange={onChange} />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

import React from 'react'
import { faShare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'

import { Button } from '@olino/design-system'

type Props = {
  minified?: boolean
}

export const ShareOnboardingButton: React.FC<Props> = ({ minified }) => {
  return (
    <Button
      variant={minified ? 'text' : 'text'}
      size={minified ? 'sm' : 'md'}
      onClick={() => {
        navigator.clipboard.writeText(window.location.href)
        toast.success('Lien copié dans le presse-papier')
      }}
    >
      <FontAwesomeIcon icon={faShare} />
      {!minified && ' Partager'}
    </Button>
  )
}

import { useParams } from 'react-router'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { PricingRCPCoversSection } from '../components/PricingRCPCoversSection'
import { useQuotation } from '../context'
import { ProductEnum, type QuotationAddress } from '../../generated/graphql'
import { PricingEcommCoversSection } from '../components/PricingEcommCoversSection'
import { PricingMRPCoversSection } from '../components/PricingMRPCoversSection'
import { TipsCard } from '../components/TipsCard'
import { SummaryStartDate } from '../components/summary/SummaryStartDate'
import { SummaryPaymentFrequency } from '../components/summary/SummaryPaymentFrequency'
import { ShareOnboardingButton } from '../components/ShareOnboardingButton'
import { SummaryEmail } from '../components/summary/SummaryEmail'
import { SummaryFirstName } from '../components/summary/SummaryFirstName'
import { SummaryLastName } from '../components/summary/SummaryLastName'
import { SummaryPhone } from '../components/summary/SummaryPhone'
import { track } from '../../utils/analytics'

const getInlineAddress = (address?: QuotationAddress) => {
  return `${address?.line1 ?? ''}${address?.line2 ? `,${address.line2} ` : ''}, ${
    address?.postalCode ?? ''
  }, ${address?.city ?? ''}`
}

const Main = () => {
  const { id } = useParams()
  const { proceed } = useOnboardingWorkflow()
  const { quotation } = useQuotation()

  const onSubmit = () => {
    track({
      event: 'submitted_summary',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_summary',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Récapitulatif de vos données contractuelles</OnboardingTitle.H1>

      <div className="space-y-8">
        <div>
          <OnboardingTitle.H2>Coordonnées du signataire</OnboardingTitle.H2>
          <div className="space-y-3">
            <SummaryEmail />
            <SummaryFirstName />
            <SummaryLastName />
            <SummaryPhone />
          </div>
        </div>

        <div>
          <OnboardingTitle.H2>A propos de votre entreprise</OnboardingTitle.H2>
          <div className="space-y-3">
            <div>
              <span className="font-medium">Nom:</span> {quotation.details.company?.name}
            </div>
            <div>
              <span className="font-medium">Siret:</span> {quotation.details.company?.siret}
            </div>
            <div>
              <span className="font-medium">Code Naf:</span> {quotation.details.company?.nafCode}
            </div>
            <div>
              <span className="font-medium">Statut:</span> {quotation.details.company?.legalStatus}
            </div>
            <div>
              <span className="font-medium">Date de création:</span>{' '}
              {quotation.details.company?.foundedAt}
            </div>
            <div>
              <span className="font-medium">Chiffre d'affaire:</span> {quotation.details.turnover}
            </div>
            <div>
              <span className="font-medium">Siège social:</span>{' '}
              {getInlineAddress(quotation.details.company?.address ?? undefined)}
            </div>
            <div>
              <span className="font-medium">Adresse de facturation:</span>{' '}
              {getInlineAddress(
                quotation.details.billingAddress ?? quotation.details.company?.address ?? undefined
              )}
            </div>
          </div>
        </div>

        <div>
          <OnboardingTitle.H2>Votre produit d'assurance</OnboardingTitle.H2>
          <div className="space-y-3">
            <SummaryStartDate />
            <SummaryPaymentFrequency />
          </div>
        </div>

        {quotation.details.products?.length && (
          <div>
            {quotation.details.products?.some((p) => p === ProductEnum.PublicLiability) && (
              <PricingRCPCoversSection extended displayOnlyCover />
            )}
            {quotation.details.products?.some((p) => p === ProductEnum.PublicLiabilityEcomm) && (
              <PricingEcommCoversSection extended displayOnlyCover />
            )}
            {quotation.details.products?.some((p) => p === ProductEnum.MultiRisk) && (
              <PricingMRPCoversSection extended displayOnlyCover />
            )}
          </div>
        )}

        <div className="flex justify-end gap-3">
          <ShareOnboardingButton />
          <OnboardingNextButton onClick={() => onSubmit()} />
        </div>
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Pourquoi dois-je valider des informations que j’ai déjà saisies ?"
      content={[
        'Ces informations seront celles qui vont apparaitre sur votre contrat et elles sont engageantes. N’hésitez pas à contacter un de nos experts si vous avez la moindre question ou doute.',
      ]}
    />
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

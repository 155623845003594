import locale from './locale'

const formatPrice = (amount: number, fractionalDigits = 2): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: fractionalDigits,
  }).format(amount)

export default formatPrice

import { useMemo } from 'react'
import { useNavigate, Navigate } from 'react-router'

import { useAuth } from './AuthProvider'
import { generateRedirectUrl } from './utils'

const useSignInUrl = () => {
  const { routes, authUser } = useAuth()
  const url = useMemo(
    () => generateRedirectUrl(authUser ? routes.afterSignIn : routes.signIn),
    [routes, authUser]
  )

  return url
}

export const useRedirectToSignIn = () => {
  const navigate = useNavigate()
  const url = useSignInUrl()

  return () => {
    navigate(url)
  }
}

export const RedirectToSignIn: React.FC = () => {
  const url = useSignInUrl()

  return <Navigate to={url} replace />
}

// eslint-disable-next-line import/no-cycle
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import client, { type AuthUser } from '../js'

type TAuthContext = {
  authUser: AuthUser | null
  isLoggedIn: boolean

  routes: {
    signIn: string

    afterSignIn: string
  }
}

const AuthContext = createContext({} as TAuthContext)

type AuthProviderProps = Pick<TAuthContext, 'routes'> & {
  onAuthStateChanged?: (user: AuthUser | null) => void
}

export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({
  children,
  routes,
  onAuthStateChanged,
}) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(client.getCurrentUser())

  useEffect(() => {
    const unsubscribe = client.onAuthStateChanged((user) => {
      setAuthUser(user)
      onAuthStateChanged?.(user)
    })

    return unsubscribe
  }, [onAuthStateChanged])

  const context = useMemo(
    () => ({
      authUser,
      isLoggedIn: !!authUser,
      routes,
    }),
    [authUser, routes]
  )

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

AuthProvider.displayName = 'AuthProvider'

export const useAuth = () => useContext(AuthContext)

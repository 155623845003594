import React from 'react'

import OptionSwitch from '../../components/quotation/optionSwitch/OptionSwitch'
import { useQuotation } from '../context'
import cn from '../../utils/cn'

export const PricingRCPOptionsSection = React.forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { quotation, updateQuotation } = useQuotation()

    const { details } = quotation

    return (
      <section className={cn('flex flex-col gap-3', className)} id="options" ref={ref}>
        <h2 className="text-lg font-bold md:text-xl">Protections recommandées</h2>

        <p className="text-xs md:text-sm">
          Voici les protections généralement souscrites par nos clients ayant la même activité que
          vous.
        </p>

        <div className="flex flex-col gap-3">
          <OptionSwitch
            title="Protection juridique"
            recommended
            description="En cas de conflit avec un tiers, vous êtes accompagné(e) par un juriste spécialisé pour trouver une solution amiable, si elle n'aboutit pas, vous bénéficiez d'un accompagnement dans la procédure judiciaire avec une prise en charge des frais (avocats, huissiers, expets, etc.)."
            price={quotation.premium?.publicLiability?.legalProtectionOption ?? undefined}
            value={details.publicLiability?.legalProtectionOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: { publicLiability: { legalProtectionOption: value } },
              })
            }}
            infoDialog={{
              title: 'Protection juridique',
              content: (
                <>
                  <p className="text-sm">
                    En cas de conflit avec un tiers, vous êtes accompagné(e) par un juriste
                    spécialisé pour trouver une solution amiable, si elle n'aboutit pas, vous
                    bénéficiez d'un accompagnement dans la procédure judiciaire avec une prise en
                    charge des frais (avocats, huissiers, expets, etc.).
                  </p>
                  <p className="font-semibold">Seuil d'intervention : 0€</p>
                  <h4 className="font-bold">Vous êtes couvert pour :</h4>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      L'accompagnement et la prise en charge des frais liés à la gestion amiable du
                      litige.
                    </li>
                    <li>
                      L'accompagnement et la prise en charge des frais liés à la phase judiciaire du
                      conflit, avec la liberté de choisir votre avocat.
                    </li>
                    <li>Le suivi jusqu'à la parfaite exécution des décisions de justice.</li>
                  </ul>
                  <h4 className="font-bold">Exemple d'intervention :</h4>
                  <p className="text-sm">
                    Un concurrent utilise un logo/ ou nom similaire au votre et dont vous avez la
                    propriété. Nous vous accompagnons pour faire respecter vos droits et défendre
                    votre propriété.{' '}
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Responsabilité civile exploitation"
            recommended
            description="La Responsabilie Civile d'Exploitation intervient en cas de dommages pendant la réalisation de vos prestations ou la vie courante de la société. "
            price={quotation.premium?.publicLiability?.operatingLiabilityOption ?? undefined}
            value={details.publicLiability?.operatingLiabilityOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: { publicLiability: { operatingLiabilityOption: value } },
              })
            }}
            infoDialog={{
              title: "Responsabilité Civile d'exploitation",
              content: (
                <>
                  <p className="text-sm">
                    La Responsabilie Civile d'Exploitation intervient en cas de dommages pendant la
                    réalisation de vos prestations ou la vie courante de la société. Ces frais ne
                    sont pas couverts par votre Responsabilité Civile Professionnelle.
                  </p>
                  <p className="font-semibold leading-tight">
                    Cette couverture est vivement recommandée si vous avez des salariés, si vos
                    clients se déplacent dans vos locaux ou bien si vous vous déplacez chez vos
                    clients.
                  </p>
                  <h4 className="font-bold">Vous êtes couvert pour :</h4>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      Les dommages corporels et/ou les dommages matériels et/ou les dommages
                      immatériels consécutifs.
                    </li>
                    <li>Les dommages immatériels non consécutifs.</li>
                    <li>Les frais de défense au titre des poursuites pénales.</li>
                    <li>La responsabilité civile employeur.</li>
                  </ul>
                  <h4 className="font-bold">Exemple d'intervention :</h4>
                  <p className="text-sm">
                    L'un de vos clients ou l'un de vos collaborateur se blesse dans vos locaux. Ou
                    bien, un salarié renverse un liquide sur l'ordinateur d'un client en
                    déplacement.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Monde entier"
            description="Cette extension vous couvre si vous exercez une partie, ou la totalité de votre activité aux Etats-Unis ou/et au Canada."
            price={quotation.premium?.publicLiability?.worldWideOption ?? undefined}
            value={details.publicLiability?.worldWideOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: { publicLiability: { worldWideOption: value } },
              })
            }}
            infoDialog={{
              title: 'Extension Monde Entier',
              content: (
                <>
                  <p className="text-sm">
                    Cette extension étend vos garanties si vous exercez une partie, ou la totalité
                    de votre activité aux Etats-Unis ou/et au Canada.
                  </p>
                  <p className="font-semibold leading-tight">
                    Cette couverture est obligatoire si vous avez une activité ou un fournisseur aux
                    Etats-Unis et/ou au Canada.
                  </p>
                </>
              ),
            }}
          />
        </div>
      </section>
    )
  }
)

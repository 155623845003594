import React from 'react'
import { twMerge } from 'tailwind-merge'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContainerProps {
  className?: string
}

const Container = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ContainerProps>>(
  ({ children, className }, ref) => (
    <section
      ref={ref}
      className={twMerge(
        'container mx-auto flex min-h-[calc(100vh-4rem)] w-full flex-col items-center justify-center px-2 py-14 sm:min-h-[calc(100vh-5rem)] sm:px-8',
        className
      )}
    >
      {children}
    </section>
  )
)

export default Container

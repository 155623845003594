export type WorkflowSteps<TPath extends string, TData> = {
  [KPath in TPath]: {
    initial?: true

    completed: ((data?: TData) => boolean) | boolean

    hideBackward?: true

    goto?: ((_?: TData) => TPath) | TPath

    // Useful for heyflows
    hideBackwardButton?: true
  }
}

export type WorkflowStep<TPath extends string, TData> = WorkflowSteps<TPath, TData>[TPath]

export const isCompleted = <TPath extends string, TData>(
  step: WorkflowStep<TPath, TData>,
  data?: TData
) => (typeof step.completed === 'boolean' ? step.completed : step.completed(data))

export const getGotoPath = <TPath extends string, TData>(
  step: WorkflowStep<TPath, TData>,
  data?: TData
) => (typeof step.goto === 'string' ? step.goto : step.goto?.(data))

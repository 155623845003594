type ZapierEvent = {
  cancel_contract: {
    quotationID: string
    product?: string
    email?: string
    phone?: string
    firstName?: string
    lastName?: string
    contractStartDate?: string
  }
}

const ZapierEventDetails = {
  cancel_contract: {
    url: 'https://hooks.zapier.com/hooks/catch/11317401/3htt14m/',
    method: 'POST',
  },
} as const satisfies Record<keyof ZapierEvent, { url: string; method: string }>

export const useZapier = () => {
  const trigger = async <TEvent extends keyof ZapierEvent>(
    event: TEvent,
    data: ZapierEvent[TEvent]
  ) => {
    const { url, method } = ZapierEventDetails[event]
    try {
      const res = await fetch(url, {
        method,
        body: JSON.stringify(data),
      })
      if (!res.ok) {
        const json = await res.json()
        console.error(json)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { trigger }
}

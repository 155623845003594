import { Combobox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { faCheck, faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'

import { Input } from '../input/Input'
import { noop } from '../utils'
import { Loader } from '../loader/Loader'
import { Hint } from '../hint/Hint'
import { Button } from '../button/Button'

export interface AutoCompleteProps<T> {
  options: T[]

  value?: T
  onChange?: (value: T | undefined) => void

  query?: string
  onQueryChange?: (value: string) => void

  getOptionLabel?: (option: T) => string
  getOptionDisabled?: (option: T) => boolean
  getOptionKey?: (option: T) => string | number
  render?: (option: T) => React.ReactNode
  onClear?: () => void

  // If true, the input will be displayed even if a value is selected in the option list
  hideSelectedValueBox?: boolean
  label?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  className?: string
  loading?: boolean
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'ref'>

  hideResults?: boolean

  bottomAdornment?: React.ReactNode

  hint?: string
  endAdornment?: React.ReactNode
}

export const AutoComplete = <T,>({
  options = [],

  value,
  onChange = noop,
  query,
  onQueryChange = noop,

  getOptionLabel = (option) => `${option}`,
  getOptionDisabled = () => false,
  getOptionKey = (option) => `${option}`,
  onClear = noop,
  render = (option) => getOptionLabel(option),

  hideSelectedValueBox = false,
  disabled,
  placeholder,
  error,
  label,
  className,
  loading = false,
  inputProps,
  bottomAdornment,
  hideResults = false,
  hint,
  endAdornment,
}: AutoCompleteProps<T>) => {
  return (
    <Combobox
      value={null}
      onChange={onChange}
      as="div"
      className={twMerge('group relative', className)}
      nullable
    >
      {label && (
        <Combobox.Label
          className={twMerge(
            'ml-0.5 flex items-center gap-2 text-sm transition-colors',
            error && 'text-danger-400',
            disabled && 'text-grey-400',
            !error && !disabled && 'text-grey-600 focus-within:text-primary-950'
          )}
        >
          {label}
          {hint && <Hint text={hint} />}
        </Combobox.Label>
      )}
      {((!hideSelectedValueBox && !value) || hideSelectedValueBox) && (
        <Combobox.Input
          as={Input}
          {...inputProps}
          className={twMerge(
            'w-full border-0 bg-transparent p-0 placeholder-grey-300 focus:outline-none focus:ring-0'
          )}
          endAdornment={endAdornment}
          onChange={(event) => {
            onQueryChange(event.target.value)
          }}
          placeholder={placeholder}
          disabled={disabled}
          // @ts-expect-error I don't know why it works but it does, and seeing as we will be rewriting this component soon, I don't want to spend time on it
          displayValue={(item: T | undefined) => {
            if (item) return getOptionLabel(item)
            return query || ''
          }}
        />
      )}

      {value && !hideSelectedValueBox && (
        <div className="flex w-full flex-row items-center rounded-lg bg-grey-50 p-2">
          <FontAwesomeIcon icon={faCheck} className="h-5 w-5" />
          <span className="ml-3 flex-grow font-semibold">{getOptionLabel(value)}</span>
          <Button variant="text" size="sm" className="m-0" onClick={onClear}>
            Modifier
          </Button>
        </div>
      )}

      {!hideResults && (
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options
            className={twMerge(
              'absolute z-50 mt-2 max-h-80 w-full overflow-auto rounded-lg border border-grey-300 bg-white shadow-md'
            )}
          >
            {loading && <Loader className="w-full py-5 text-primary-950" size="small" />}
            {!loading &&
              options.length > 0 &&
              options.map((option) => (
                <Combobox.Option
                  key={getOptionKey(option)}
                  value={option}
                  disabled={getOptionDisabled(option)}
                >
                  {({ active, selected, disabled }) => (
                    <div
                      className={twMerge(
                        'cursor relative flex flex-row py-2 pl-3 pr-10',
                        disabled && 'text-black text-opacity-30',
                        selected && 'bg-primary-600 text-white',
                        active && !selected && 'bg-primary-100 bg-opacity-50 text-black',
                        !selected && !active && 'bg-white text-black'
                      )}
                    >
                      {render(option)}
                      {selected && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <FontAwesomeIcon icon={faCheckCircle} className="h-5 w-5" />
                        </span>
                      )}
                    </div>
                  )}
                </Combobox.Option>
              ))}
            {!loading && options.length === 0 && (
              <div className="px-3 py-1">Aucun résultat trouvé.</div>
            )}
            {bottomAdornment}
          </Combobox.Options>
        </Transition>
      )}
    </Combobox>
  )
}
AutoComplete.displayName = 'AutoComplete'

import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'
import { PaymentFrequency } from '../../../generated/graphql'
import { PaymentFrequencyInput } from '../PaymentFrequencyInput'

import { FormError } from '@olino/design-system'

const getPaymentFrequencyLabel = (paymentFrequency?: PaymentFrequency) => {
  switch (paymentFrequency) {
    case PaymentFrequency.Monthly:
      return 'Mensuel'
    case PaymentFrequency.Annually:
      return 'Annuel'
    default:
      return ''
  }
}

export const SummaryPaymentFrequency = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      value={{
        paymentFrequency: quotation?.details?.paymentFrequency ?? PaymentFrequency.Annually,
      }}
      displayValue={({ paymentFrequency }) => getPaymentFrequencyLabel(paymentFrequency)}
      label="Fréquence de facturation :"
      schema={z.object({
        paymentFrequency: z.nativeEnum(PaymentFrequency),
      })}
      onSave={({ paymentFrequency }) => updateQuotation({ details: { paymentFrequency } })}
      input={({ control }) => (
        <Controller
          control={control}
          name="paymentFrequency"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <PaymentFrequencyInput value={value} onChange={onChange} />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

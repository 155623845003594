/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation signUp(\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $phone: String!\n    $marketingOptIn: Boolean\n  ) {\n    deviceId: signUp(\n      email: $email\n      firstName: $firstName\n      lastName: $lastName\n      phone: $phone\n      marketingOptIn: $marketingOptIn\n    )\n  }\n": types.SignUpDocument,
    "\n  mutation sendSignInOTP($email: String!) {\n    deviceId: sendSignInOTP(email: $email)\n  }\n": types.SendSignInOtpDocument,
    "\n  mutation signInWithOTP($deviceId: String!, $email: String!, $otp: String!) {\n    tokens: signInWithOTP(deviceId: $deviceId, email: $email, otp: $otp) {\n      accessToken\n      refreshToken\n    }\n  }\n": types.SignInWithOtpDocument,
    "\n  mutation refreshAccessToken($refreshToken: String!) {\n    tokens: refreshAccessToken(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n": types.RefreshAccessTokenDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation signUp(\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $phone: String!\n    $marketingOptIn: Boolean\n  ) {\n    deviceId: signUp(\n      email: $email\n      firstName: $firstName\n      lastName: $lastName\n      phone: $phone\n      marketingOptIn: $marketingOptIn\n    )\n  }\n"): (typeof documents)["\n  mutation signUp(\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $phone: String!\n    $marketingOptIn: Boolean\n  ) {\n    deviceId: signUp(\n      email: $email\n      firstName: $firstName\n      lastName: $lastName\n      phone: $phone\n      marketingOptIn: $marketingOptIn\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendSignInOTP($email: String!) {\n    deviceId: sendSignInOTP(email: $email)\n  }\n"): (typeof documents)["\n  mutation sendSignInOTP($email: String!) {\n    deviceId: sendSignInOTP(email: $email)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation signInWithOTP($deviceId: String!, $email: String!, $otp: String!) {\n    tokens: signInWithOTP(deviceId: $deviceId, email: $email, otp: $otp) {\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation signInWithOTP($deviceId: String!, $email: String!, $otp: String!) {\n    tokens: signInWithOTP(deviceId: $deviceId, email: $email, otp: $otp) {\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation refreshAccessToken($refreshToken: String!) {\n    tokens: refreshAccessToken(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation refreshAccessToken($refreshToken: String!) {\n    tokens: refreshAccessToken(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
/* eslint-disable jsx-a11y/label-has-associated-control */
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Button } from '@olino/design-system'
import { useAuth } from '@olino/auth-client/react'
import client from '@olino/auth-client/js'

const OtpSingleInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & { focus: boolean; failed: boolean }
> = ({ focus, failed, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focus) ref.current?.focus()
  }, [focus])

  return (
    <input
      ref={ref}
      aria-label="Entrez votre code de connexion"
      className={twMerge(
        'w-8 rounded-lg border text-center text-[2rem] focus:outline-primary-900 sm:w-12 sm:text-[2.5rem]',
        failed && 'border-danger-200'
      )}
      {...rest}
    />
  )
}

export const OtpInput: React.FC<{
  onChange: (otp: string | undefined) => void
  failed: boolean
}> = ({ onChange, failed }) => {
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [focus, setFocus] = useState(0)

  const clear = () => setOtp(['', '', '', '', '', ''])

  useEffect(() => {
    const otpString = otp.join('')
    if (otpString.length === 6) onChange(otpString)
    else onChange(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp])

  const props = (i: number) => ({
    value: otp[i],
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newOtp = [...otp]
      newOtp[i] = e.target.value.trim().toUpperCase().at(-1) || ''
      setOtp(newOtp)

      if (e.target.value.length > 0) setFocus(i + 1)
    },
    onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      const data = e.clipboardData
        .getData('text')
        .replace('-', '')
        .replace(' ', '')
        .replace('\n', '')
        .toUpperCase()
      const splitData = data.split('')

      const newOtp = ['', '', '', '', '', '']
      for (let i = 0; i < Math.max(splitData.length, 6); i += 1) {
        newOtp[i] = splitData[i]
      }

      setOtp(newOtp)
    },
    failed,
    focus: i === focus,
  })

  return (
    <div className="flew-row flex items-center justify-between gap-1 self-center sm:gap-2">
      <OtpSingleInput {...props(0)} />
      <OtpSingleInput {...props(1)} />
      <OtpSingleInput {...props(2)} />

      <div className="mx-1 text-3xl font-semibold text-grey-400">-</div>

      <OtpSingleInput {...props(3)} />
      <OtpSingleInput {...props(4)} />
      <OtpSingleInput {...props(5)} />

      <button type="button" onClick={clear}>
        <FontAwesomeIcon
          icon={faTimes}
          className={twMerge(
            'ml-1 h-6 w-6 text-grey-300 transition-opacity sm:h-8 sm:w-8',
            otp.some((v) => v !== '') ? 'opacity-100' : 'opacity-0'
          )}
        />
      </button>
    </div>
  )
}

const OtpPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { routes } = useAuth()
  const [otp, setOtp] = useState<string | undefined>()
  const [failed, setFailed] = useState(false)
  const navigate = useNavigate()

  const [loggingIn, setLoggingIn] = useState(false)

  const email = searchParams.get('email') as string
  const redirectUri = searchParams.get('redirectUri')

  useEffect(() => {
    if (!email) navigate('../login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setLoggingIn(true)
    if (!otp) {
      console.warn('Trying to submit empty OTP')
      return
    }

    try {
      await client.signInWithOtp(email, otp)
      navigate(redirectUri || routes.afterSignIn)
    } catch (e) {
      console.error(e)
      setFailed(true)
    } finally {
      setLoggingIn(false)
    }
  }

  return (
    <>
      <p className="mt-4 leading-relaxed text-grey-500">
        Si votre adresse email est rattachée à un compte Olino, vous allez recevoir un mail
        contenant un code à 6 caractères. Saisissez ce code ci-dessous :
      </p>

      <form action="#" className="mt-8 flex flex-col items-stretch gap-6" onSubmit={onSubmit}>
        <OtpInput onChange={setOtp} failed={failed} />
        {failed && (
          <span className="text-sm">
            Code invalide. Attention, vous n'avez que 3 tentatives par code
          </span>
        )}

        <Button type="submit" disabled={!otp || loggingIn} loading={loggingIn} className="w-full">
          Se connecter
        </Button>
      </form>
    </>
  )
}

export default OtpPage

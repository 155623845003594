import { faChevronDown, faTag } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Disclosure, Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import { useQuotation } from '../context'
import formatPrice from '../../utils/formatPrice'

import { Card, CardContent } from '@olino/design-system'

export const CheckoutRecapCard: React.FC = () => {
  const { quotation } = useQuotation()

  const discountedTotal = quotation.premium?.discountedTotal

  const total = quotation.premium?.originalTotal || 0

  const totalApplicationFees = quotation.premium?.applicationFeesTotal

  const annualTotalAfterPotentialDiscount = discountedTotal || total || 0

  const annualTotalIncludingApplicationFees =
    annualTotalAfterPotentialDiscount + (totalApplicationFees || 0)

  const discountAmount = discountedTotal == null ? undefined : discountedTotal - total

  return (
    <Card className="mx-auto mb-4 w-full max-w-lg">
      <CardContent>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full flex-row items-center justify-between rounded-lg py-2 text-left text-lg font-semibold text-primary-900 focus:outline-none focus-visible:ring-opacity-75">
                <span>Votre récapitulatif</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={twMerge('transition-transform', open ? 'rotate-180' : 'rotate-0')}
                />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pb-2 text-sm">
                  <div className="spacer my-2.5 h-px w-full bg-grey-200" />
                  {quotation.premium?.publicLiability && (
                    <ul>
                      <li>
                        <div className="flex flex-row items-center justify-between font-medium">
                          <span>Responsabilité civile professionnelle</span>
                          <span>
                            {formatPrice(quotation.premium?.publicLiability?.originalTotal || 0)}
                          </span>
                        </div>
                        <ul className="gap-2 pl-2 sm:pl-4">
                          {quotation.details.publicLiability?.legalProtectionOption &&
                            quotation.premium?.publicLiability?.legalProtectionOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Protection juridique</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiability?.legalProtectionOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiability?.operatingLiabilityOption &&
                            quotation.premium?.publicLiability?.operatingLiabilityOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Responsabilité civile exploitation</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiability?.operatingLiabilityOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiability?.worldWideOption &&
                            quotation.premium?.publicLiability?.worldWideOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Responsabilité civile à l'étranger</span>
                                <span>
                                  {formatPrice(quotation.premium?.publicLiability?.worldWideOption)}
                                </span>
                              </li>
                            )}
                        </ul>
                      </li>
                    </ul>
                  )}
                  {quotation.premium?.multiRisk && (
                    <ul>
                      <li>
                        <div className="flex flex-row items-center justify-between font-medium">
                          <span>Multirisque</span>
                          <span>
                            {formatPrice(quotation.premium?.multiRisk?.originalTotal || 0)}
                          </span>
                        </div>
                        <ul className="gap-2 pl-2 sm:pl-4">
                          {quotation.details.multiRisk?.legalProtectionOption &&
                            quotation.premium?.multiRisk?.legalProtectionOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span>dont Protection juridique</span>
                                <span>
                                  {formatPrice(quotation.premium?.multiRisk?.legalProtectionOption)}
                                </span>
                              </li>
                            )}
                          {quotation.details.multiRisk?.operatingLiabilityOption &&
                            quotation.premium?.multiRisk?.operatingLiabilityOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span>dont Responsabilité civile d'exploitation</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.multiRisk?.operatingLiabilityOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.multiRisk?.financialLossStandardOption &&
                            quotation.premium?.multiRisk?.financialLossStandardOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span>dont Pertes financières</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.multiRisk?.financialLossStandardOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.multiRisk?.financialLossAdvantageOption &&
                            quotation.premium?.multiRisk?.financialLossAdvantageOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span>dont Pertes financières</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.multiRisk?.financialLossAdvantageOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.multiRisk?.assistanceOption &&
                            quotation.premium?.multiRisk?.assistanceOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span>dont Assistance</span>
                                <span>
                                  {formatPrice(quotation.premium?.multiRisk?.assistanceOption)}
                                </span>
                              </li>
                            )}
                        </ul>
                      </li>
                    </ul>
                  )}
                  {quotation.premium?.publicLiabilityEcomm && (
                    <ul>
                      <li>
                        <div className="flex flex-row items-center justify-between font-medium">
                          <span>Responsabilité civile professionnelle</span>
                          <span>
                            {formatPrice(
                              quotation.premium?.publicLiabilityEcomm?.originalTotal || 0
                            )}
                          </span>
                        </div>
                        <ul className="gap-2 pl-2 sm:pl-4">
                          {quotation.details.publicLiabilityEcomm
                            ?.allComputerMaterialDamageOption &&
                            quotation.premium?.publicLiabilityEcomm
                              ?.allComputerMaterialDamageOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Tous Dommages Matériels Informatiques</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.allComputerMaterialDamageOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiabilityEcomm
                            ?.assistanceAndCyberExtortionOption &&
                            quotation.premium?.publicLiabilityEcomm
                              ?.assistanceAndCyberExtortionOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Assistance et Cyber-extorsion</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.assistanceAndCyberExtortionOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiabilityEcomm?.businessInterruptionOption &&
                            quotation.premium?.publicLiabilityEcomm?.businessInterruptionOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Pertes d'exploitation</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.businessInterruptionOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiabilityEcomm?.thirdPartyDamageCoverageOption &&
                            quotation.premium?.publicLiabilityEcomm
                              ?.thirdPartyDamageCoverageOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Dommages causés aux tiers</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.thirdPartyDamageCoverageOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiabilityEcomm?.suppliersDeficiencyOption &&
                            quotation.premium?.publicLiabilityEcomm?.suppliersDeficiencyOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Carence des fournisseurs</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.suppliersDeficiencyOption
                                  )}
                                </span>
                              </li>
                            )}
                          {quotation.details.publicLiabilityEcomm?.cyberAndTelephoneFraudOption &&
                            quotation.premium?.publicLiabilityEcomm
                              ?.cyberAndTelephoneFraudOption && (
                              <li className="flex flex-row items-center justify-between gap-2 text-xs">
                                <span> dont Fraude cyber et téléphonique</span>
                                <span>
                                  {formatPrice(
                                    quotation.premium?.publicLiabilityEcomm
                                      ?.cyberAndTelephoneFraudOption
                                  )}
                                </span>
                              </li>
                            )}
                        </ul>
                      </li>
                    </ul>
                  )}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        <div className="spacer my-2.5 flex h-px w-full bg-grey-200" />

        <div className="flex flex-row items-center justify-between gap-2 text-sm">
          <span>Sous-total</span>
          <span>{formatPrice(total || 0)}</span>
        </div>
        {totalApplicationFees != null && totalApplicationFees > 0 && (
          <div className="flex flex-row items-center justify-between gap-2 text-sm">
            <span>Frais de dossier</span>
            <span>{formatPrice(totalApplicationFees || 0)}</span>
          </div>
        )}
        {discountAmount != null && (
          <div className="flex flex-row items-center justify-between gap-2 text-sm">
            <span>
              Réduction <FontAwesomeIcon icon={faTag} className="text-success-500" />
            </span>
            <span>{formatPrice(discountAmount)}</span>
          </div>
        )}
        <div>
          <div className="flex flex-row items-center justify-between text-lg font-semibold text-primary-900">
            <span>Total par an</span>
            <span>{formatPrice(annualTotalIncludingApplicationFees)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

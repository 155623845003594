import CoverListItem from '../../components/quotation/coverListItem/CoverListItem'
import CoverListOption from '../../components/quotation/coverListOption/CoverListOption'
import { useQuotation } from '../context'

type Props = {
  displayOnlyCover?: boolean
}

export const PricingRCPCoverOptions: React.FC<Props> = ({ displayOnlyCover }) => {
  const { quotation } = useQuotation()

  const covers = quotation?.covers?.publicLiability
  const details = quotation?.details?.publicLiability

  return (
    <div className="space-y-3">
      <CoverListItem
        label="Dommages corporels, matériels et immatériels"
        amount={covers?.physicalMaterialAndImmaterialDamagesConsecutiveOrNot}
      />

      <CoverListItem label="Factures impayées" amount={covers?.unpaidInvoices} />

      <CoverListItem label="Perte d'un Homme Clé" amount={covers?.lossOfAkeyPerson} />

      <CoverListItem
        label="Atteinte à la réputation et à l'image de marque"
        amount={covers?.damageToReputationAndBrandImage}
      />

      <CoverListItem label="Responsabilité civile cyber" amount={covers?.cyberPublicLiability} />

      <CoverListItem label="Piratage Site Internet" amount={covers?.hackingOfWebsite} />

      <CoverListItem
        label="Violation de données personnelles"
        amount={covers?.violationOfPersonalData}
      />

      {/* Checking if the user selected the option as well as if the option is available, to avoid confusing the user */}
      {(!displayOnlyCover ||
        (details?.legalProtectionOption === true &&
          quotation.premium?.publicLiability?.legalProtectionOption != null)) && (
        <CoverListOption
          label="Protection juridique"
          option={
            details?.legalProtectionOption === true &&
            quotation.premium?.publicLiability?.legalProtectionOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.operatingLiabilityOption === true &&
          quotation.premium?.publicLiability?.operatingLiabilityOption != null)) && (
        <CoverListOption
          label="Responsabilité civile d'exploitation"
          option={
            details?.operatingLiabilityOption === true &&
            quotation.premium?.publicLiability?.operatingLiabilityOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.worldWideOption === true &&
          quotation.premium?.publicLiability?.worldWideOption != null)) && (
        <CoverListOption
          label="Extension monde entier"
          option={
            details?.worldWideOption === true &&
            quotation.premium?.publicLiability?.worldWideOption != null
          }
        />
      )}
    </div>
  )
}

import { client } from './client'
import { graphql } from './gql'
import { updateAuthState, logOut, storeAccessToken, storeRefreshToken } from './state'

const deviceIdKey = 'deviceId'

const signUpDocument = graphql(/* GraphQL */ `
  mutation signUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $marketingOptIn: Boolean
  ) {
    deviceId: signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      marketingOptIn: $marketingOptIn
    )
  }
`)
export const signUp = async (params: {
  email: string
  firstName: string
  lastName: string
  phone: string
  marketingOptIn?: boolean
}) => {
  try {
    const res = await client.request(signUpDocument, params)

    localStorage.setItem(deviceIdKey, res.deviceId)
  } catch (e) {
    console.error(e)
    console.error('The error above occured while trying to sign up')
    logOut()
    throw e
  }
}

const sendSignInOtpDocument = graphql(/* GraphQL */ `
  mutation sendSignInOTP($email: String!) {
    deviceId: sendSignInOTP(email: $email)
  }
`)
export const sendSignInOtp = async (email: string) => {
  try {
    const res = await client.request(sendSignInOtpDocument, {
      email,
    })
    localStorage.setItem(deviceIdKey, res.deviceId)
  } catch (e) {
    console.error(e)
    console.error('The error above occured while trying to send sign in OTP')
    logOut()
    throw e
  }
}

const signInWithOtpDocument = graphql(/* GraphQL */ `
  mutation signInWithOTP($deviceId: String!, $email: String!, $otp: String!) {
    tokens: signInWithOTP(deviceId: $deviceId, email: $email, otp: $otp) {
      accessToken
      refreshToken
    }
  }
`)
export const signInWithOtp = async (email: string, otp: string) => {
  try {
    const deviceId = localStorage.getItem(deviceIdKey)

    if (!deviceId) {
      throw new Error('Device ID not found')
    }

    const res = await client.request(signInWithOtpDocument, { deviceId, email, otp })

    localStorage.removeItem(deviceIdKey)

    storeAccessToken(res.tokens.accessToken)
    storeRefreshToken(res.tokens.refreshToken)

    updateAuthState()
  } catch (e) {
    console.error(e)
    console.error('The error above occured while trying to sign in with OTP')
    logOut()
    throw e
  }
}

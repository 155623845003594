import { type AuthUser, parseJwtPayload } from './jwt-helper'

const authStateListeners = new Set<(user: AuthUser | null) => void>()

export const accessTokenKey = 'accessToken'
export const refreshTokenKey = 'refreshToken'

export const getStoredTokens = () => {
  const accessToken = localStorage.getItem(accessTokenKey)
  const refreshToken = localStorage.getItem(refreshTokenKey)
  return { accessToken, refreshToken }
}
// eslint-disable-next-line import/no-mutable-exports
export const getCurrentUser = () => {
  const { accessToken } = getStoredTokens()
  return accessToken ? parseJwtPayload(accessToken) : null
}

export const updateAuthState = async () => {
  const { accessToken } = getStoredTokens()
  const user = accessToken ? parseJwtPayload(accessToken) : null

  authStateListeners.forEach((listener) => listener(user))
}

export const onAuthStateChanged = (callback: (user: AuthUser | null) => void) => {
  authStateListeners.add(callback)
  // Call the callback immediately to get the initial state
  updateAuthState()
  return () => {
    authStateListeners.delete(callback)
  }
}

export const clearStoredTokens = () => {
  localStorage.removeItem(accessTokenKey)
  localStorage.removeItem(refreshTokenKey)

  updateAuthState()
}

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem(accessTokenKey, accessToken)
  updateAuthState()
}

export const storeRefreshToken = (refreshToken: string) => {
  localStorage.setItem(refreshTokenKey, refreshToken)
}

export const logOut = () => {
  clearStoredTokens()
  updateAuthState()
}

import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  type Activity,
  type GetActivityQuery,
  useGetActivitiesSuggestionQuery,
  useGetActivityQuery,
} from '../../../generated/graphql'
import useDebounce from '../../../utils/useDebounce'
import NoOp from '../../../utils/NoOp'
import { useQuotation } from '../../../onboarding/context'

import { AutoComplete, Loader } from '@olino/design-system'

export interface ActivitySelectionProps {
  className?: string
  onChange?: (value?: number) => void
  isFetching?: boolean
  activity?: Activity
  label?: string
  endAdornment?: React.ReactNode
}

const ActivityAutoComplete: React.FC<ActivitySelectionProps> = ({
  className,
  onChange = NoOp,
  isFetching: isFetchingValue = false,
  activity: value,
  label,
  endAdornment,
}) => {
  const [query, setQuery] = useState('')
  const queryClient = useQueryClient()
  const { quotation } = useQuotation()

  const product = useMemo(() => quotation.details.products?.at(0), [quotation])

  const debouncedQuery = useDebounce(query, 300)

  const { data, isFetching } = useGetActivitiesSuggestionQuery(
    {
      query: debouncedQuery,
      // Query is disabled when no product is selected
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      product: product!,
    },
    {
      enabled: !!debouncedQuery && !!product,
      onSuccess: (data) => {
        data.activities.forEach((activity) => {
          queryClient.setQueryData<GetActivityQuery>(
            useGetActivityQuery.getKey({ id: activity.id }),
            {
              activity: {
                id: activity.id,
                label: activity.label,
                category: {
                  id: activity.categoryId,
                  label: activity.categoryLabel,
                  sector: {
                    id: activity.sectorId,
                    label: activity.sectorLabel,
                  },
                },
              },
            }
          )
        })
      },
    }
  )

  const options =
    data?.activities.map((activity) => ({
      id: activity.id,
      label: activity.label,
      category: {
        id: activity.categoryId,
        label: activity.categoryLabel,
        sector: {
          id: activity.sectorId,
          label: activity.sectorLabel,
        },
      },
    })) || []

  if (isFetchingValue) {
    return <Loader />
  }

  return (
    <AutoComplete
      options={options}
      value={value}
      className={className}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => `${option.label}`}
      placeholder="Développeur web, Carreleur, Danseur, ..."
      label={label}
      onClear={() => {
        setQuery('')
        onChange(undefined)
      }}
      onQueryChange={setQuery}
      onChange={(value) => onChange(value?.id)}
      endAdornment={endAdornment}
      render={(activity) => (
        <div>
          <p className="mr-2 font-medium tracking-tight text-primary-900">{activity.label}</p>
          {/* <p className="text-primary-900 tracking-tight text-opacity-80">{activity.description}</p> */}
          <p className="text-sm tracking-tighter text-primary-900 text-opacity-80">
            {activity.category.sector.label} {'>'} {activity.category.label}
          </p>
        </div>
      )}
      loading={isFetching || debouncedQuery !== query}
    />
  )
}

export default ActivityAutoComplete

import { useEffect } from 'react'
import { useLocation } from 'react-router'

const RoutingDevTools: React.FC = import.meta.env.DEV
  ? () => {
      const location = useLocation()

      useEffect(() => {
        // eslint-disable-next-line no-console
        console.info(
          `➡️ %c Going to ${location.pathname} %o`,
          `
    color: blue;
    font-weight: bold;
    `,
          location
        )
      }, [location])

      return null
    }
  : () => null

export default RoutingDevTools

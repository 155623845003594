import { faArrowRight, faCalendar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from 'react-router'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { track } from '../../utils/analytics'
import { TipsCard } from '../components/TipsCard'

import { Button, RadioGroup } from '@olino/design-system'

enum ExpectedContractStartDateEnum {
  NOW = 'NOW',
  END_OF_WEEK = 'END_OF_WEEK',
  IN_THREE_MONTHS = 'IN_THREE_MONTHS',
  PRECISE_DATE = 'PRECISE_DATE',
}

const schema = z.object({
  expectedContractStartDate: z.union([
    z.literal(ExpectedContractStartDateEnum.NOW),
    z.literal(ExpectedContractStartDateEnum.END_OF_WEEK),
    z.literal(ExpectedContractStartDateEnum.IN_THREE_MONTHS),
    z.literal(ExpectedContractStartDateEnum.PRECISE_DATE),
  ]),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { handleSubmit, control } = useForm<FormInputs>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      expectedContractStartDate: ExpectedContractStartDateEnum.NOW,
    },
  })

  const { proceed } = useOnboardingWorkflow()

  const onSubmit = async (data: FormInputs) => {
    track({
      event: 'submitted_tailored_cover_date',
      quotation_id: id,
    })

    // TODO: Do something with the data

    // eslint-disable-next-line no-console
    console.log(data)

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_tailored_cover_date',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Votre demande nécessite une attention particulière !</OnboardingTitle.H1>

      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <b>Un de nos spécialistes</b> va se pencher sur votre dossier pour vous proposer une
          couverture adaptée à votre activité et{' '}
          <b>revient vers vous dans les plus brefs délais.</b>
        </div>

        <Controller
          control={control}
          name="expectedContractStartDate"
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={onChange}
              label="À partir de quand souhaitez-vous être protégés ?"
              options={Object.values(ExpectedContractStartDateEnum)}
              optionClassName="flex items-center p-3"
              direction="col"
              getOptionLabel={(option) => {
                switch (option) {
                  case ExpectedContractStartDateEnum.NOW:
                    return 'Aujourd’hui'
                  case ExpectedContractStartDateEnum.END_OF_WEEK:
                    return 'À la fin de la semaine'
                  case ExpectedContractStartDateEnum.IN_THREE_MONTHS:
                    return 'Dans 3 mois'
                  case ExpectedContractStartDateEnum.PRECISE_DATE:
                    return 'À une date précise'
                  default:
                    throw new Error('Invalid option')
                }
              }}
            />
          )}
        />

        <div className="flex items-center justify-end gap-3">
          <Button variant="outlined" onClick={() => navigate(`../tailored--book-meeting`)}>
            <FontAwesomeIcon icon={faCalendar} />
            Prendre rendez-vous
          </Button>

          <OnboardingNextButton>
            Transmettre mon dossier
            <FontAwesomeIcon icon={faArrowRight} />
          </OnboardingNextButton>
        </div>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <div className="space-y-3">
      <TipsCard
        title="Olino Plus"
        content={[
          "En dépit de nos meilleurs efforts, nous avons parfois besoin qu'un de nos expert se penche sur votre dossier.",
          "Pour vous, c'est l'opportunité d'un accompagnement humain pour trouver une solution d'assurance qui vous corresponde !",
        ]}
      />
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'

import { FormError, Input } from '@olino/design-system'

export const SummaryEmail = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      value={{ email: quotation?.details?.contact?.email ?? '' }}
      displayValue={({ email }) => email}
      label="Email :"
      schema={z.object({
        email: z
          .string({ required_error: 'Ce champ est requis' })
          .trim()
          .email({ message: "L'email n'est pas valide" }),
      })}
      onSave={({ email }) =>
        updateQuotation({
          details: {
            contact: {
              email,
            },
          },
        })
      }
      input={({ control }) => (
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <Input
                type="email"
                autoComplete="email"
                placeholder="email@exemple.com"
                value={value}
                onChange={onChange}
                error={!!error}
              />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useCheckIfUserEmailExistsQuery } from '../../generated/graphql'
import useDebounce from '../../utils/useDebounce'
import { useQuotation } from '../context'
import { track } from '../../utils/analytics'

import { useAuth } from '@olino/auth-client/react'
import { Input, FormError } from '@olino/design-system'

const schema = z.object({
  email: z
    .string({ required_error: 'Entrez une adresse email valide' })
    .email({ message: 'Entrez une adresse email valide' }),
})

type FormInput = z.infer<typeof schema>

const Main = () => {
  const { id } = useParams()
  const { isLoggedIn } = useAuth()
  const { proceed } = useOnboardingWorkflow()
  const { quotation } = useQuotation()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<FormInput>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      email: quotation?.details.contact?.email ?? undefined,
    },
  })

  const email = watch('email')

  const emailQuery = useDebounce(email, 350)
  const isDebounced = email !== emailQuery

  const { data, isFetching } = useCheckIfUserEmailExistsQuery(
    {
      email: emailQuery,
    },
    {
      enabled: isValid && emailQuery != null,
    }
  )

  const userExists = data?.checkIfUserEmailExists != null ? data.checkIfUserEmailExists : true

  const onSubmit = handleSubmit(() => {
    if (!isValid || !userExists || isDebounced) {
      // 'SIGN UP'
    } else {
      // SIGN IN
    }

    track({
      event: 'submitted_authenticate',
      quotation_id: id,
    })

    proceed(undefined, { email }) // Move to sign in
  })

  useEffect(() => {
    if (isLoggedIn) {
      proceed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    track({
      event: 'visited_authenticate',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Authentifiez-vous</OnboardingTitle.H1>

      <form className="flex flex-col items-stretch gap-8" onSubmit={onSubmit}>
        <p className="leading-relaxed text-grey-500">
          Entrez votre email, nous vous enverrons un code de connexion.
        </p>

        <div className="col-span-6">
          <Input label="Email" type="email" {...register('email')} />
          <FormError error={errors.email} />
        </div>

        <OnboardingNextButton loading={isFetching} className="w-full" onClick={() => onSubmit()}>
          {isValid && !userExists && !isFetching && !isDebounced ? "Je m'inscris" : 'Continuer'}
          <FontAwesomeIcon icon={faArrowRight} />
        </OnboardingNextButton>

        {isValid && !userExists && !isFetching && !isDebounced ? (
          <Link to="/onboarding" className=" text-left text-sm text-warning-600 hover:underline">
            Aucun compte n&apos;est associé à cette adresse email.
          </Link>
        ) : (
          <Link to="/onboarding" className="mx-auto text-sm hover:underline">
            Pas de compte ? <span className="font-bold">Inscrivez-vous</span>
          </Link>
        )}
      </form>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

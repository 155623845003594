import React from 'react'

import { cn } from '../utils'

export type CardProps = {
  tag?: React.ReactNode | string
  tagColor?: 'green' | 'red'
} & React.HTMLAttributes<HTMLDivElement>

export const Card = React.forwardRef<HTMLDivElement, React.PropsWithChildren<CardProps>>(
  ({ children, tag, tagColor = 'green', className, ...rest }, ref) => (
    <div
      {...rest}
      className={cn(
        'relative',
        'h-min rounded-xl border border-grey-200 bg-white',
        tag && 'mt-8',
        className
      )}
      ref={ref}
    >
      {children}

      {tag && (
        <div className="absolute inset-x-0 bottom-full">
          <div
            className={cn(
              'mx-auto w-min whitespace-nowrap rounded-t-md px-6 py-1.5 text-center text-sm font-medium text-white',
              tagColor === 'red' && 'bg-danger-400',
              tagColor === 'green' && 'bg-primary-600'
            )}
          >
            {tag}
          </div>
        </div>
      )}
    </div>
  )
)
Card.displayName = 'Card'

export interface CardActionsProps {
  className?: string
}

export const CardActions: React.FC<React.PropsWithChildren<CardActionsProps>> = ({
  children,
  className,
}) => <div className={cn('mx-auto px-6 pb-4', className)}>{children}</div>

CardActions.displayName = 'CardActions'

export interface CardContentProps {
  className?: string
}

export const CardContent: React.FC<React.PropsWithChildren<CardContentProps>> = ({
  children,
  className,
}) => <div className={cn('px-4 py-4 sm:px-6', className)}>{children}</div>

CardContent.displayName = 'CardContent'

export interface CardHeaderProps {
  className?: string
}

const CardHeaderContainer: React.FC<React.PropsWithChildren<CardHeaderProps>> = ({
  children,
  className,
}) => <div className={cn('border-gray-200 border-b px-6 py-2', className)}>{children}</div>

export interface CardHeaderTitleProps {
  className?: string
}

const CardHeaderTitle: React.FC<React.PropsWithChildren<CardHeaderTitleProps>> = ({
  children,
  className,
}) => <h2 className={cn('py-2 text-lg font-semibold', className)}>{children}</h2>

export const CardHeader = Object.assign(CardHeaderContainer, { Title: CardHeaderTitle })

CardHeader.displayName = 'CardHeader'

export interface CardListProps {
  className?: string
}

export const CardList: React.FC<React.PropsWithChildren<CardListProps>> = ({
  children,
  className,
}) => <ul className={cn('empty:py-3', className)}>{children}</ul>

CardList.displayName = 'CardList'

export interface CardListItemProps {
  className?: string
}

export const CardListItem: React.FC<React.PropsWithChildren<CardListItemProps>> = ({
  children,
  className,
}) => <li className={cn('px-6 py-3', className)}>{children}</li>

CardListItem.displayName = 'CardListItem'

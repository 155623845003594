import React, { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

type Size = 'big' | 'small' | 'medium'

export interface LoaderProps {
  className?: string
  size?: Size
}

export const Loader: React.FC<LoaderProps> = ({ className, size = 'medium' }) => {
  const dotStyle = useMemo(
    () =>
      twMerge(
        'rounded-full bg-current transition-none',
        size === 'big' && 'h-4 w-4 animate-bounce',
        size === 'medium' && 'h-3 w-3 animate-bounce',
        size === 'small' && 'h-2 w-2 animate-bounceHigh'
      ),
    [size]
  )

  return (
    <div
      className={twMerge(
        'inline-flex items-center justify-center',
        size === 'big' && 'space-x-2.5 p-5',
        size === 'medium' && 'space-x-2 p-4',
        size === 'small' && 'space-x-1.5 p-3',
        className
      )}
    >
      <div className={twMerge(dotStyle, '')} />
      <div className={twMerge(dotStyle, 'delay-200')} />
      <div className={twMerge(dotStyle, 'delay-500')} />
    </div>
  )
}

Loader.displayName = 'Loader'

import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { twMerge } from 'tailwind-merge'

const CoverListOption: React.FC<{ label: string; option: boolean }> = ({ label, option }) => (
  <li className="flex flex-col gap-1 text-sm md:flex-row md:items-center md:justify-between">
    <div className="flex gap-2">
      <FontAwesomeIcon
        icon={option ? faCheck : faTimes}
        className={twMerge('h-5 w-5', option ? 'text-primary-600' : 'text-grey-300')}
      />
      <p className={twMerge('font-semibold', option ? 'text-inherit' : 'text-grey-300')}>{label}</p>
    </div>

    <p
      className={twMerge(
        'ml-6 whitespace-nowrap font-light',
        option ? 'text-grey-500' : 'text-grey-300'
      )}
    >
      {option ? 'incluse' : 'non incluse'}
    </p>
  </li>
)

export default CoverListOption

import { faCheckCircle, faGear } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useQuotation } from '../context'

import { OnboardingTitle } from './OnboardingTitle'
import { FormattedPrice } from './FormattedPrice'

import { Button, Card, CardContent, Select, Checkbox } from '@olino/design-system'

const deductibles = [0, 3, 5]
const compensationPeriods = [12, 18, 24, 36]

const title = 'Pertes financières'
const description =
  "L'option pertes financières garantit la perte de marge brute ainsi que les frais de pertes additionnels consécutifs à un dommage matériel."

export const PricingMRPFinancialLossOption: React.FC = () => {
  const { quotation, updateQuotation } = useQuotation()

  const [configDialogOpen, setConfigDialogOpen] = useState(false)

  const details = quotation.details.multiRisk
  const premium = quotation.premium?.multiRisk

  const selected = details?.financialLossStandardOption || details?.financialLossAdvantageOption

  const price = details?.financialLossAdvantageOption
    ? premium?.financialLossAdvantageOption
    : premium?.financialLossStandardOption

  const onClick = () => {
    if (details?.financialLossStandardOption || details?.financialLossAdvantageOption) {
      updateQuotation({
        details: {
          multiRisk: {
            financialLossStandardOption: false,
            financialLossAdvantageOption: false,
          },
        },
      })
    } else {
      updateQuotation({
        details: {
          multiRisk: {
            financialLossStandardOption: true,
            financialLossAdvantageOption: false,
          },
        },
      })
      setConfigDialogOpen(true)
    }
  }

  const onConfigClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setConfigDialogOpen(true)
  }

  return (
    <>
      <div className="flex flex-row items-stretch">
        <button type="button" role="switch" aria-checked={selected ?? false} onClick={onClick}>
          <Card
            className={twMerge(
              'cursor-pointer overflow-hidden transition hover:border-primary-900',
              selected && 'border-primary-900 shadow-clone-xl'
            )}
          >
            <CardContent className="flex flex-row items-center">
              <div
                className={twMerge(
                  'mr-3 flex h-7 w-7 items-center justify-center rounded-full border border-grey-300 sm:mr-5'
                )}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={twMerge(
                    'h-7 w-7 text-primary-600',
                    'transition-transform ease-in-out',
                    selected ? 'rotate-0 scale-100' : 'rotate-90 scale-0'
                  )}
                />
              </div>

              <div className="flex flex-grow flex-col sm:flex-row sm:items-center">
                <div className="flex-grow">
                  <div className="flex flex-row flex-wrap items-center">
                    <OnboardingTitle.H3Pricing className="font-bold">
                      {title}
                    </OnboardingTitle.H3Pricing>
                  </div>
                  <p className="hidden text-left text-sm sm:block">{description}</p>
                </div>

                <p className="my-1 text-left text-sm font-light sm:hidden">{description}</p>

                {price ? <FormattedPrice size="sm" originalAmount={price} hideLoader /> : null}
              </div>
            </CardContent>
          </Card>
        </button>

        <button
          className={twMerge(
            'flex flex-shrink-0 items-center justify-center rounded-lg border bg-grey-100 transition-all hover:bg-grey-200',
            selected
              ? 'ml-4 w-10 opacity-100 sm:w-14'
              : 'pointer-events-none w-0 border-none opacity-0'
          )}
          type="button"
          onClick={onConfigClick}
        >
          <FontAwesomeIcon icon={faGear} className="w-full text-grey-400" />
        </button>
      </div>

      <Transition appear show={configDialogOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => setConfigDialogOpen(false)}>
          {/* Backdrop */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-info-200 bg-opacity-25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-2xl">
                  <Card>
                    <CardContent className="px-11 py-6">
                      <Dialog.Title as="h3" className="mb-2 text-2xl font-bold text-primary-900">
                        {title}
                      </Dialog.Title>

                      <p>
                        L'option pertes financières garantit la perte de marge brute ainsi que les
                        frais de pertes additionnels consécutifs à un dommage matériel.
                      </p>

                      <h4 className="mt-3 text-base font-bold">Franchise (en nombre de jours)</h4>
                      <p>Il s'agit du nombre de jours à partir duquel vous êtes indemnisé.</p>
                      <Select
                        options={deductibles}
                        value={details?.financialLossDeductibleAmount}
                        onChange={(value) =>
                          updateQuotation({
                            details: {
                              multiRisk: {
                                financialLossDeductibleAmount: value,
                              },
                            },
                          })
                        }
                      />

                      <h4 className="mt-3 text-base font-bold">
                        Période d'indemnité (en nombre de jours)
                      </h4>
                      <p>Il s'agit du nombre de jours pendant lesquels vous êtes indemnisé.</p>
                      <Select
                        options={compensationPeriods}
                        value={details?.financialLossCompensationPeriod}
                        onChange={(value) =>
                          updateQuotation({
                            details: {
                              multiRisk: {
                                financialLossCompensationPeriod: value,
                              },
                            },
                          })
                        }
                      />
                      {quotation.premium?.multiRisk?.financialLossAdvantageOption && (
                        <>
                          <h4 className="mt-3 text-lg font-bold">
                            Pertes financières premium (en nombre de jours)
                          </h4>
                          <p>
                            L'option pertes financières premium reprend les mêmes garanties et y
                            ajoute :{' '}
                          </p>
                          <ul className="list-disc pl-6">
                            <li>Perte de la valeur vénale du fonds de commerce.</li>
                            <li>Carence des fournisseurs</li>
                            <li>Désaffection de la clientèle</li>
                          </ul>

                          <Checkbox
                            label="Souhaitez-vous opter pour la garantie pertes financières premium ?"
                            value={details?.financialLossAdvantageOption ?? false}
                            onChange={(value) =>
                              updateQuotation({
                                details: {
                                  multiRisk: {
                                    financialLossAdvantageOption: value,
                                    financialLossStandardOption: !value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}

                      <div className="mt-4">
                        <Button
                          className="mx-auto"
                          size="sm"
                          onClick={() => setConfigDialogOpen(false)}
                        >
                          Confirmer
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

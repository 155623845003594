import { isNil } from 'ramda'
import React, { useContext, createContext, useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router'

import { getGotoPath, isCompleted, type WorkflowStep, type WorkflowSteps } from './types'

const findFirstNotCompletedStep = <TPath extends string, TData>({
  steps,
  data,
}: {
  steps: WorkflowSteps<TPath, TData>
  data?: TData
}): [TPath, WorkflowStep<TPath, TData>] | undefined => {
  const initialStep = Object.entries<WorkflowStep<TPath, TData>>(steps).find(
    ([, step]) => step.initial
  )

  if (!initialStep) throw new Error('⚠️ No initial step is configured')

  const recurse = (
    [path, step]: [TPath, WorkflowStep<TPath, TData>],
    depth = 0
  ): [TPath, WorkflowStep<TPath, TData>] => {
    if (depth > 100) {
      // eslint-disable-next-line no-console
      console.warn('Max recursive depth reached in `findFirstNotCompletedStep`')
      return [path, step]
    }

    if (!isCompleted(step, data)) return [path, step]

    const gotoPath = getGotoPath(step, data)

    const goto = Object.entries<WorkflowStep<TPath, TData>>(steps).find(
      ([path]) => path === gotoPath
    )

    if (!goto) return [path, step]

    return recurse(goto as [TPath, WorkflowStep<TPath, TData>], depth + 1)
  }

  return recurse(initialStep as [TPath, WorkflowStep<TPath, TData>])
}

const findCurrentStep = <TPath extends string, TData>({
  steps,
  currentPath,
  basePath,
}: {
  steps: WorkflowSteps<TPath, TData>
  currentPath: string
  basePath: string
}) => {
  return Object.entries<WorkflowStep<TPath, TData>>(steps).find(([p]) =>
    matchPath(`${basePath.replace(/\/$/, '')}/${p}`, currentPath)
  ) as [TPath, WorkflowStep<TPath, TData>] | undefined
}

const findNextStep = <TPath extends string, TData>({
  steps,
  currentPath,
  data,
  basePath,
}: {
  steps: WorkflowSteps<TPath, TData>
  currentPath: string
  data?: TData
  basePath: string
}) => {
  const currentStep = findCurrentStep<TPath, TData>({ steps, currentPath, basePath })

  if (!currentStep) return undefined

  const [, step] = currentStep

  const goto = getGotoPath(step, data)

  if (!goto) return undefined

  const gotoStep = Object.entries<WorkflowStep<TPath, TData>>(steps).find(([p]) => p === goto)

  if (!gotoStep) return undefined

  return gotoStep as [TPath, WorkflowStep<TPath, TData>]
}

type ContextInterface<TPath extends string, TData> = {
  initialStep?: [string, WorkflowStep<TPath, TData>]
  currentStep?: [string, WorkflowStep<TPath, TData>]
  nextStep: [string, WorkflowStep<TPath, TData>] | undefined
  nextNotCompletedStep: [string, WorkflowStep<TPath, TData>] | undefined

  proceed: (action?: () => TData, searchParams?: Record<string, string>) => void
}

type Props<TPath extends string, TData> = {
  basePath: string
  steps: WorkflowSteps<TPath, TData>
  data?: TData
}

const createProvider =
  <TPath extends string, TData>(context: React.Context<ContextInterface<TPath, TData>>) =>
  ({ children, basePath, steps, data }: React.PropsWithChildren<Props<TPath, TData>>) => {
    // It's ok because this will be called inside react components
    /* eslint-disable react-hooks/rules-of-hooks */
    const location = useLocation()
    const navigate = useNavigate()
    /* eslint-enable react-hooks/rules-of-hooks */

    const initialStep = useMemo(
      () => Object.entries<WorkflowStep<TPath, TData>>(steps).find(([, step]) => step.initial),
      [steps]
    )

    const nextNotCompletedStep = findFirstNotCompletedStep({ steps, data })

    const proceed = (action?: () => TData, searchParams?: Record<string, string>) => {
      let newData = data

      if (!isNil(action)) {
        newData = action()
      }

      const [, currentStep] =
        findCurrentStep({ steps, basePath, currentPath: location.pathname }) ?? []
      const [nextPath] =
        findNextStep({ steps, basePath, currentPath: location.pathname, data: newData }) ?? []

      if (nextPath) {
        navigate(
          searchParams
            ? `./${nextPath}?${new URLSearchParams(searchParams).toString()}`
            : `./${nextPath}`,
          {
            replace: currentStep?.hideBackward,
          }
        )
      } else {
        navigate(`./${initialStep?.[0]}`, { relative: 'path', replace: true })
        // eslint-disable-next-line no-console
        console.warn('No further step was found')
      }
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value: ContextInterface<TPath, TData> = {
      initialStep,
      currentStep: findCurrentStep({ steps, currentPath: location.pathname, basePath }),
      nextStep: findNextStep({ steps, currentPath: location.pathname, data, basePath }),
      nextNotCompletedStep,
      proceed,
    }

    return <context.Provider value={value}>{children}</context.Provider>
  }

const createWorkflow = <TPath extends string, TData>() => {
  const context = createContext({} as ContextInterface<TPath, TData>)
  const provider = createProvider(context)

  return {
    context,
    provider,
    useContext: () => useContext(context),
  }
}

export {
  createWorkflow,
  Props as WorkflowProviderProps,
  ContextInterface as WorkflowContextInterface,
}

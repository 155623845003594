import LibInputPhone, {
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/fr.json'
import { z } from 'zod'
import { Fragment, useState } from 'react'
import flags from 'react-phone-number-input/flags'
import { Listbox, Transition } from '@headlessui/react'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-phone-number-input/style.css'
import './PhoneInput.css'
import { cn } from '../utils'
import { Input } from '../input/Input'

const schema = z
  .string({
    required_error: 'Le numéro de téléphone est requis',
  })
  .refine(isPossiblePhoneNumber, {
    message: 'Le numéro de téléphone doit être au format international',
  })
  .transform((v) => v.replace(/\s/g, ''))

export type InputPhoneProps = {
  value: string
  onChange: (value: string) => void
  hideLabel?: boolean
}

type CountryCode = ReturnType<typeof getCountries>[number]

const Flag = ({ country, className }: { country?: CountryCode | null; className?: string }) => {
  return (
    <>
      {country && (
        <span className={cn('overflow-hidden rounded border', className)}>
          {flags[country]?.({ title: labels[country] })}
        </span>
      )}
      {!country && <span className={cn('', className)}>🌐</span>}
    </>
  )
}

// Define the list of countries to show in the listbox, in the order we want, and with '|' behaving as a separator.
const countries: Array<CountryCode | '|'> = [
  'FR',
  'GF',
  'RE',
  'PM',
  'YT',
  'BL',
  'MF',
  'WF',
  'PF',
  'NC',
  '|',
]
countries.push(
  ...getCountries()
    .filter((code) => !countries.includes(code))
    .sort((a, b) => labels[a].localeCompare(labels[b]))
)

const Component = ({ value, onChange, hideLabel }: InputPhoneProps) => {
  const [country, setCountry] = useState<CountryCode | null>('FR')

  return (
    <div className={cn('group relative')}>
      <Listbox
        value={country}
        onChange={(value) => {
          onChange(value ? `+${getCountryCallingCode(value)}` : '')
          setCountry(value)
        }}
      >
        {() => (
          <>
            {/* <CountrySelect value={country} onChange={setCountry} /> */}
            <LibInputPhone
              placeholder="+33 6 12 34 56 78"
              defaultCountry="FR"
              value={value}
              onChange={onChange}
              international
              label={hideLabel ? undefined : 'Téléphone'}
              className="text-left" // Otherwise the text is centered, idk why
              country={country}
              inputComponent={Input}
              onCountryChange={(c) => c && setCountry(c ?? null)}
              startAdornment={
                <Listbox.Button className="flex items-center justify-center gap-1">
                  <Flag country={country} className="w-7" />
                  <FontAwesomeIcon icon={faCaretDown} className="text-gray-400 text-sm" />
                </Listbox.Button>
              }
            />
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute inset-x-0 z-20 mt-2 max-h-52 overflow-x-hidden overflow-y-scroll rounded-md border border-grey-300 bg-white py-1 shadow-sm outline-1 outline-primary-600">
                {countries.map((option, i) =>
                  option === '|' ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="bg-gray-200 mx-auto my-2 h-px w-full" key={`|${i}`} />
                  ) : (
                    <Listbox.Option key={option} value={option} as={Fragment}>
                      {({ active, selected }) => (
                        <li
                          className={cn(
                            'cursor relative flex flex-row items-center py-1 pl-3 pr-10 text-sm',
                            selected && 'bg-primary-600 text-white',
                            active && !selected && 'bg-gray-100 text-black',
                            !selected && !active && 'bg-white text-black'
                          )}
                        >
                          <Flag country={option} className="mr-1.5 w-5 flex-shrink-0" />
                          <span className="mr-1 line-clamp-1">{labels[option]}</span>
                          <span className="text-gray-500 font-light">
                            +{getCountryCallingCode(option)}
                          </span>
                        </li>
                      )}
                    </Listbox.Option>
                  )
                )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}

Component.displayName = 'InputPhone'

export const PhoneInput = Object.assign(Component, {
  schema,
})

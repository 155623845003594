import { useQuotation } from '../context'
import formatPrice from '../../utils/formatPrice'

import { OnboardingTitle } from './OnboardingTitle'

import { Hint, Select } from '@olino/design-system'

const deductibleAmounts = [300, 500, 750, 1_000, 1_500, 2_000, 2_500, 3_000, 5_000, 10_000]

export const PricingMRPDeductibleSlider: React.FC = () => {
  const { quotation, updateQuotation } = useQuotation()

  const details = quotation.details.multiRisk

  return (
    <div className="flex items-center gap-3">
      <OnboardingTitle.H3Pricing>Montant de votre franchise :</OnboardingTitle.H3Pricing>

      <Select
        className="min-w-[180px]"
        options={deductibleAmounts}
        value={details?.deductibleAmount || 1_000}
        getOptionLabel={(value) => formatPrice(value, 0)}
        onChange={(value) =>
          updateQuotation({
            details: {
              multiRisk: {
                deductibleAmount: value,
              },
            },
          })
        }
      />

      <Hint>
        Le <span className="font-semibold">montant de la franchise</span> correspond au reste à
        charge qu'il vous faudra régler en cas de sinistre. Vous pouvez le modifier.
      </Hint>
    </div>
  )
}

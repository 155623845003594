import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { useQuotation } from '../context'
import { useZapier } from '../../hooks/useZapier'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { TipsCard } from '../components/TipsCard'
import { track } from '../../utils/analytics'

import { RadioGroup } from '@olino/design-system'

const schema = z.object({
  isUnderContractChecked: z.boolean(),
  isCancelContractChecked: z.boolean(),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { id } = useParams()
  const { quotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const { trigger } = useZapier()

  const { handleSubmit, watch, control, resetField } = useForm<FormInputs>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      isUnderContractChecked: false,
      isCancelContractChecked: false,
    },
  })

  const isUnderContractCheckedValue = watch('isUnderContractChecked')

  const onSubmit = handleSubmit(async (data) => {
    if (!quotation) throw new Error('Quotation is not defined')

    track({
      event: 'submitted_termination',
      quotation_id: id,
    })

    try {
      if (data.isCancelContractChecked) {
        const contractStartDate = quotation.details.startDate ?? undefined

        await trigger('cancel_contract', {
          quotationID: quotation.id,
          product: quotation.details?.products?.at(0),
          email: quotation.details?.contact?.email ?? undefined,
          phone: quotation.details?.contact?.phone ?? undefined,
          firstName: quotation.details?.contact?.firstName ?? undefined,
          lastName: quotation.details?.contact?.lastName ?? undefined,
          contractStartDate,
        })
      }

      proceed()
    } catch (e) {
      console.error(e)
    }
  })

  useEffect(() => {
    if (!isUnderContractCheckedValue) {
      resetField('isCancelContractChecked')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnderContractCheckedValue])

  useEffect(() => {
    track({
      event: 'visited_termination',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>
        Souhaitez-vous que nous résilions votre ancien contrat&nbsp;?
      </OnboardingTitle.H1>

      <form onSubmit={onSubmit} className="flex flex-col gap-12">
        <Controller
          name="isUnderContractChecked"
          control={control}
          render={({ field }) => (
            <RadioGroup
              label="Avez-vous déjà un contrat ?"
              hint="Contentez-vous de répondre à la question, nous nous occupons de tout !"
              options={[true, false]}
              value={field.value}
              onChange={field.onChange}
              getOptionLabel={(option) => {
                switch (option) {
                  case true:
                    return 'Oui'
                  case false:
                    return 'Non'
                  default:
                    throw new Error('Invalid option')
                }
              }}
              direction="col"
              optionsContainerClassName="space-y-3"
              optionClassName="flex items-center"
            />
          )}
        />

        {isUnderContractCheckedValue && (
          <Controller
            name="isCancelContractChecked"
            control={control}
            render={({ field }) => (
              <RadioGroup
                label="Souhaitez vous que nous résilions l'ancien contract ?"
                options={[true, false]}
                value={field.value}
                getOptionDisabled={() => !isUnderContractCheckedValue}
                onChange={field.onChange}
                getOptionLabel={(option) => {
                  switch (option) {
                    case true:
                      return 'Oui'
                    case false:
                      return 'Non'
                    default:
                      throw new Error('Invalid option')
                  }
                }}
                direction="col"
                optionsContainerClassName="space-y-3"
                optionClassName="flex items-center"
              />
            )}
          />
        )}

        <div className="flex justify-end">
          <OnboardingNextButton />
        </div>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Vous pouvez être serein"
      content={[
        "Nous nous occupons gratuitement de clôturer vos contrats existants. Après la signature de votre nouveau contrat, vous n'aurez qu'à signer un simple document en ligne, pré-rempli par nos soins..",
        'Après la signature de votre contrat, nous vous enverrons un formulaire à remplir pour nous fournir les informations nécessaires, nous vous transmettrons ensuite un courrier à signer et enfin nous ferons les démarches nécessaires auprès de votre précédent assureur.',
      ]}
    />
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import { Route, Routes, useNavigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import usePartner from '../utils/usePartner'
import {
  type GetQuotationQuery,
  useCreateQuotationMutation,
  useGetQuotationQuery,
  useApplyQuotationPromoCodeMutation,
} from '../generated/graphql'
import { identify, track } from '../utils/analytics'

import { OnboardingProvider, useQuotation } from './context'
import { OnboardingLayout } from './layout'
import { MainContentRoutes, TipsRoutes } from './routes'
import { OnboardingWorkflowProvider, OnboardingWorkflowSteps } from './workflow'

import { Button, Logo } from '@olino/design-system'

const Onboarding = () => {
  const { quotation } = useQuotation()

  useEffect(() => {
    if (quotation.details.contact?.email) {
      identify({
        email: quotation.details.contact?.email,
      })
    }
  }, [quotation])

  return (
    <OnboardingWorkflowProvider
      steps={OnboardingWorkflowSteps}
      data={quotation}
      basePath="/onboarding/:id"
    >
      <OnboardingLayout main={<MainContentRoutes />} tips={<TipsRoutes />} />
    </OnboardingWorkflowProvider>
  )
}

const CreateOnboardingPage = () => {
  const { clearPartner, isPartnerPresent, code } = usePartner()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: createQuotation } = useCreateQuotationMutation({
    onSuccess: async (data) => {
      const id = data?.createQuotation?.quotation?.id
      if (!id) throw new Error('Failed to create quotation')

      track({
        event: 'new_quote_created',
        quotation_id: id,
      })

      navigate(`./${id}`, { replace: true })

      const queryKey = useGetQuotationQuery.getKey({ id })
      // Cancel any outgoing refetches (so they don't overwrite our written data)
      await queryClient.cancelQueries(queryKey)

      queryClient.setQueryData<GetQuotationQuery>(queryKey, {
        quotation: data.createQuotation.quotation,
      })
    },
    onError: () => {
      if (isPartnerPresent) {
        clearPartner()
        createQuotation({})
      }
    },
  })

  const { mutateAsync: addPromoCodeMutation } = useApplyQuotationPromoCodeMutation({
    onSuccess: (data) => {
      if (
        data.applyQuotationPromoCode.__typename === 'QuotationId' &&
        data.applyQuotationPromoCode.quotation
      ) {
        queryClient.setQueryData<GetQuotationQuery>(
          useGetQuotationQuery.getKey({ id: data.applyQuotationPromoCode.id }),
          { quotation: data.applyQuotationPromoCode.quotation }
        )
      }
    },
  })

  useEffect(() => {
    const lastQuotationId = localStorage.getItem('lastQuotationId')
    if (lastQuotationId) {
      navigate(`./${lastQuotationId}`, { replace: true })
      if (code) {
        addPromoCodeMutation({
          id: lastQuotationId,
          promoCode: code,
        })
      }
    } else {
      createQuotation({
        promoCode: code,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const NotFoundPage = () => (
  <div className="flex h-screen w-full flex-col items-center justify-center gap-12 bg-primary-900 text-white">
    <Logo variant="text" className="w-52 fill-white" />
    <h1 className="text-3xl text-white">Le devis recherché est introuvable</h1>
    <Button asChild>
      <a href="/onboarding">Créer un nouveau devis</a>
    </Button>
  </div>
)

export default () => (
  <Routes>
    <Route
      path=":id/*"
      element={
        <OnboardingProvider>
          <Onboarding />
        </OnboardingProvider>
      }
    />
    <Route path="not-found" element={<NotFoundPage />} />
    <Route index element={<CreateOnboardingPage />} />
  </Routes>
)

/* eslint-disable import/prefer-default-export */
import * as z from 'zod'

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
const extractJwtPayload = (token: string) => {
  const base64Url = token.split('.')[1]

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const jwtPayloadSchema = z.object({
  sub: z.string(),
  email: z.string().email(),
  session_id: z.string(),
  'https://hasura.io/jwt/claims': z.object({
    'X-Hasura-Role': z.literal('user'),
    'X-Hasura-User-Id': z.string(),
    'X-Hasura-Session-Id': z.string(),
  }),
  iat: z.number(),
  exp: z.number(),
})

export type AuthUser = ReturnType<typeof parseJwtPayload>

export const parseJwtPayload = (jwt: string) => {
  const payload = extractJwtPayload(jwt)
  const parsing = jwtPayloadSchema.safeParse(payload)

  if (!parsing.success) throw new Error(`Invalid JWT payload: ${parsing.error.message}`)

  return {
    id: parsing.data.sub,
    email: parsing.data.email,
    sessionId: parsing.data.session_id,
    tokenExpiresAt: new Date(parsing.data.exp * 1000),
    tokenIssuedAt: new Date(parsing.data.iat * 1000),
  }
}

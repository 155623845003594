import { type MeQuery, useMeQuery } from '../generated/graphql'

import { useAuth } from '@olino/auth-client/react'

const useMe = (...vars: Parameters<typeof useMeQuery<MeQuery>>) => {
  const { isLoggedIn } = useAuth()

  const { data } = useMeQuery<MeQuery>(vars[0], {
    enabled: isLoggedIn,
    ...vars[1],
  })

  return data?.me?.user
}

export default useMe

import { RadioGroup } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-solid-svg-icons'

import { PaymentFrequency } from '../../generated/graphql'
import cn from '../../utils/cn'

export const PaymentFrequencyInput = ({
  value,
  onChange,
}: {
  value: PaymentFrequency
  onChange?: (value: PaymentFrequency) => void
}) => {
  return (
    <RadioGroup
      className="flex h-10 items-stretch justify-center gap-1 space-x-1 rounded-xl border border-primary-700/10 bg-primary-700/10 p-1"
      value={value}
      onChange={onChange}
    >
      <RadioGroup.Option
        value={PaymentFrequency.Annually}
        data-tooltip-id="payment-frequency-annually"
        className={({ checked }) =>
          cn(
            'flex w-full items-center justify-center rounded-lg text-sm font-semibold',
            'ring-primary-950 ring-opacity-60 focus-visible:outline-none focus-visible:ring-2',
            checked
              ? 'bg-primary-900 text-white shadow'
              : 'cursor-pointer text-primary-900/75 hover:bg-primary-50/40 hover:text-primary-900'
          )
        }
      >
        Annuel
        <FontAwesomeIcon icon={faTag} className="ml-2 opacity-80" />
      </RadioGroup.Option>

      <RadioGroup.Option
        value={PaymentFrequency.Monthly}
        className={({ checked }) =>
          cn(
            'flex w-full items-center justify-center rounded-lg text-sm font-semibold',
            'ring-primary-950 ring-opacity-60 focus-visible:outline-none focus-visible:ring-2',
            checked
              ? 'bg-primary-900 text-white shadow'
              : 'cursor-pointer text-primary-900/75 hover:bg-primary-50/40 hover:text-primary-900'
          )
        }
      >
        Mensuel
      </RadioGroup.Option>
    </RadioGroup>
  )
}

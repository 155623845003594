import { add, format, startOfDay } from 'date-fns'
import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'

import { DatePicker, FormError } from '@olino/design-system'

export const SummaryStartDate = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      label="Date de début de contrat :"
      value={{
        startDate: quotation?.details?.startDate
          ? new Date(quotation.details.startDate)
          : new Date(),
      }}
      displayValue={({ startDate }) => format(startDate, 'dd/MM/yyyy')}
      schema={z.object({
        startDate: z
          .date({ required_error: 'Une date valide est requise' })
          .min(startOfDay(new Date()), "La date doit être supérieure à aujourd'hui")
          .max(
            add(new Date(), { days: 90 }),
            "La date doit être inférieure à 90 jours après aujourd'hui"
          ),
      })}
      onSave={({ startDate }) =>
        updateQuotation({
          details: {
            startDate: format(startDate, 'yyyy-MM-dd'),
          },
        })
      }
      input={({ control }) => (
        <Controller
          control={control}
          name="startDate"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <DatePicker
                min={new Date()}
                max={add(new Date(), { days: 90 })}
                value={value}
                error={!!error}
                onChange={onChange}
              />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

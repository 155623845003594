import React, { useMemo } from 'react'
import { type VariantProps, cva } from 'class-variance-authority'

import { cn } from '../utils'

const loaderVariants = cva(['inline-flex', 'items-center', 'justify-center'], {
  variants: {
    size: {
      xs: 'space-x-1 p-2',
      sm: 'space-x-1.5 p-3',
      md: 'space-x-2 p-4',
      lg: 'space-x-2.5 p-5',
    },
  },
})

export interface LoaderProps
  extends React.BaseHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof loaderVariants> {}

export const Loader = React.forwardRef<HTMLDivElement, LoaderProps>(
  ({ size = 'md', className, children, ...props }, ref) => {
    const dotStyle = useMemo(
      () =>
        cn(
          'rounded-full bg-current transition-none',
          size === 'lg' && 'h-4 w-4 animate-bounce',
          size === 'md' && 'h-3 w-3 animate-bounce',
          size === 'sm' && 'h-2 w-2 animate-bounceHigh',
          size === 'xs' && 'h-1 w-1 animate-bounceHigh'
        ),

      [size]
    )

    return (
      <div ref={ref} className={cn(loaderVariants({ size, className }))} {...props}>
        <div className={cn(dotStyle, '')} />
        <div className={cn(dotStyle, 'delay-200')} />
        <div className={cn(dotStyle, 'delay-500')} />
      </div>
    )
  }
)
Loader.displayName = 'Loader'

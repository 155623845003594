const regex = /^(\d{3})(\d{3})(\d{3})(\d{5})$/

const nonBreakingSpace = ' '

const formatSiret = (siret: string): string => {
  const res = regex.exec(siret)
  return res?.slice(1).join(nonBreakingSpace) || ''
}

export default formatSiret

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { useEffect } from 'react'

import locale from '../../utils/locale'
import { type OnboardingPage } from '../routes'
import { useQuotation } from '../context'
import { OccupancyType } from '../../generated/graphql'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingButtons } from '../components/OnboardingButtons'
import { track } from '../../utils/analytics'

import { Input, FormError, Checkbox, Select } from '@olino/design-system'

const schema = z.object({
  contentValue: z
    .number({ required_error: 'Ce champ est requis' })
    .gt(0, { message: 'La valeur du contenu doit être supérieure à 0' }),
  occupancyType: z.nativeEnum(OccupancyType, { required_error: 'Ce champ est requis' }),
  historicalMonument: z.boolean({ required_error: 'Ce champ est requis' }),
  surfaceArea: z
    .number({ required_error: 'Ce champ est requis' })
    .gt(0, { message: 'La surface doit être supérieure à 0' }),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { id } = useParams()
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    defaultValues: {
      contentValue: quotation.details?.multiRisk?.contentValue ?? 5000,
      occupancyType: quotation.details?.multiRisk?.occupancyType ?? OccupancyType.Tenant,
      historicalMonument: quotation.details?.multiRisk?.historicalMonument ?? false,
      surfaceArea: quotation.details?.multiRisk?.surfaceArea ?? 100,
    },
    resolver: zodResolver(schema),
    mode: 'all',
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = handleSubmit((data) => {
    track({
      event: 'submitted_mrp_building_informations',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          multiRisk: {
            contentValue: data.contentValue,
            occupancyType: data.occupancyType,
            historicalMonument: data.historicalMonument,
            surfaceArea: data.surfaceArea,
          },
        },
      })
    )
  })

  useEffect(() => {
    track({
      event: 'visited_mrp_building_informations',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Quelques informations sur votre bien&nbsp;?</OnboardingTitle.H1>

      <form className="w-full space-y-8" onSubmit={onSubmit}>
        <div className="space-y-3">
          <Controller
            name="occupancyType"
            control={control}
            render={({ field }) => (
              <div className="col-span-6">
                <Select
                  label="Qualité de l'occupant"
                  options={Object.values(OccupancyType)}
                  getOptionLabel={(option) => {
                    switch (option) {
                      case OccupancyType.Owner:
                        return 'Propriétaire'
                      case OccupancyType.Tenant:
                        return 'Locataire'
                      case OccupancyType.FreeOccupant:
                        return 'Occupant à titre gratuit'
                      case OccupancyType.SubTenant:
                        return 'Sous-locataire'
                      case OccupancyType.TenantOnBehalfOfOwner:
                        return 'Locataire agissant pour le compte du propriétaire'
                      default:
                        return ''
                    }
                  }}
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                />
                <FormError error={errors.occupancyType} />
              </div>
            )}
          />

          <div className="flex flex-col items-center gap-3 sm:flex-row">
            <Controller
              name="surfaceArea"
              control={control}
              render={({ field }) => (
                <div className="w-full sm:w-[50%]">
                  <Input
                    label="Superficie de vos locaux"
                    endAdornment="m²"
                    value={field?.value?.toLocaleString(locale) || ''}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = parseInt(e.target.value.replace(/\D/g, ''), 10)

                      field.onChange(Number.isNaN(value) ? 0 : value)
                    }}
                  />
                  <FormError error={errors.surfaceArea} />
                </div>
              )}
            />

            <Controller
              name="contentValue"
              control={control}
              render={({ field }) => (
                <div className="w-full sm:w-[50%]">
                  <Input
                    label="Valeur du contenu"
                    endAdornment="€"
                    value={field?.value?.toLocaleString(locale) || ''}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = parseInt(e.target.value.replace(/\D/g, ''), 10)

                      field.onChange(Number.isNaN(value) ? 0 : value)
                    }}
                  />
                  <FormError error={errors.contentValue} />
                </div>
              )}
            />
          </div>

          <Controller
            name="historicalMonument"
            control={control}
            render={({ field }) => (
              <div className="col-span-6">
                <Checkbox
                  value={field.value}
                  label="Votre bien est-il un monument historique ?"
                  onChange={(v) => field.onChange(v)}
                  name={field.name}
                />
                <FormError error={errors.historicalMonument} />
              </div>
            )}
          />
        </div>

        <OnboardingButtons>
          <OnboardingNextButton disabled={!isValid} />
        </OnboardingButtons>
      </form>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import React, { useState } from 'react'

import formatSiret from '../../../utils/formatSiret'
import useDebounce from '../../../utils/useDebounce'
import NoOp from '../../../utils/NoOp'
import { type CompanySuggestion, useCompanyAutocompleteQuery } from '../../../generated/graphql'

import { AutoComplete } from '@olino/design-system'

export interface SiretAutoCompleteProps {
  className?: string
  onChange?: (value?: CompanySuggestion) => void
  value?: CompanySuggestion
  hint?: string
}

const SiretAutoComplete: React.FC<SiretAutoCompleteProps> = ({
  className,
  onChange = NoOp,
  value,
  hint,
}) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 300)

  const { data: results, isFetching } = useCompanyAutocompleteQuery(
    {
      query: debouncedQuery,
    },
    {
      enabled: debouncedQuery.length > 2,
    }
  )

  return (
    <AutoComplete
      options={results?.suggestions || []}
      value={value}
      onChange={onChange}
      getOptionKey={(option) => option.siret}
      getOptionLabel={(option) => `${option.name} - ${formatSiret(option.siret)}`}
      onClear={() => {
        setQuery('')
        onChange(undefined)
      }}
      className={className}
      placeholder="Entreprise"
      inputProps={{ autoComplete: 'organization' }}
      label="Nom ou SIRET de votre société"
      query={query}
      onQueryChange={setQuery}
      render={(company) => (
        <div>
          <div>
            <span className="mr-2 font-medium tracking-tight text-primary-900">{company.name}</span>
            <span className="text-sm tracking-tighter text-primary-900 text-opacity-80">
              {formatSiret(company.siret)}
            </span>
          </div>
          <div className="text-xs tracking-tighter text-primary-900 text-opacity-60">
            {company.address?.postalCode}, {company.address?.city}
          </div>
        </div>
      )}
      loading={isFetching || debouncedQuery !== query}
      hideResults={query.length < 3}
      hint={hint}
    />
  )
}

export default SiretAutoComplete

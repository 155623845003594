import React from 'react'
import { twMerge } from 'tailwind-merge'

import OptionSwitch from '../../components/quotation/optionSwitch/OptionSwitch'
import { useQuotation } from '../context'

import { PricingMRPFinancialLossOption } from './PricingMRPFinancialLossOption'

export const PricingMRPOptionsSection = React.forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { quotation, updateQuotation } = useQuotation()

    const details = quotation.details.multiRisk
    const premium = quotation.premium?.multiRisk

    return (
      <section className={twMerge('space-y-3', className)} id="options" ref={ref}>
        <h2 className="text-lg font-bold md:text-xl">Protections recommandées</h2>

        <p>
          Voici les protections généralement souscrites par nos clients ayant la même activité que
          vous.
        </p>

        <div className="space-y-3">
          <OptionSwitch
            title="Protection juridique"
            recommended
            description="En cas de conflit avec un tiers, vous êtes accompagné(e) par un juriste spécialisé pour trouver une solution amiable, si elle n'aboutit pas, vous bénéficiez d'un accompagnement dans la procédure judiciaire avec une prise en charge des frais (avocats, huissiers, expets, etc.)."
            price={premium?.legalProtectionOption ?? undefined}
            value={details?.legalProtectionOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: { multiRisk: { legalProtectionOption: value } },
              })
            }}
            infoDialog={{
              title: 'Protection juridique',
              content: (
                <>
                  <p className="text-sm">
                    En cas de conflit avec un tiers, vous êtes accompagné(e) par un juriste
                    spécialisé pour trouver une solution amiable, si elle n'aboutit pas, vous
                    bénéficiez d'un accompagnement dans la procédure judiciaire avec une prise en
                    charge des frais (avocats, huissiers, expets, etc.).
                  </p>
                  <p className="mt-3 font-semibold">Seuil d'intervention : 0€</p>
                  <h4 className="font-bold">Vous êtes couvert pour :</h4>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      L'accompagnement et la prise en charge des frais liés à la gestion amiable du
                      litige.
                    </li>
                    <li>
                      L'accompagnement et la prise en charge des frais liés à la phase judiciaire du
                      conflit, avec la liberté de choisir votre avocat.
                    </li>
                    <li>Le suivi jusqu'à la parfaite exécution des décisions de justice.</li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple d'intervention :</h4>
                  <p className="text-sm">
                    Un concurrent utilise un logo/ ou nom similaire au votre et dont vous avez la
                    propriété. Nous vous accompagnons pour faire respecter vos droits et défendre
                    votre propriété.{' '}
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Responsabilité civile exploitation"
            recommended
            description="La Responsabilie Civile d'Exploitation intervient en cas de dommages pendant la réalisation de vos prestations ou la vie courante de la société. "
            price={premium?.operatingLiabilityOption ?? undefined}
            value={details?.operatingLiabilityOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: { multiRisk: { operatingLiabilityOption: value } },
              })
            }}
            infoDialog={{
              title: "Responsabilité Civile d'exploitation",
              content: (
                <>
                  <p className="text-sm">
                    La Responsabilie Civile d'Exploitation intervient en cas de dommages pendant la
                    réalisation de vos prestations ou la vie courante de la société. Ces frais ne
                    sont pas couverts par votre Responsabilité Civile Professionnelle.
                  </p>
                  <p className="mt-1 font-semibold leading-tight">
                    Cette couverture est vivement recommandée si vous avez des salariés, si vos
                    clients se déplacent dans vos locaux ou bien si vous vous déplacez chez vos
                    clients.
                  </p>
                  <h4 className="font-bold">Vous êtes couvert pour :</h4>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      Les dommages corporels et/ou les dommages matériels et/ou les dommages
                      immatériels consécutifs.
                    </li>
                    <li>Les dommages immatériels non consécutifs.</li>
                    <li>Les frais de défense au titre des poursuites pénales.</li>
                    <li>La responsabilité civile employeur.</li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple d'intervention :</h4>
                  <p className="text-sm">
                    L'un de vos clients ou l'un de vos collaborateur se blesse dans vos locaux. Ou
                    bien, un salarié renverse un liquide sur l'ordinateur d'un client en
                    déplacement.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Assistance"
            description="L'assistance vous assure une tranquillité totale en cas de sinistre pour traiter l'urgence de la situation."
            price={premium?.assistanceOption ?? undefined}
            value={details?.assistanceOption ?? undefined}
            onChange={(value) => {
              updateQuotation({ details: { multiRisk: { assistanceOption: value } } })
            }}
            infoDialog={{
              title: 'Assistance',
              content: (
                <>
                  <p className="mb-2 text-sm">
                    L'assistance vous assure une tranquilité totale en cas de sinistre pour traiter
                    l'urgence de la situation.
                  </p>
                  <h4 className="font-bold">Vous êtes couvert pour :</h4>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      Retour anticipé de l'assuré sur le lieu du sinistre, si vous êtes en
                      déplacement par exemple.
                    </li>
                    <li>Réparations provisoires, nettoyage et gardiennage des bâtiments.</li>
                    <li>Recherche de locaux de remplacement provisoires.</li>
                    <li>Frais de transport vers des locaux de remplacement provisoires.</li>
                    <li>
                      Continuité de service, nous contactons à votre demande, vos
                      clients/fournisseurs afin de leur faire part de l'arrêt temporaire de vos
                      activités professionnelles.{' '}
                    </li>
                    <li>Vol ou perte des clés des bâtiments.</li>
                    <li>Recherche de prestataires pour la remise en état des bâtiments.</li>
                  </ul>
                </>
              ),
            }}
          />

          <PricingMRPFinancialLossOption />
        </div>
      </section>
    )
  }
)

import Cookies from 'js-cookie'

import { useGetPartnerFromPromoCodeQuery } from '../generated/graphql'
import { domain } from '../constants/config'

const usePartner = () => {
  const code = Cookies.get('partner_code')
  const isPartnerPresent = !!code

  const clearPartner = () => {
    Cookies.remove('partner_code', {
      domain: domain ? `.${domain}` : undefined,
    })
  }

  const { data: partnerQuery, isLoading } = useGetPartnerFromPromoCodeQuery(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      code: code!,
    },
    {
      enabled: isPartnerPresent,
      onError: clearPartner,
      onSuccess: (data) => {
        if (data.partners.length === 0) {
          clearPartner()
        }
      },
    }
  )

  const partner = partnerQuery?.partners.at(0)

  return {
    partner,
    isPartnerPresent,
    code,
    isLoading,
    clearPartner,
  }
}

export default usePartner

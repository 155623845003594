import { RadioGroup as HuiRadioGroup } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import React from 'react'

import { noop } from '../utils'
import { Hint } from '../hint/Hint'

interface Props<T> {
  options: T[] | readonly T[]
  value?: T
  onChange?: (value: T) => void
  name?: string

  getOptionLabel?: (option: T) => JSX.Element | string
  getOptionDisabled?: (option: T) => boolean
  getOptionKey?: (option: T) => string | number

  direction?: 'row' | 'col'

  label?: JSX.Element | string
  className?: string
  optionClassName?: string
  optionsContainerClassName?: string
  labelClassName?: string

  hint?: React.ReactNode
}

export const RadioGroup = <T,>({
  options,
  value = options[0],
  onChange = noop,
  getOptionLabel = (option) => `${option}`,
  getOptionDisabled = () => false,
  getOptionKey = (option) => `${option}`,
  className,
  direction = 'row',
  optionClassName,
  optionsContainerClassName,
  labelClassName,
  name,
  label,
  hint,
}: Props<T>) => {
  return (
    <HuiRadioGroup value={value} onChange={onChange} className={twMerge(className)} name={name}>
      {label && (
        <HuiRadioGroup.Label
          className={twMerge(
            'mb-1 flex items-center gap-2',
            'ml-0.5 text-sm transition-colors',
            'text-grey-600 focus-within:text-primary-950',
            labelClassName
          )}
        >
          {label}
          {!!hint && <Hint>{hint}</Hint>}
        </HuiRadioGroup.Label>
      )}
      <div
        className={twMerge(
          'flex gap-2',
          direction === 'row' && 'flex-row',
          direction === 'col' && 'flex-col',
          optionsContainerClassName
        )}
      >
        {options.map((option) => (
          <HuiRadioGroup.Option
            key={getOptionKey(option)}
            value={option}
            disabled={getOptionDisabled(option)}
            className={twMerge('group flex cursor-pointer flex-row items-center', optionClassName)}
          >
            {({ checked }) => (
              <>
                <div
                  className={twMerge(
                    'relative mr-2 h-6 w-6 flex-shrink-0 rounded-full transition-all',
                    checked ? 'bg-primary-600' : 'bg-grey-200 group-hover:bg-grey-300'
                  )}
                >
                  <div
                    className={twMerge(
                      'absolute inset-[2px] rounded-full bg-white transition-all',
                      checked ? 'scale-[0.5]' : 'group-hover:bg-grey-100'
                    )}
                  />
                </div>
                <HuiRadioGroup.Label
                  as="span"
                  className={`${
                    checked ? 'text-primary-950' : 'text-grey-600'
                  } block text-sm font-medium`}
                >
                  {getOptionLabel(option)}
                </HuiRadioGroup.Label>
              </>
            )}
          </HuiRadioGroup.Option>
        ))}
      </div>
    </HuiRadioGroup>
  )
}

RadioGroup.displayName = 'RadioGroup'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useEffect } from 'react'
import posthog from 'posthog-js'

import RoutingDevTools from './components/RoutingDevTools'
import { AuthProvider } from './contexts/auth'
import Pages from './pages'
import 'react-toastify/dist/ReactToastify.min.css'
import ScrollToTop from './utils/ScrollToTop'

import { initializeAuthClient } from '@olino/auth-client/js'
import { load as loadIntercom } from '@olino/intercom-js'

initializeAuthClient(`${import.meta.env.VITE_HASURA_GRAPHQL_ENDPOINT}/v1/graphql`)

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // 1 minute
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 500,
    },
    mutations: {
      retry: false,
    },
  },
})

if (import.meta.env.VITE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-KK4P2QZ',
  })

  TagManager.initialize({
    gtmId: 'GTM-KDXTCBQ',
  })
}

if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging') {
  posthog.init('phc_2YaqVXEcuSrdf1MW6ZGReYoIjYoQoCjg0FWPik9cmXL', {
    api_host: 'https://eu.posthog.com',
  })
} else {
  posthog.init('fake token', {
    autocapture: false,
    loaded: (ph) => ph.opt_out_capturing(),
  })
}

// window.intercomSettings = {
//   api_base: 'https://api-iam.intercom.io',
//   app_id: 'baxgnty5',
// }

const App = () => {
  useEffect(() => {
    loadIntercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      api_base: 'https://api-iam.intercom.io',
    })
  })

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RoutingDevTools />
          <AuthProvider>
            <Pages />
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>

      <ToastContainer position="bottom-right" bodyClassName="font-sans" />
    </>
  )
}

export default App

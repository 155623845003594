import { GraphQLClient } from 'graphql-request'

// eslint-disable-next-line import/no-mutable-exports
let client: GraphQLClient

const initializeClient = (hasuraEndpoint: string) => {
  client = new GraphQLClient(hasuraEndpoint)
}

export { client, initializeClient }

import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { ProductEnum, useGetQuotationCrmDealIdQuery } from '../../generated/graphql'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { track } from '../../utils/analytics'

import { Loader } from '@olino/design-system'

const getHeyflowID = (product?: ProductEnum) => {
  switch (product) {
    case ProductEnum.Auto:
    case ProductEnum.AutoMission:
      return `car`
    case ProductEnum.Legal:
      return `legal`
    case ProductEnum.Health:
    case ProductEnum.Life:
      return `health_mutuelle`
    case ProductEnum.KeyPerson:
      return `key_person`
    case ProductEnum.Cyber:
    case ProductEnum.Decennial:
      return `cover-activity`
    case ProductEnum.DAndO:
      return `d_and_o`
    default:
      return undefined
  }
}

const Main = () => {
  const { id } = useParams()
  const { quotation, isUpdating } = useQuotation()
  const { proceed } = useOnboardingWorkflow()

  const { data } = useGetQuotationCrmDealIdQuery(
    {
      id: quotation.id,
    },
    {
      refetchInterval: (data) => (data?.quotation?.hubspotDealId == null ? 1000 : false),
      enabled: quotation.hubspotDealId == null,
    }
  )

  const hubspotId = quotation.hubspotDealId ?? data?.quotation?.hubspotDealId ?? undefined

  const heyflowAttrs: {
    ID?: string
    params: {
      hubspotDealId?: string
      env: string
    }
  } = useMemo(() => {
    return {
      ID: getHeyflowID(quotation.details?.products?.[0] ?? undefined),
      params: {
        hubspotDealId: hubspotId,
        env: import.meta.env.VITE_ENV,
      },
    }
  }, [quotation, hubspotId])

  const onSubmitEvent = (event: Event) => {
    event.preventDefault()

    track({
      event: 'submitted_heyflow',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    window.addEventListener('heyflow-submit', onSubmitEvent)

    return () => {
      window.removeEventListener('heyflow-submit', onSubmitEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    track({
      event: 'visited_heyflow',
      quotation_id: id,
    })
  }, [id])

  return !heyflowAttrs?.ID || !heyflowAttrs.params.hubspotDealId || isUpdating ? (
    <div className="absolute inset-0 flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <heyflow-wrapper
        flow-id={heyflowAttrs.ID}
        url-parameters={`hubspot_deal_id=${heyflowAttrs.params.hubspotDealId}&env=${heyflowAttrs.params.env}`}
        style-config='{"position": "absolute", "inset": "0", "width": "100%", "height": "100%"}'
      />
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import React from 'react'
import { twMerge } from 'tailwind-merge'

import CoversContainer, {
  CoversContainerExpanded,
} from '../../components/quotation/pricing/CoversContainer'
import { useQuotation } from '../context'

import { OnboardingTitle } from './OnboardingTitle'
import { PricingRCPCoverOptions } from './PricingRCPCoverOptions'

type Props = React.PropsWithChildren & {
  className?: string
  extended?: boolean
  displayOnlyCover?: boolean
}

export const PricingRCPCoversSection = React.forwardRef<HTMLElement, Props>(
  ({ className, extended, displayOnlyCover }, ref) => {
    const { quotation } = useQuotation()

    const covers = quotation?.covers?.publicLiability
    const details = quotation?.details?.publicLiability

    const Container = extended ? CoversContainerExpanded : CoversContainer

    return (
      <section className={twMerge('w-full', className)} id="covers" ref={ref}>
        <Container
          deductible={covers?.deductible || 0}
          guaranteeAmount={details?.guaranteeAmount || 100_000}
        >
          {displayOnlyCover && <OnboardingTitle.H3>Votre couverture</OnboardingTitle.H3>}
          <PricingRCPCoverOptions displayOnlyCover={displayOnlyCover} />
        </Container>
      </section>
    )
  }
)

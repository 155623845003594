import { client as graphqlClient, initializeClient } from './client'
import type { AuthUser } from './jwt-helper'
import { sendSignInOtp, signInWithOtp, signUp } from './otp'
import { getCurrentUser, logOut, onAuthStateChanged } from './state'
import { getAccessToken } from './tokens'

const exportObject = {
  getCurrentUser,
  onAuthStateChanged,
  logOut,
  getAccessToken,
  signUp,
  sendSignInOtp,
  signInWithOtp,
}

const client = new Proxy(exportObject, {
  get(target, prop, receiver) {
    if (!graphqlClient) {
      // eslint-disable-next-line no-console
      console.error(`Auth client is not initialized.
        Please call initializeClient() before using the auth client.
        Auth request will be ignored.
        `)
    }

    return Reflect.get(target, prop, receiver)
  },
})

export { initializeClient as initializeAuthClient, AuthUser }
export default client

import React from 'react'
import { twMerge } from 'tailwind-merge'

import OptionSwitch from '../../components/quotation/optionSwitch/OptionSwitch'
import { useQuotation } from '../context'

import { PricingEcommAllComputerMaterialDamageOption } from './PricingEcommAllComputerMaterialDamageOption'

export const PricingEcommOptionsSection = React.forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { quotation, updateQuotation } = useQuotation()

    const details = quotation.details.publicLiabilityEcomm
    const premium = quotation.premium?.publicLiabilityEcomm

    return (
      <section className={twMerge('space-y-3', className)} id="options" ref={ref}>
        <h2 className="text-lg font-bold md:text-xl">Protections recommandées</h2>

        <p>
          Voici les protections généralement souscrites par nos clients ayant la même activité que
          vous.
        </p>

        <div className="space-y-3">
          <PricingEcommAllComputerMaterialDamageOption />

          <OptionSwitch
            title="Assistance et Cyber-extorsion"
            recommended
            description="Soyez accompagnés pendant & après une tentative de cyber-extorsion et protégés des conséquences."
            price={premium?.assistanceAndCyberExtortionOption ?? undefined}
            value={details?.assistanceAndCyberExtortionOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: {
                  publicLiabilityEcomm: { assistanceAndCyberExtortionOption: value },
                },
              })
            }}
            infoDialog={{
              title: 'Assistance et Cyber-extorsion',
              content: (
                <>
                  <p>
                    L'option “Assistance et Cyber-extorsion” offre une protection doublée d'une
                    assistance immédiate en cas de tentative d'extorsion liée à une intrusion
                    malveillante dans vos systèmes d'information.
                  </p>
                  <p>
                    Pendant la situation de crise, nous vous proposons un accompagnement d'experts
                    (en sécurité informatique, en communication de crise, avocats, etc.)
                  </p>
                  <p>Après la situation de crise, vous êtes indemnisés:</p>
                  <ul className="list-disc pl-6 text-sm">
                    <li>des rançons</li>
                    <li>de la perte de marge brute engendrée</li>
                    <li>des frais de défense en cas d'enquêtes ou de sanctions administrative</li>
                    <li>
                      des amendes et pénalités lorsque celles-ci sont assurables (eg, non pénales)
                    </li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple</h4>
                  <p className="text-sm">
                    Plusieurs ordinateurs de vos employés sont infectés par un ransomware qui les
                    empêchent d'accéder à leurs outils de travail. L'assureur vous indemnise des
                    rançons et de la perte de revenus associée.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Perte d'exploitation"
            description="Limitez le manque à gagner généré par une intrusion ou une perte de données confidentielles."
            price={premium?.businessInterruptionOption ?? undefined}
            value={details?.businessInterruptionOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: {
                  publicLiabilityEcomm: { businessInterruptionOption: value },
                },
              })
            }}
            infoDialog={{
              title: "Perte d'exploitation",
              content: (
                <>
                  <p>L'option “Perte d'exploitation” vous offre:</p>
                  <ul className="list-disc pl-6 text-sm">
                    <li>
                      En cas de violation de vos données, indemnisation des frais de notification et
                      d'assistance
                    </li>
                    <li>En cas de pertes de données confidentielles, assistance de l'assureur</li>
                    <li>
                      Après le sinistre, indemnisation des pertes d'exploitation et des mesures
                      correctives
                    </li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple</h4>
                  <p className="text-sm">
                    Suite à une attaque cyber, votre système d'informatique est inutilisable et vous
                    empêche de vendre vos produits. L'assureur indemnise les pertes d'exploitation
                    suite à l'interruption de votre activité.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Dommages causés aux tiers"
            description="Protégez-vous des conséquences financières d'une faille de sécurité chez vous ayant pénalisée un fournisseur, un client, etc."
            price={premium?.thirdPartyDamageCoverageOption ?? undefined}
            value={details?.thirdPartyDamageCoverageOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: {
                  publicLiabilityEcomm: { thirdPartyDamageCoverageOption: value },
                },
              })
            }}
            infoDialog={{
              title: 'Dommages causés aux tiers',
              content: (
                <>
                  <p>
                    L'option “Dommages causés aux tiers” vous indemnise des conséquences financières
                    associées à une atteinte à la sécurité et/ou à la confidentialité de données
                    personnelles ou professionnelles chez un tiers (client, fournisseur, etc.).
                  </p>
                  <h4 className="mt-3 font-bold">Exemple</h4>
                  <p className="text-sm">
                    Suite à l'envoi d'un mail, vous avez transmis un virus de manière involontaire à
                    votre client qui a rendu non fonctionnel l'ensemble de son système informatique.
                    Il vous réclame 40 000 € de dommages et intérêts. L'assureur prend en charge ces
                    frais.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Défaillances des fournisseurs"
            description="Protégez-vous des interruptions de services de vos fournisseurs."
            price={premium?.suppliersDeficiencyOption ?? undefined}
            value={details?.suppliersDeficiencyOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: {
                  publicLiabilityEcomm: { suppliersDeficiencyOption: value },
                },
              })
            }}
            infoDialog={{
              title: 'Défaillances des fournisseurs',
              content: (
                <>
                  <p>
                    L'option “Défaillances des fournisseurs” vous indemnise en cas d'interruption de
                    service involontaire de vos fournisseurs à la fois:
                  </p>
                  <ul className="list-disc pl-6 text-sm">
                    <li>pour les pertes d'exploitation engendrées par l'interruption de service</li>
                    <li>
                      pour les mesures correctives ultérieures que vous devez prendre pour limiter
                      votre exposition{' '}
                    </li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple</h4>
                  <p className="text-sm">
                    L'hébergeur de votre site internet interrompt son service suite à un
                    dysfonctionnement et vos clients ne peuvent plus accéder à votre site internet
                    pour acheter vos produits. L'impact financier est est de 10 000€. L'assureur
                    prend en charge ces pertes.
                  </p>
                </>
              ),
            }}
          />

          <OptionSwitch
            title="Fraude cyber et téléphonique"
            description="Protégez-vous des conséquences d'un usage malveillant de vos systèmes de téléphonie, cloud, etc..."
            price={premium?.cyberAndTelephoneFraudOption ?? undefined}
            value={details?.cyberAndTelephoneFraudOption ?? undefined}
            onChange={(value) => {
              updateQuotation({
                details: {
                  publicLiabilityEcomm: { cyberAndTelephoneFraudOption: value },
                },
              })
            }}
            infoDialog={{
              title: 'Fraude cyber & téléphonique',
              content: (
                <>
                  <p className="mb-2">
                    L'option Fraude cyber & téléphonique vous offre une prise en charge, suite à une
                    intrusion de vos systèmes d'information et/ou une utilisation frauduleuse de
                    votre système de téléphonie / cloud / ou point d'accès à l'électricité :
                  </p>
                  <ul className="list-disc pl-6 text-sm">
                    <li>préjudices financiers directs</li>
                    <li>préjudices financiers indirects</li>
                  </ul>
                  <h4 className="mt-3 font-bold">Exemple</h4>
                  <p className="text-sm">
                    Vous recevez une facture de votre opérateur de téléphonie avec un montant 10x
                    supérieur à la normale. Après analyse, vous identifiez un acte malveillant ayant
                    permis à un acteur d'utiliser votre système de téléphonie pour appeler des
                    numéros surtaxés. L'assureur prend en charge l'augmentation de la facture liée à
                    l'intrusion.
                  </p>
                </>
              ),
            }}
          />
        </div>
      </section>
    )
  }
)

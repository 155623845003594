import { Dialog, Transition } from '@headlessui/react'
import React from 'react'

import { Button, Card, CardContent } from '@olino/design-system'

type ModalProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  footer?: React.ReactNode
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  title,
  isOpen,
  onClose,
  footer,
  children,
}) => {
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => onClose()}>
        {/* Backdrop */}
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-info-200 bg-opacity-25 backdrop-blur" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-xl">
                <Card>
                  <CardContent className="px-11 py-6">
                    <Dialog.Title as="h3" className="mb-4 text-2xl font-bold text-primary-900">
                      {title}
                    </Dialog.Title>

                    {children}

                    {!footer && (
                      <div className="mt-4">
                        <Button className="mx-auto" size="sm" onClick={() => onClose()}>
                          J'ai compris !
                        </Button>
                      </div>
                    )}

                    {footer && <div className="mt-4">{footer}</div>}
                  </CardContent>
                </Card>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

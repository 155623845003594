import { mergeDeepRight } from 'ramda'
import React, { useContext, createContext, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { z } from 'zod'

import {
  type GetQuotationQuery,
  type MyQuotationCoversFragment,
  type MyQuotationDetailsFragment,
  type MyQuotationFragment,
  type MyQuotationPremiumFragment,
  useFillQuotationMutation,
  useGetQuotationQuery,
} from '../generated/graphql'

import { Loader } from '@olino/design-system'

export type Quotation = MyQuotationFragment &
  MyQuotationDetailsFragment &
  MyQuotationCoversFragment &
  MyQuotationPremiumFragment

// type QuotationDetails = MyQuotationDetailsFragment['details']
interface OnboardingContextInterface {
  quotation: Quotation
  isFetching: boolean

  // Update the quotation data, returns the next step path, undefined if done
  updateQuotation: (newData: { details: Quotation['details'] }) => Quotation
  isUpdating: boolean
}
const Context = createContext({} as OnboardingContextInterface)

const mergeQuotationDetails = (old: Quotation['details'], _new: Quotation['details']) =>
  mergeDeepRight(old, _new)

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient()

  const location = useLocation()
  const navigate = useNavigate()

  const params = useParams()

  const quotationId = useMemo(() => {
    const validation = z.string().uuid().safeParse(params.id).success
    if (!validation) navigate('/onboarding/not-found')

    return params.id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const [debounceTimeout, setDebounceTimeout] = React.useState<NodeJS.Timeout | undefined>(
    undefined
  )
  const [isDebouncingUpdate, setIsDebouncingUpdate] = React.useState(false)

  const { data, isFetching } = useGetQuotationQuery(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: quotationId!,
    },
    {
      enabled: !!quotationId,
      onSettled: (data) => {
        if (data?.quotation?.id) localStorage.setItem('lastQuotationId', data.quotation.id)
        else {
          localStorage.removeItem('lastQuotationId')
          navigate('/onboarding/not-found')
        }
      },
    }
  )

  const [previousPage, setPreviousPage] = useState<string | undefined>(undefined)
  const [previousQuotation, setPreviousQuotation] = useState<Quotation | undefined>(undefined)

  const quotation = data?.quotation ?? undefined

  const { mutateAsync: updateQuotation, isLoading: isUpdatingMutation } = useFillQuotationMutation({
    onError: (err, vars) => {
      if (previousQuotation) {
        queryClient.setQueryData<GetQuotationQuery>(useGetQuotationQuery.getKey({ id: vars.id }), {
          quotation: previousQuotation,
        })
        setPreviousQuotation(undefined)
      }
      if (previousPage) navigate(previousPage, { replace: true })
      toast.error('Une erreur est survenue, veuillez réessayer')
      console.error(`Failed to update quotation, going back to ${previousPage}`)
    },
    onSuccess: (res, vars) => {
      queryClient.setQueryData<GetQuotationQuery>(useGetQuotationQuery.getKey({ id: vars.id }), {
        quotation: res.fillQuotation.quotation,
      })
    },
    onSettled: () => {
      setIsDebouncingUpdate(false)
      setPreviousPage(undefined)
    },
  })

  if (isFetching || !quotation)
    return (
      <div className="grid min-h-screen place-items-center items-center bg-info-50">
        <Loader size="big" />
      </div>
    )

  const update = (newData: { details?: Quotation['details'] }): Quotation => {
    if (!quotation) throw new Error('Quotation is undefined')

    if (!previousPage) {
      setPreviousPage(location.pathname)
    }

    if (!isDebouncingUpdate) {
      setPreviousQuotation(quotation)
    }

    setIsDebouncingUpdate(true)

    const mergedDetails = newData.details
      ? mergeQuotationDetails(quotation.details, newData.details)
      : quotation.details

    if (import.meta.env.DEV) {
      /* eslint-disable no-console */
      console.group('%c Updating quotation details', 'color: black; font-weight: bold;')
      console.log(`➡️ %cprev data%o`, `color: gray; font-weight: bold;`, quotation)
      console.log(`➡️ %cnew data%o`, `color: green; font-weight: bold;`, mergedDetails)
      console.groupEnd()
      /* eslint-enable no-console */
    }

    const queryKey = useGetQuotationQuery.getKey({ id: quotation.id })
    const tempQuotation = {
      ...quotation,
      details: mergedDetails,
    }

    // Optimistically update to the new value
    queryClient.setQueryData<GetQuotationQuery>(queryKey, {
      quotation: tempQuotation,
    })

    if (debounceTimeout) clearTimeout(debounceTimeout)

    const timeout = setTimeout(() => {
      updateQuotation({
        id: quotation.id,
        details: mergedDetails,
      })
    }, 500)

    setDebounceTimeout(timeout)

    return tempQuotation
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context: OnboardingContextInterface = {
    quotation,
    isFetching,
    updateQuotation: update,
    isUpdating: isDebouncingUpdate || isUpdatingMutation,
  }

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useQuotation = () => useContext(Context)

export { Provider as OnboardingProvider }

import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { useQuotation } from '../context'
import {
  type GetQuotationQuery,
  useApplyQuotationPromoCodeMutation,
  useGetQuotationQuery,
  useRemoveQuotationPromoCodeMutation,
  PromoCodeError,
} from '../../generated/graphql'

import { Button, FormError, Input } from '@olino/design-system'

const formSchema = z.object({
  code: z
    .string({ required_error: 'Le code promo est requis' })
    .nonempty({ message: 'Le code promo est requis' }),
})
const AddPromoCode = () => {
  const queryClient = useQueryClient()
  const { quotation } = useQuotation()
  const { promoCode } = quotation

  const { handleSubmit, formState, setError, reset, register } = useForm<
    z.infer<typeof formSchema>
  >({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      code: promoCode?.code || '',
    },
  })

  // If the promo code changes from outside this component, update the value
  useEffect(() => {
    reset({ code: promoCode?.code || '' })
  }, [promoCode?.code, reset])

  const { mutateAsync: addPromoCodeMutation } = useApplyQuotationPromoCodeMutation({
    onSuccess: (data) => {
      if (
        data.applyQuotationPromoCode.__typename === 'QuotationId' &&
        data.applyQuotationPromoCode.quotation
      ) {
        queryClient.setQueryData<GetQuotationQuery>(
          useGetQuotationQuery.getKey({ id: quotation.id }),
          { quotation: data.applyQuotationPromoCode.quotation }
        )
        toast.success('Code promo appliqué')
      }
    },
  })

  const onSubmit = handleSubmit(async ({ code }) => {
    const res = await addPromoCodeMutation({ id: quotation.id, promoCode: code })

    if (res.applyQuotationPromoCode.__typename === 'ApplyQuotationPromoCodeFailure') {
      const { error } = res.applyQuotationPromoCode

      switch (error) {
        case PromoCodeError.Expired:
          setError('code', { message: 'Ce code promo a expiré' })
          break

        case PromoCodeError.ConditionsNotFulfilled:
          setError('code', { message: 'Ce code promo ne peut pas être appliqué à ce devis' })
          break

        case PromoCodeError.NotValid:
          setError('code', { message: "Ce code promo n'est pas valide" })
          break

        default:
          setError('code', { message: 'Une erreur est survenue' })
          break
      }
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-row gap-2">
        <Input
          className="flex-grow"
          id="promo-code-input"
          disabled={formState.isSubmitting}
          {...register('code')}
        />
        <Button
          variant="outlined"
          type="submit"
          size="sm"
          disabled={formState.isSubmitting}
          loading={formState.isSubmitting}
        >
          Appliquer
        </Button>
      </div>
      <FormError error={formState.errors.code} />
    </form>
  )
}

const PromoCode = () => {
  const { quotation } = useQuotation()
  const queryClient = useQueryClient()

  const { promoCode } = quotation

  const { mutateAsync: removePromoCodeMutation, isLoading: isRemoving } =
    useRemoveQuotationPromoCodeMutation({
      onSuccess: (data) => {
        if (data.removeQuotationPromoCode.quotation)
          queryClient.setQueryData<GetQuotationQuery>(
            useGetQuotationQuery.getKey({ id: quotation.id }),
            { quotation: data.removeQuotationPromoCode.quotation }
          )

        toast.success('Code promo retiré')
      },
    })
  const removePromoCode = () => removePromoCodeMutation({ id: quotation.id })

  return (
    <div className="mt-0.5 flex items-center rounded-lg border border-success-500 bg-white px-4 py-2">
      <FontAwesomeIcon icon={faTag} className="mr-2 text-success-500" />
      <div className="font-medium">{promoCode?.code}</div>

      <div className="spacer flex-1" />

      <Button
        variant="text"
        onClick={() => removePromoCode()}
        loading={isRemoving}
        disabled={isRemoving}
        size="sm"
      >
        Retirer
      </Button>
    </div>
  )
}

export const PromoCodeButton = () => {
  const { quotation } = useQuotation()
  const { promoCode } = quotation

  const [showInput, setShowInput] = useState(promoCode == null)

  if (showInput)
    return (
      <Button
        variant="link"
        className="w-fit text-sm font-light"
        onClick={() => setShowInput(false)}
      >
        Ajouter un code promo
      </Button>
    )

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="promo-code-input" className="text-sm">
        Code promo
      </label>

      {promoCode ? <PromoCode /> : <AddPromoCode />}
    </div>
  )
}

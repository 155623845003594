import { twMerge } from 'tailwind-merge'

import { PaymentFrequency } from '../../generated/graphql'
import formatPrice from '../../utils/formatPrice'
import { useQuotation } from '../context'
import cn from '../../utils/cn'

import { Loader } from '@olino/design-system'

type FormattedPriceProps = {
  size: 'sm' | 'lg'
  originalAmount?: number | null
  discountedAmount?: number | null
  hideLoader?: boolean
}

export const FormattedPrice = ({
  size,
  originalAmount,
  discountedAmount,
  hideLoader,
}: FormattedPriceProps) => {
  const { quotation, isUpdating } = useQuotation()

  const paymentFrequency = quotation.details?.paymentFrequency

  return (
    <div
      className={twMerge(
        'relative text-primary-950',
        size === 'sm' ? 'text-xl md:text-2xl' : 'text-3xl md:text-4xl'
      )}
    >
      {!hideLoader && (
        <Loader
          className={twMerge('absolute inset-0', isUpdating ? 'visible' : 'invisible')}
          size={size === 'sm' ? 'small' : 'big'}
        />
      )}

      <div className={cn(!hideLoader && (isUpdating ? 'invisible' : 'visible'))}>
        {!(discountedAmount == null) && (
          <span className="font-bold">
            {formatPrice(
              paymentFrequency === PaymentFrequency.Monthly
                ? discountedAmount / 12
                : discountedAmount
            )}
          </span>
        )}

        <span
          className={twMerge(
            !(discountedAmount == null)
              ? 'text-sm font-medium text-grey-400 line-through'
              : 'font-bold'
          )}
        >
          {formatPrice(
            paymentFrequency === PaymentFrequency.Monthly
              ? (originalAmount ?? 0) / 12
              : originalAmount ?? 0
          )}
        </span>

        <span className="text-xs">
          {paymentFrequency === PaymentFrequency.Monthly ? '/mois' : '/an'}
        </span>
      </div>
    </div>
  )
}

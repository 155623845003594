import { faBriefcase, faCheck, faChevronRight, faEllipsis } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  type Activity,
  useGetActivitiesQuery,
  useGetActivityCategoriesQuery,
  useGetActivitySectorsQuery,
  ProductEnum,
} from '../../../generated/graphql'
import { useQuotation } from '../../../onboarding/context'

import { Badge, Loader } from '@olino/design-system'

export type ActivityStep = 'sector' | 'category' | 'activity'

type ActivityLike = {
  id: number
  label: string
}
export interface ActivitySelectProps {
  className?: string
  onChange?: (activity?: ActivityLike) => void
  activity?: Activity
}

const ListContainer = ({
  items,
  onClick,
  step,
  value,
}: {
  items: Array<ActivityLike>
  onClick: (item: ActivityLike) => void
  step: ActivityStep
  value?: number
}) => (
  <div>
    <p className="mb-3 text-sm font-medium text-primary-900">
      {
        {
          sector: 'Sélectionnez le secteur qui correspond à votre activité :',
          category: 'Sélectionnez la catégorie qui correspond à votre activité :',
          activity: 'Sélectionnez votre activité :',
        }[step]
      }
    </p>

    {items.length > 0 ? (
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <button
              type="button"
              className={twMerge(
                'group flex w-full items-center rounded-full px-2 hover:bg-grey-50',
                item.id === value &&
                  step === 'activity' &&
                  'border-2 border-primary-600 bg-primary-200/40'
              )}
              onClick={() => onClick(item)}
            >
              <span
                className={twMerge(
                  'my-1.5 mr-2 flex rounded-full p-2',
                  step === 'sector' && 'bg-warning-400/20',
                  step === 'category' && 'bg-info-400/20',
                  step === 'activity' && 'bg-primary-200/30'
                )}
              >
                <FontAwesomeIcon icon={faBriefcase} className="text-sm" />
              </span>

              <p className="whitespace-pre-wrap py-1.5 text-left text-sm font-semibold leading-tight">
                {item.label}
              </p>

              {step !== 'activity' && (
                <span className="invisible my-1 ml-auto flex rounded-full p-2 group-hover:visible">
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              )}

              {item.id === value && (
                <span className="my-1 ml-auto flex rounded-full p-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <div className="flex justify-center px-10 py-20">
        <Loader className="py-10" />
      </div>
    )}
  </div>
)

const ActivitySelect: React.FC<ActivitySelectProps> = ({
  className,
  onChange: setActivity,
  activity,
}) => {
  const [sector, setSector] = useState<ActivityLike | undefined>(undefined)
  const [category, setCategory] = useState<ActivityLike | undefined>(undefined)
  const { quotation } = useQuotation()

  const step = useMemo(() => {
    if (!sector) return 'sector'
    if (!category) return 'category'
    return 'activity'
  }, [sector, category])

  const filter = useMemo(() => {
    if (quotation.details.products?.includes(ProductEnum.PublicLiabilityEcomm)) {
      return { publicLiabilityEcommOk: { _eq: true } }
    }

    if (quotation.details.products?.includes(ProductEnum.MultiRisk)) {
      return { multiRiskOk: { _eq: true } }
    }

    return { publicLiabilityOk: { _eq: true } }
  }, [quotation.details.products])

  const activitySectors = useGetActivitySectorsQuery({ filter })

  const activityCategories = useGetActivityCategoriesQuery(
    {
      sectorId: sector?.id ?? -1,
      filter,
    },
    { enabled: !!sector }
  )

  const activities = useGetActivitiesQuery(
    {
      categoryId: category?.id ?? -1,
      filter,
    },
    { enabled: !!category }
  )

  const onActivityChange = (activity: ActivityLike) => {
    setActivity?.(activity)
  }

  useEffect(() => {
    if (activity) {
      setActivity?.(activity ?? undefined)
      setSector(activity?.category?.sector)
      setCategory(activity?.category)
    }
  }, [setActivity, activity])

  return (
    <div className={twMerge('max-w-full', className)}>
      {sector && (
        <div className="mb-4 flex items-center justify-start gap-1.5">
          <button
            type="button"
            onClick={() => {
              setSector(undefined)
              setCategory(undefined)
              setActivity?.(undefined)
            }}
            disabled={!sector}
            className="flex-shrink-0"
          >
            <Badge className="bg-transparent text-xs hover:bg-grey-100">
              <FontAwesomeIcon icon={faEllipsis} className="align-sub" />
            </Badge>
          </button>
          <FontAwesomeIcon icon={faChevronRight} className="flex-shrink-0 text-grey-400" />

          <button
            type="button"
            onClick={() => {
              setCategory(undefined)
              setActivity?.(undefined)
            }}
            disabled={!category}
            className="inline-flex min-w-0 flex-shrink overflow-hidden transition-all duration-300 ease-in-out hover:flex-shrink-0"
          >
            <Badge
              className={twMerge(
                'min-w-0 max-w-full flex-shrink',
                category && 'bg-transparent hover:bg-grey-100'
              )}
            >
              <p className="hidden truncate text-xs md:block">{sector?.label}</p>
              <p className="truncate text-xs md:hidden">catégories</p>
            </Badge>
          </button>

          {category && (
            <>
              <FontAwesomeIcon icon={faChevronRight} className="flex-shrink-0 text-grey-400" />

              <Badge className="min-w-0 flex-shrink transition-all duration-300 ease-in-out hover:flex-shrink-0">
                <p className="hidden truncate text-xs md:block">{category?.label}</p>
                <p className="truncate text-xs md:hidden">activités</p>
              </Badge>
            </>
          )}
        </div>
      )}

      {step === 'sector' && (
        <ListContainer
          step={step}
          items={activitySectors.data?.sectors ?? []}
          onClick={(activity) => {
            setSector(activity)
          }}
        />
      )}

      {step === 'category' && (
        <ListContainer
          step={step}
          items={activityCategories.data?.categories ?? []}
          onClick={(activity) => {
            setCategory(activity)
          }}
        />
      )}

      {step === 'activity' && (
        <ListContainer
          step={step}
          items={activities.data?.activities ?? []}
          onClick={(activity) => {
            onActivityChange(activity)
          }}
          value={activity?.id}
        />
      )}
    </div>
  )
}

export default ActivitySelect

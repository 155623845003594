import React from 'react'
import { twMerge } from 'tailwind-merge'

import CoversContainer, {
  CoversContainerExpanded,
} from '../../components/quotation/pricing/CoversContainer'
import { useQuotation } from '../context'

import { OnboardingTitle } from './OnboardingTitle'
import { PricingMRPCoversOptions } from './PricingMRPCoversOptions'

type Props = React.PropsWithChildren & {
  className?: string
  extended?: boolean
  displayOnlyCover?: boolean
}

export const PricingMRPCoversSection = React.forwardRef<HTMLElement, Props>(
  ({ className, extended, displayOnlyCover }, ref) => {
    const { quotation } = useQuotation()

    const details = quotation.details.multiRisk

    const Container = extended ? CoversContainerExpanded : CoversContainer

    return (
      <section className={twMerge('w-full', className)} id="covers" ref={ref}>
        <Container deductible={details?.deductibleAmount || 0}>
          {displayOnlyCover && <OnboardingTitle.H3>Votre couverture</OnboardingTitle.H3>}

          <PricingMRPCoversOptions displayOnlyCover={displayOnlyCover} />
        </Container>
      </section>
    )
  }
)

import { RadioGroup } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { useQuotation } from '../context'
import { MultiRiskPackage } from '../../generated/graphql'

import { PricingMRPPackageCard } from './PricingMRPPackageCard'

const packages: Record<MultiRiskPackage, { name: string; description: JSX.Element }> = {
  [MultiRiskPackage.Basic]: {
    name: 'Basic',
    description: (
      <p>
        La formule <b>Basic</b> vous assure à hauteur de <b>30%</b> de la valeur de votre contenu et{' '}
        <b>30%</b> de votre chiffre d'affaires pour vos pertes.
      </p>
    ),
  },
  [MultiRiskPackage.Comfort]: {
    name: 'Confort',
    description: (
      <p>
        La formule <b>Confort</b> vous assure à hauteur de <b>50%</b> de la valeur de votre contenu
        et <b>50%</b> de votre chiffre d'affaires pour vos pertes.
      </p>
    ),
  },
  [MultiRiskPackage.Premium]: {
    name: 'Premium',
    description: (
      <p>
        La formule <b>Basic</b> vous assure à hauteur de <b>80%</b> de la valeur de votre contenu et{' '}
        <b>80%</b> de votre chiffre d'affaires pour vos pertes.
      </p>
    ),
  },
}

export const PricingMRPPackageSection = React.forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { quotation, updateQuotation } = useQuotation()

    const details = quotation.details.multiRisk

    return (
      <section className={twMerge(className)} id="package" ref={ref}>
        <RadioGroup
          value={details?.packageOption ?? undefined}
          onChange={(value: MultiRiskPackage) =>
            updateQuotation({
              details: {
                multiRisk: {
                  packageOption: value,
                },
              },
            })
          }
        >
          <div className="space-y-3">
            <h2 className="text-lg font-bold md:text-xl">
              Votre formule pour modifier votre protection
            </h2>
            {[
              Object.values(MultiRiskPackage).map((value) => (
                <RadioGroup.Option value={value} key={value}>
                  {({ checked }) => (
                    <PricingMRPPackageCard
                      key={value}
                      title={packages[value].name}
                      description={packages[value].description}
                      checked={checked}
                    />
                  )}
                </RadioGroup.Option>
              )),
            ]}
          </div>
        </RadioGroup>
      </section>
    )
  }
)

import { Dialog, Switch, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'

import { OnboardingTitle } from '../../../onboarding/components/OnboardingTitle'
import { FormattedPrice } from '../../../onboarding/components/FormattedPrice'

import { Badge, Button, Card, CardContent } from '@olino/design-system'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionSwitchProps {
  title: string
  description: string
  price?: number
  disabled?: boolean
  onChange?: (value: boolean) => void
  value?: boolean
  recommended?: boolean
  infoDialog?: {
    title: string
    content: React.ReactNode
  }
}

const OptionSwitch: React.FC<OptionSwitchProps> = ({
  title,
  description,
  price,
  value,
  onChange,
  recommended,
  infoDialog,
  disabled = price == null,
}) => {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)

  return (
    <>
      <Switch
        checked={value}
        onChange={(value: boolean) => onChange?.(value)}
        as="div"
        className={twMerge(disabled && 'pointer-events-none opacity-50')}
      >
        <Card
          className={twMerge(
            'cursor-pointer transition hover:border-primary-900',
            value && 'border-primary-900 shadow-clone-xl'
          )}
        >
          <CardContent className="flex items-center">
            <div
              className={twMerge(
                'mr-3 flex h-7 w-7 items-center justify-center rounded-full border border-grey-300 sm:mr-5'
              )}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={twMerge(
                  'h-7 w-7 text-primary-600',
                  'transition-transform duration-300 ease-in-out',
                  value ? 'rotate-0 scale-100' : 'rotate-90 scale-0'
                )}
              />
            </div>

            <div className="flex flex-col gap-3 md:flex-row md:items-center">
              <div className="flex flex-1 flex-col gap-1">
                <div className="flex items-center gap-2">
                  <OnboardingTitle.H3Pricing>{title}</OnboardingTitle.H3Pricing>

                  {infoDialog && (
                    <button
                      type="button"
                      className="group flex items-center justify-center"
                      onClick={(e) => {
                        e.preventDefault()
                        setInfoDialogOpen(true)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="h-5 w-5 text-grey-400 transition-colors group-hover:text-grey-200"
                      />
                    </button>
                  )}

                  <div className="hidden md:block">
                    {price != null && recommended && <Badge size="small">Recommandé</Badge>}
                  </div>
                </div>

                <div className="md:hidden">
                  {price != null && recommended && <Badge size="small">Recommandé</Badge>}
                </div>

                <p className="text-sm">{price == null ? 'Indisponible' : description}</p>
              </div>

              {price ? <FormattedPrice size="sm" originalAmount={price} hideLoader /> : null}
            </div>
          </CardContent>
        </Card>
      </Switch>

      {infoDialog && (
        <Transition appear show={infoDialogOpen} as={React.Fragment}>
          <Dialog as="div" className="relative z-20" onClose={() => setInfoDialogOpen(false)}>
            {/* Backdrop */}
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-info-200 bg-opacity-25 backdrop-blur" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="max-w-xl">
                    <Card>
                      <CardContent className="px-11 py-6">
                        <Dialog.Title as="h3" className="mb-2 text-2xl font-bold text-primary-900">
                          {infoDialog.title}
                        </Dialog.Title>

                        {infoDialog.content}

                        <div className="mt-4">
                          <Button
                            className="mx-auto"
                            size="sm"
                            onClick={() => setInfoDialogOpen(false)}
                          >
                            J'ai compris !
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  )
}

export default OptionSwitch

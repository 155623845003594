import { type PartialDeep } from 'type-fest'

// https://developers.intercom.com/installing-intercom/docs/intercom-for-web

type IntercomSettings = {
  app_id: string
  api_base: string
  custom_launcher_selector: string
  alignment: string
  vertical_padding: number
  horizontal_padding: number
  hide_default_launcher: boolean
  session_duration: number
  action_color: string
  background_color: string
}

type IntercomData = {
  email: string
  user_id: string
  created_at: number
  name: string
  phone: string
  last_request_at: number
  unsubscribed_from_emails: boolean
  language_override: string
  utm_campaign: string
  utm_content: string
  utm_medium: string
  utm_source: string
  utm_term: string
  avatar: {
    type: 'avatar'
    image_url: string
  }
  user_hash: string
  company: {
    id: string
    name: string
    created_at: number
    monthly_spend: number
    plan: string
    user_count: number
    size: number
    website: string
    industry: string
  }
  companies: Array<IntercomData['company']>
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any
    intercomSettings: PartialDeep<IntercomSettings & IntercomData>
  }
}

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
export const load = async (
  settings: Partial<IntercomSettings> & Pick<IntercomSettings, 'app_id' | 'api_base'>
) => {
  /* eslint-disable */
  // prettier-ignore
  // @ts-ignore
  (function(){window.intercomSettings = settings})();
  // @ts-ignore
  // prettier-ignore
  (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',settings);}else{const d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${settings.app_id}`;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  /* eslint-enable */
}

export const update = (data: PartialDeep<IntercomSettings & IntercomData> = {}) => {
  if (window && window.Intercom) {
    window.Intercom('update', data)
    window.intercomSettings = { ...window.intercomSettings, ...data }
  }
}

export const show = () => {
  if (window && window.Intercom) window.Intercom('show')
}

export const showNewMessage = (message: string) => {
  if (window && window.Intercom) window.Intercom('showNewMessage', message)
}

export const hide = () => {
  if (window && window.Intercom) window.Intercom('hide')
}

export const shutdown = () => {
  if (window && window.Intercom) window.Intercom('shutdown')
}

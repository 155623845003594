import { useQuotation } from '../context'
import formatPrice from '../../utils/formatPrice'

import { OnboardingTitle } from './OnboardingTitle'

import { Hint, Select } from '@olino/design-system'

const guaranteeAmounts = [100_000, 200_000, 300_000]

export const PricingEcommGuaranteeAmountSlider = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <div className="flex items-center gap-3">
      <OnboardingTitle.H3Pricing>Montant maximal des garanties</OnboardingTitle.H3Pricing>

      <Select
        className="min-w-[180px]"
        options={guaranteeAmounts}
        value={quotation.details.publicLiabilityEcomm?.guaranteeAmount || 200_000}
        getOptionLabel={(value) => formatPrice(value, 0)}
        onChange={(value) =>
          updateQuotation({
            details: {
              publicLiabilityEcomm: {
                guaranteeAmount: value,
              },
            },
          })
        }
      />

      <Hint>
        La <span className="font-semibold">limite de garantie</span> est la somme maximum
        d'indemnisation que l'assurance vous versera en cas de sinistre.
      </Hint>
    </div>
  )
}

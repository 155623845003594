/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react'
import { add, format, isBefore, startOfDay } from 'date-fns'
import { fr } from 'date-fns/locale'
import * as zod from 'zod'
import { DayPicker } from 'react-day-picker'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { TipsCard } from '../components/TipsCard'
import { track } from '../../utils/analytics'

import { FormError, Hint } from '@olino/design-system'

const Main = () => {
  const { id } = useParams()
  const { updateQuotation, quotation } = useQuotation()
  const [startDate, setStartDate] = useState<Date>(
    quotation.details.startDate ? new Date(quotation.details.startDate) : new Date()
  )
  const { proceed } = useOnboardingWorkflow()
  const [error, setError] = useState<string | undefined>()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isBefore(startDate, startOfDay(new Date()))) {
      setError("La date doit être supérieure à aujourd'hui")
      return
    }

    track({
      event: 'submitted_start_date',
      quotation_id: id,
    })

    const formattedDate = format(startDate, 'yyyy-MM-dd')

    proceed(() =>
      updateQuotation({
        details: {
          startDate: formattedDate,
        },
      })
    )
  }

  useEffect(() => {
    track({
      event: 'visited_start_date',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>
        A partir de quand souhaitez-vous protéger votre entreprise&nbsp;?
      </OnboardingTitle.H1>

      <form onSubmit={onSubmit} className="w-full space-y-8">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <span className="ml-0.5 text-sm transition-colors">Date d'effet</span>
            <Hint text="la date d'effet est la date à partir de laquelle votre entreprise est effectivement protégée grâce à votre contrat d'assurance (sous réserve que nous ayons bien reçu votre premier versement." />
          </div>

          <div className="flex flex-col items-center justify-center">
            <DayPicker
              mode="single"
              className="m-0 p-0"
              locale={fr}
              defaultMonth={startDate}
              fromDate={new Date()}
              toDate={add(new Date(), { days: 90 })}
              selected={startDate}
              onSelect={(date) => {
                if (!date) return

                const schema = zod
                  .date({ required_error: 'Une date valide est requise' })
                  .min(startOfDay(new Date()), "La date doit être supérieure à aujourd'hui")
                  .max(
                    add(new Date(), { days: 90 }),
                    "La date doit être inférieure à 90 jours après aujourd'hui"
                  )

                const parse = schema.safeParse(date)

                if (!parse.success) {
                  setError(parse.error.issues.at(0)?.message)
                  return
                }

                setError(undefined)

                setStartDate(parse.data)
              }}
            />
            <FormError error={error} />
          </div>
        </div>

        <div className="flex justify-end">
          <OnboardingNextButton />
        </div>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Comment choisir sa date d'effet ?"
      content={[
        "Vous n'avez pas de contrat d'assurance: si vous avez déjà des clients, choisissez la date d'aujourd'hui ; sinon choisissez la veille de la date de début de votre premier contrat.",
        "Vous avez un contrat d'assurance: choisissez la date de résiliation de votre contrat existant pour assurer une couverture sans interruption.",
      ]}
    />
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faBuildingShield,
  faCarBurst,
  faCartShopping,
  faFileContract,
  faFileLock,
  faHeadSideMedical,
  faHouseChimneyHeart,
  faHouseCrack,
  faPersonChalkboard,
  faRoute,
  faScaleBalanced,
  faUmbrella,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons'

import { ProductEnum } from '../generated/graphql'

export type Product = {
  name: string
  icon: IconDefinition
  slug: string
}

export { ProductEnum }

export const PRODUCTS: Record<ProductEnum, Product> = {
  [ProductEnum.PublicLiability]: {
    name: 'Responsabilité Civile Professionnelle',
    icon: faUmbrella,
    slug: 'RCP',
  },
  [ProductEnum.PublicLiabilityEcomm]: {
    name: 'Responsabilité Civile Professionnelle',
    icon: faCartShopping,
    slug: 'RCP',
  },
  [ProductEnum.MultiRisk]: {
    name: 'Multirisque',
    icon: faBuildingShield,
    slug: 'MRP',
  },
  [ProductEnum.Cyber]: {
    name: 'Cybersécurité',
    icon: faFileLock,
    slug: 'CYBER',
  },
  [ProductEnum.Legal]: {
    name: 'Protection juridique',
    icon: faScaleBalanced,
    slug: 'PJ',
  },
  [ProductEnum.KeyPerson]: {
    name: 'Homme clé',
    icon: faPersonChalkboard,
    slug: 'HC',
  },
  [ProductEnum.Decennial]: {
    name: 'RC Décennale',
    icon: faHouseCrack,
    slug: 'RCD',
  },
  [ProductEnum.DAndO]: {
    name: 'RC des mandataires sociaux',
    icon: faUserTie,
    slug: 'RCMS',
  },
  [ProductEnum.Health]: {
    name: 'Mutuelle',
    icon: faHeadSideMedical,
    slug: 'MUT',
  },
  [ProductEnum.Life]: {
    name: 'Prévoyance',
    icon: faHouseChimneyHeart,
    slug: 'PREV',
  },
  [ProductEnum.Auto]: {
    name: 'Auto',
    icon: faCarBurst,
    slug: 'AUTO',
  },
  [ProductEnum.AutoMission]: {
    name: 'Auto mission',
    icon: faRoute,
    slug: 'AM',
  },
  [ProductEnum.Other]: {
    name: 'Autre',
    icon: faFileContract,
    slug: 'AUTRE',
  },
}

/* eslint-disable react/destructuring-assignment */
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useQuotation } from '../context'

import { Button, cn } from '@olino/design-system'

export const OnboardingNextButton: React.FC<React.ComponentProps<typeof Button>> = (props) => {
  const { disabled, children } = props
  const { isUpdating } = useQuotation()

  return (
    <Button
      type="submit"
      disabled={isUpdating || disabled}
      loading={isUpdating}
      className={cn('flex-shrink-0', props.className)}
      {...props}
    >
      {children || (
        <>
          Suivant
          <FontAwesomeIcon icon={faArrowRight} />
        </>
      )}
    </Button>
  )
}

import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line import/no-duplicates
import { format, isValid, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import './DatePicker.css'
import { twMerge } from 'tailwind-merge'
// eslint-disable-next-line import/no-duplicates
import { fr } from 'date-fns/locale'
import * as Popover from '@radix-ui/react-popover'

import { noop } from '../utils'
import { Input, type InputProps } from '../input/Input'
import { Card } from '../card/Card'

interface Props {
  label?: InputProps['label']
  value?: Date
  onChange?: (date?: Date) => void
  min?: Date
  max?: Date
  error?: boolean
  className?: string
}

const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/

export const DatePicker: React.FC<Props> = ({
  value,
  onChange: _onChange = noop,
  label,
  min,
  max,
  error,
  className,
}) => {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [valid, setValid] = useState<boolean | undefined>(isValid(value) ? true : undefined)

  // It's important to put the modifiers in useMemo, as not doing it triggers re-renders, and an infinite loop

  const [tmpValue, setTmpValue] = useState<string | undefined>(
    value ? format(value, 'dd/MM/yyyy') : undefined
  )

  useEffect(() => {
    if (value) {
      setTmpValue(format(value, 'dd/MM/yyyy'))
      setValid(isValid(value))
    }
  }, [value])

  const onChange = (date?: Date) => {
    setTmpValue(date ? format(date, 'dd/MM/yyyy') : '')
    _onChange(date)
  }

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isDateValid = dateRegex.test(e.target.value)
    setValid(isDateValid)

    if (isDateValid) {
      const date = parse(e.target.value, 'dd/MM/yyyy', new Date())
      onChange(date)
    } else {
      onChange()
      setTmpValue(e.target.value)
    }
  }

  return (
    <Popover.Root open={calendarOpen} onOpenChange={setCalendarOpen}>
      <Popover.Anchor className={className}>
        <Input
          value={tmpValue}
          label={label}
          autoComplete="off"
          placeholder="jj/mm/aaaa"
          error={!valid || error}
          onFocus={() => setCalendarOpen(true)}
          className="w-full min-w-[165px]"
          onChange={handleTextInputChange}
          endAdornment={
            <Popover.Trigger asChild>
              <button
                type="button"
                className="hover:bg-gray-100 -m-1 mr-0 h-9 w-9 rounded-full p-1"
                // onClick={() => setCalendarOpen(true)}
              >
                <FontAwesomeIcon icon={faCalendarDays} className="text-gray-400" />
              </button>
            </Popover.Trigger>
          }
        />
      </Popover.Anchor>
      <Popover.Content asChild>
        <Card
          className={twMerge(
            'z-50 shadow-lg transition-opacity',
            // literally copied over from https://github.com/shadcn/ui/blob/38fb9693d0fdad70d9b6181a71067d883bd5ec0b/apps/www/registry/default/ui/popover.tsx
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'
          )}
        >
          <DayPicker
            mode="single"
            selected={value}
            onSelect={onChange}
            locale={fr}
            defaultMonth={value}
            fromDate={min}
            toDate={max}
          />
        </Card>
      </Popover.Content>
    </Popover.Root>
  )
}

DatePicker.displayName = 'DatePicker'

/**
 * Generate a URI that includes the current location as a url parameter for auth redirects
 * @param path The path of the auth page
 * @returns
 */
export const generateRedirectUrl = (path: string) => {
  const url = new URL(window.location.href)
  url.searchParams.set('redirect_uri', window.location.pathname)
  url.pathname = path
  return `${url.pathname}${url.search}`
}

import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'

import { FormError, Input } from '@olino/design-system'

export const SummaryLastName = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      label="Prénom :"
      schema={z.object({
        lastName: z
          .string({ required_error: 'Ce champ est requis' })
          .trim()
          .min(1, { message: 'Le prénom est requis' }),
      })}
      value={{ lastName: quotation?.details?.contact?.lastName ?? '' }}
      displayValue={({ lastName }) => lastName}
      onSave={({ lastName }) =>
        updateQuotation({
          details: {
            contact: {
              lastName,
            },
          },
        })
      }
      input={({ control }) => (
        <Controller
          control={control}
          name="lastName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <Input type="text" autoComplete="given-name" value={value} onChange={onChange} />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { type Activity, useGetActivityQuery } from '../../generated/graphql'
import { type OnboardingPage } from '../routes'
import ActivityAutoComplete from '../../components/quotation/activityAutoComplete/ActivityAutoComplete'
import ActivitySelect from '../../components/quotation/activitySelect/ActivitySelect'
import { PRODUCTS } from '../../constants/products'
import { useQuotation } from '../context'
import { track } from '../../utils/analytics'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingButtons } from '../components/OnboardingButtons'

import { Button } from '@olino/design-system'
import { showNewMessage as showNewMessageIntercom } from '@olino/intercom-js'

// List of the activity ids that are covered by the ecommerce public liability
// TODO: Remove this when we have a proper way to handle this :sad:
export const ecommActivitiyWhitelist = [
  213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231,
]

const Main = () => {
  const { id } = useParams()
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()

  const [selectedActivityId, setSelectedActivityId] = useState<number | undefined>(
    quotation.details.publicLiability?.activityIds?.at(0) ||
      quotation.details.publicLiabilityEcomm?.activityId ||
      quotation.details.multiRisk?.activityId ||
      undefined
  )

  const { data: valueData, isFetching: isFetchingValue } = useGetActivityQuery(
    { id: selectedActivityId || -1 },
    {
      enabled: selectedActivityId != null,
    }
  )

  const activity = valueData?.activity as Activity | undefined

  const onActivityChange = (id?: number) => {
    track({
      event: 'activity_selected',
      quotation_id: quotation.id,
      activity_id: id,
      product: quotation.details?.products?.[0],
    })

    setSelectedActivityId(id)
  }

  const onActivityNotFound = () => {
    track({
      event: 'activity_not_found',
      quotation_id: quotation.id,
      product: quotation.details?.products?.[0],
    })

    showNewMessageIntercom(
      `Bonjour, je ne trouve pas mon activité lors de la création d'un devis ${
        quotation.details?.products
          ? PRODUCTS[quotation.details?.products[0]].name.toLowerCase()
          : ''
      }. Pouvez-vous m'aider s'il vous plait ?`
    )
  }

  const onSubmit = () => {
    if (!selectedActivityId) return

    track({
      event: 'submitted_activities',
      quotation_id: id,
    })

    const activityObject = {
      publicLiability: {
        activityIds: [selectedActivityId],
      },
      multiRisk: {
        activityId: selectedActivityId,
      },
      publicLiabilityEcomm: {
        activityId: selectedActivityId,
      },
    }

    //  This was a hack to handle the ecommerce public liability. We don't support it anymore

    // Hey look, it's a hack!
    // "A temporary hack" hihi
    // if (
    //   quotation.details.products?.includes(ProductEnum.PublicLiability) &&
    //   ecommActivitiyWhitelist.includes(selectedActivityId)
    // ) {
    //   proceed(() =>
    //     updateQuotation({
    //       details: { products: [ProductEnum.PublicLiabilityEcomm], ...activityObject },
    //     })
    //   )
    //   return
    // }

    // if (
    //   quotation.details.products?.includes(ProductEnum.PublicLiabilityEcomm) &&
    //   !ecommActivitiyWhitelist.includes(selectedActivityId)
    // ) {
    //   proceed(() =>
    //     updateQuotation({
    //       details: {
    //         products: [ProductEnum.PublicLiability],
    //         ...activityObject,
    //       },
    //     })
    //   )
    //   return
    // }

    proceed(() => updateQuotation({ details: { ...activityObject } }))
  }

  useEffect(() => {
    track({
      event: 'visited_activities',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Quelle est votre activité principale&nbsp;?</OnboardingTitle.H1>

      <p className="mb-4 ml-0.5 text-sm text-grey-600 transition-colors focus-within:text-primary-900">
        Cherchez l'activité qui se rapproche le plus de celle de votre entreprise
      </p>

      <div className="flex flex-col items-center gap-3 md:flex-row">
        <ActivityAutoComplete
          className="w-full"
          onChange={(v) => onActivityChange(v)}
          activity={activity}
          isFetching={isFetchingValue}
          endAdornment={<FontAwesomeIcon icon={faSearch} />}
        />
      </div>

      <div className="mx-auto my-4 text-center font-bold uppercase text-grey-400">ou</div>

      <ActivitySelect
        className="mb-4 px-5"
        onChange={(v) => onActivityChange(v?.id)}
        activity={activity}
      />

      <OnboardingButtons>
        <Button variant="outlined" onClick={() => onActivityNotFound()}>
          Je ne trouve pas mon activité
        </Button>

        <OnboardingNextButton
          className="order-first place-self-end md:order-none"
          disabled={!selectedActivityId}
          onClick={onSubmit}
        />
      </OnboardingButtons>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

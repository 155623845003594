import { faPlaneDeparture, faPlusCircle, faStarShooting } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from 'react-router'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import useMe from '../../utils/useMe'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { track } from '../../utils/analytics'

import { Card, CardContent, CardActions, Button } from '@olino/design-system'

const Main = () => {
  const { id } = useParams()
  const user = useMe()
  const navigate = useNavigate()

  useEffect(() => {
    track({
      event: 'visited_tailored_finalization',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1 className="mb-5">
        Nous revenons vers vous dans les plus&nbsp;brefs délais !
      </OnboardingTitle.H1>

      <div className="mb-5">
        Nous mettons tout en œuvre pour vous proposer l'offre la plus adaptée à vos besoins.
      </div>

      <Card className="sm:pt-4">
        <CardContent>
          <div className="grid max-w-4xl grid-cols-1 space-y-4 text-center text-sm sm:gap-6 md:grid-cols-2 md:flex-row md:space-y-0">
            <div className="flex flex-col items-center gap-3">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-200 text-primary-900">
                <FontAwesomeIcon icon={faStarShooting} className="h-6 w-6" />
              </div>
              <h2 className="font-serif text-xl font-black">Merci !</h2>
              <p>
                Votre demande a bien été transmise à un de nos spécialistes qui vous contactera dès
                que possible.
              </p>
            </div>

            <div className="flex flex-col items-center gap-3">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-200 text-primary-900">
                <FontAwesomeIcon icon={faPlaneDeparture} className="h-6 w-6" />
              </div>
              <h2 className="font-serif text-xl font-black">En attendant...</h2>
              <p>Consulter nos autres offres pour protéger au mieux votre entreprise !</p>
            </div>
          </div>
        </CardContent>

        <CardActions>
          <div className="mt-3 flex flex-col items-center justify-center gap-6 sm:flex-row">
            {user && (
              <Button variant="outlined" onClick={() => navigate('/')}>
                Voir mes contrats
              </Button>
            )}
            <Button onClick={() => navigate('/onboarding')}>
              <FontAwesomeIcon icon={faPlusCircle} />
              Nouveau devis
            </Button>
          </div>
        </CardActions>
      </Card>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import React from 'react'
import { Tooltip as ReactToolTip } from 'react-tooltip'

import { cn } from '../utils'

export const Tooltip: React.FC<React.ComponentProps<typeof ReactToolTip>> = ({
  children,
  className,
  ...rest
}) => (
  <ReactToolTip
    className={cn(
      ['z-[9999] max-w-[300px] border bg-white text-primary-900 opacity-100 shadow-md'],
      className
    )}
    {...rest}
  >
    {children}
  </ReactToolTip>
)
Tooltip.displayName = 'ToolTip'

import { z } from 'zod'
import { Controller } from 'react-hook-form'

import { SummaryEditableProperty } from '../SummaryEditableProperty'
import { useQuotation } from '../../context'

import { FormError, PhoneInput } from '@olino/design-system'

export const SummaryPhone = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <SummaryEditableProperty
      value={{ phone: quotation?.details?.contact?.phone ?? '' }}
      label="Tel :"
      displayValue={({ phone }) => phone}
      schema={z.object({
        phone: PhoneInput.schema,
      })}
      onSave={({ phone }) =>
        updateQuotation({
          details: {
            contact: {
              phone,
            },
          },
        })
      }
      input={({ control }) => (
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <PhoneInput hideLabel value={value} onChange={onChange} />
              <FormError error={error} />
            </div>
          )}
        />
      )}
    />
  )
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { PaymentElement } from '@stripe/react-stripe-js'
import {
  type StripePaymentElementChangeEvent,
  type StripePaymentElementOptions,
} from '@stripe/stripe-js'
import React from 'react'

import { Card, CardContent } from '@olino/design-system'

const CARD_ELEMENT_OPTIONS: StripePaymentElementOptions = {
  defaultValues: {
    billingDetails: {
      address: {
        country: 'France',
      },
    },
  },
  terms: {
    card: 'never',
    sepaDebit: 'never',
  },
}

interface Props {
  onChange?: (e: StripePaymentElementChangeEvent) => unknown
}

export const CheckoutPaymentCards: React.FC<Props> = ({ onChange }) => (
  <Card className="mb-4 w-full max-w-lg">
    <CardContent>
      <p className="mb-1 font-semibold text-primary-900">Vos informations de paiement</p>

      <PaymentElement options={CARD_ELEMENT_OPTIONS} className="w-full p-2" onChange={onChange} />
      {import.meta.env.VITE_ENV !== 'production' && (
        <div className="text-yellow-500 text-xs">
          <a href="https://stripe.com/docs/testing" target="_blank" rel="noreferrer">
            Retrouvez les informations de test en cliquant ici.
          </a>
        </div>
      )}
    </CardContent>
  </Card>
)

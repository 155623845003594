import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import formatPrice from '../../../utils/formatPrice'

const CoverListItem: React.FC<{ label: string; amount?: number | null }> = ({ label, amount }) => {
  if (!amount) return null

  return (
    <li className="flex flex-col gap-1 text-sm md:flex-row md:items-center md:justify-between">
      <div className="flex gap-2">
        <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-primary-600" />
        <p className="font-semibold">{label}</p>
      </div>

      <div className="ml-6 whitespace-nowrap font-light text-grey-500">
        Jusqu'à {formatPrice(amount, 0)}
      </div>
    </li>
  )
}

export default CoverListItem

// eslint-disable-next-line import/no-cycle
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { identify } from '../../utils/analytics'
import { useMeQuery } from '../../generated/graphql'

import { AuthProvider as AuthClientProvider, useAuth } from '@olino/auth-client/react'
import { update as updateIntercom, shutdown as shutdownIntercom } from '@olino/intercom-js'

const AuthWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authUser } = useAuth()
  const queryClient = useQueryClient()

  useMeQuery(
    {},
    {
      onSuccess: (data) => {
        if (data.me?.user)
          updateIntercom({
            email: data.me.user.email,
            name: `${data.me.user.firstName} ${data.me.user.lastName}`,
            user_id: data.me.user.id,
            phone: data.me.user.phone,
            created_at: new Date(data.me.user.createdAt).getTime() / 1000,
          })
      },
      enabled: !!authUser,
    }
  )

  useEffect(() => {
    if (authUser) {
      identify({
        email: authUser.email,
        id: authUser.id,
      })
    } else {
      identify(null)
      shutdownIntercom()
    }

    queryClient.invalidateQueries(useMeQuery.getKey())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const AuthProvider = (props: React.ComponentProps<typeof AuthWrapper>) => (
  <AuthClientProvider
    routes={{
      signIn: '/auth/login',
      afterSignIn: '/',
    }}
  >
    <AuthWrapper {...props} />
  </AuthClientProvider>
)

import { useState, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { type OnboardingPage } from '../routes'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OtpInput } from '../../pages/auth/otp'
import graphQLClient from '../../services/graphql/graphqlClient'
import {
  CheckIfUserEmailExistsDocument,
  type CheckIfUserEmailExistsQuery,
  type CheckIfUserEmailExistsQueryVariables,
} from '../../generated/graphql'
import { useQuotation } from '../context'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { track } from '../../utils/analytics'

import client from '@olino/auth-client/js'

const Main = () => {
  const { id } = useParams()
  const { proceed } = useOnboardingWorkflow()

  const [otp, setOtp] = useState<string | undefined>()
  const [failed, setFailed] = useState(false)
  const navigate = useNavigate()

  const [loggingIn, setLoggingIn] = useState(false)
  const { quotation } = useQuotation()

  const contact = useMemo(() => {
    const contactValidation = z
      .object({
        email: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        phone: z.string(),
        marketingOptIn: z.boolean(),
      })
      .safeParse(quotation.details.contact)

    if (contactValidation.success) return contactValidation.data

    console.warn(contactValidation)
    console.warn('The contact information failed for signIn/signUp. See above validation errors')
    navigate('..')

    return undefined
  }, [navigate, quotation.details.contact])

  // Sends OTP appropriately to the user
  useQuery(
    ['onboarding-authenticate-otp'],
    async () => {
      if (!contact) {
        console.warn('Contact information must be present in order to signIn/signup')
        return ''
      }

      const { checkIfUserEmailExists: emailExists } = await graphQLClient.request<
        CheckIfUserEmailExistsQuery,
        CheckIfUserEmailExistsQueryVariables
      >(CheckIfUserEmailExistsDocument, { email: contact.email })

      if (emailExists) {
        await client.sendSignInOtp(contact.email)
      } else {
        await client.signUp(contact)
      }

      return ''
    },
    { enabled: !!contact }
  )

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     proceed()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoggedIn])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    if (!contact?.email) {
      console.warn('No email in quotation contact')
      return
    }

    if (!otp) {
      console.warn('Trying to submit empty OTP')
      return
    }

    track({
      event: 'submitted_otp',
      quotation_id: id,
    })

    setLoggingIn(true)

    try {
      await client.signInWithOtp(contact?.email, otp)
      proceed()
    } catch (e) {
      console.error(e)
      setFailed(true)
    } finally {
      setLoggingIn(false)
    }
  }

  useEffect(() => {
    track({
      event: 'visited_otp',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Vérifions ensemble votre adresse email</OnboardingTitle.H1>

      <form className="flex flex-col items-stretch gap-8" onSubmit={onSubmit}>
        <p className="leading-relaxed text-grey-500">
          Votre adresse email est rattachée à un compte Olino, vous allez recevoir un mail contenant
          un code à 6 caractères. Saisissez ce code ci-dessous :
        </p>

        <OtpInput onChange={setOtp} failed={failed} />

        {failed && (
          <span className="text-sm">
            Code invalide. Attention, vous n'avez que 3 tentatives par code
          </span>
        )}

        <OnboardingNextButton disabled={!otp} loading={loggingIn} className="w-full">
          Se connecter
        </OnboardingNextButton>
      </form>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

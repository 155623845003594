import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { type OnboardingPage } from '../routes'
import { useQuotation } from '../context'
import { useGetSignatureLinkForQuotationQuery } from '../../generated/graphql'
import { track } from '../../utils/analytics'

import { Button, Loader } from '@olino/design-system'
import { show } from '@olino/intercom-js'

const Main = () => {
  const { id } = useParams()
  const { quotation } = useQuotation()
  const [takesTooLong, setTakesTooLong] = useState(false)
  const [hasRedirected, setHasRedirected] = useState(false)

  const { data } = useGetSignatureLinkForQuotationQuery(
    {
      quotationId: quotation.id,
    },
    {
      refetchInterval: hasRedirected ? false : 2000,
    }
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTakesTooLong(true)
    }, 60 * 1000) // 60 seconds

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (!hasRedirected && data?.getSignatureLinkForQuotation) {
      track({
        event: 'submitted_signature',
        quotation_id: id,
      })
      window.location.href = data.getSignatureLinkForQuotation
      setHasRedirected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    track({
      event: 'visited_signature',
      quotation_id: id,
    })
  }, [id])

  if (takesTooLong) {
    return (
      <>
        <p className="text-center">Votre contrat met plus de temps que prévu à se générer.</p>
        <p className="text-center">
          Pas de panique ! Vous allez recevoir un email dès qu'il sera prêt.
        </p>
        <button
          className="mt-2 text-center font-medium hover:underline"
          onClick={() => show()}
          type="button"
        >
          Dans le cas contraire, cliquez ici pour nous contacter
        </button>

        <Link to="../success" className="mx-auto mt-4">
          <Button className="mx-auto">Continuer</Button>
        </Link>
      </>
    )
  }

  return (
    <>
      <Loader />

      <p>
        Votre contrat est presque prêt à signer. Cela peut prendre quelques instants merci de
        patienter.
      </p>

      {data?.getSignatureLinkForQuotation && (
        <Button variant="link" className="mt-1" asChild>
          <a href={data.getSignatureLinkForQuotation}>
            Votre contrat vous attend. Vous pouvez le signer ici.
          </a>
        </Button>
      )}
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage

import React from 'react'

import CoverListItem from '../../components/quotation/coverListItem/CoverListItem'
import CoverListOption from '../../components/quotation/coverListOption/CoverListOption'
import { useQuotation } from '../context'

type Props = React.PropsWithChildren & {
  displayOnlyCover?: boolean
}

export const PricingMRPCoversOptions = ({ displayOnlyCover }: Props) => {
  const { quotation } = useQuotation()

  const covers = quotation.covers?.multiRisk
  const details = quotation.details.multiRisk
  const premium = quotation.premium?.multiRisk

  return (
    <div className="space-y-3">
      <CoverListItem label="Vol des biens mobiliers" amount={covers?.theftProperty} />
      <CoverListItem
        label="Vandalisme (hors bâtiments)"
        amount={covers?.vandalismExcludingBuildings}
      />
      <CoverListItem label="Incendie" amount={covers?.fire} />
      <CoverListItem label="Catastrophes naturelles" amount={covers?.naturalDisasters} />
      <CoverListItem label="Bris de glaces" amount={covers?.glassBreakage} />
      <CoverListItem label="Bris de machine" amount={covers?.machineryBreakdown} />
      <CoverListItem label="Tous risques informatiques" amount={covers?.allComputerRisks} />
      <CoverListItem label="Dégâts des eaux" amount={covers?.waterDamage} />
      <CoverListItem
        label="Recours des voisins et des tiers"
        amount={covers?.recourseFromNeighborsAndThirdParties}
      />

      {/* Checking if the user selected the option as well as if the option is available, to avoid confusing the user */}
      {(!displayOnlyCover ||
        (details?.operatingLiabilityOption === true &&
          premium?.operatingLiabilityOption != null)) && (
        <CoverListOption
          label="Responsabilité civile d'exploitation"
          option={
            details?.operatingLiabilityOption === true && premium?.operatingLiabilityOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.legalProtectionOption === true && premium?.legalProtectionOption != null)) && (
        <CoverListOption
          label="Protection juridique"
          option={details?.legalProtectionOption === true && premium?.legalProtectionOption != null}
        />
      )}

      {(!displayOnlyCover ||
        (details?.assistanceOption === true && premium?.assistanceOption != null)) && (
        <CoverListOption
          label="Assistance"
          option={details?.assistanceOption === true && premium?.assistanceOption != null}
        />
      )}

      {(!displayOnlyCover ||
        (details?.financialLossStandardOption === true &&
          premium?.financialLossStandardOption != null) ||
        (details?.financialLossAdvantageOption === true &&
          premium?.financialLossAdvantageOption != null)) && (
        <CoverListOption
          label="Pertes financières"
          option={
            (details?.financialLossStandardOption === true &&
              premium?.financialLossStandardOption != null) ||
            (details?.financialLossAdvantageOption === true &&
              premium?.financialLossAdvantageOption != null)
          }
        />
      )}
    </div>
  )
}

import React from 'react'

import CoverListItem from '../../components/quotation/coverListItem/CoverListItem'
import CoverListOption from '../../components/quotation/coverListOption/CoverListOption'
import { useQuotation } from '../context'

type Props = React.PropsWithChildren & {
  displayOnlyCover?: boolean
}

export const PricingEcommCoversOptions = ({ displayOnlyCover }: Props) => {
  const { quotation } = useQuotation()
  const covers = quotation.covers?.publicLiabilityEcomm
  const details = quotation.details.publicLiabilityEcomm
  const premium = quotation.premium?.publicLiabilityEcomm

  return (
    <div className="space-y-3">
      <CoverListItem
        label="Dommages corporels, matériels et immatériels"
        amount={covers?.physicalMaterialAndImmaterialDamagesConsecutiveOrNot}
      />
      <CoverListItem label="Factures impayées" amount={covers?.unpaidInvoices} />
      <CoverListItem label="Perte d'un Homme Clé" amount={covers?.lossOfAkeyPerson} />
      <CoverListItem
        label="Atteinte à la réputation et à l'image de marque"
        amount={covers?.damageToReputationAndBrandImage}
      />
      <CoverListItem
        label="Responsabilité Civile d'Exploitation"
        amount={covers?.operatingLiabilityPhysicalMaterialAndImmaterialDamagesConsecutive}
      />
      <CoverListItem label="Protection juridique" amount={covers?.legalProtection} />
      {/* <CoverListItem label="Vol des biens mobiliers" amount={covers?.theftProperty} />
          <CoverListItem
            label="Vandalisme (hors bâtiments)"
            amount={covers?.vandalismExcludingBuildings}
          />
          <CoverListItem label="Incendie" amount={covers?.fire} />
          <CoverListItem label="Catastrophes naturelles" amount={covers?.naturalDisasters} />
          <CoverListItem label="Bris de glaces" amount={covers?.glassBreakage} />
          <CoverListItem label="Bris de machine" amount={covers?.machineryBreakdown} />
          <CoverListItem label="Tous risques informatiques" amount={covers?.allComputerRisks} />
          <CoverListItem label="Dégâts des eaux" amount={covers?.waterDamage} />
          <CoverListItem
            label="Recours des voisins et des tiers"
            amount={covers?.recourseFromNeighborsAndThirdParties}
          /> */}

      {/* Checking if the user selected the option as well as if the option is available, to avoid confusing the user */}
      {(!displayOnlyCover ||
        (details?.allComputerMaterialDamageOption === true &&
          premium?.allComputerMaterialDamageOption != null)) && (
        <CoverListOption
          label="Tous Dommages au Matériel Informatique et au Mobilier Professionnel"
          option={
            details?.allComputerMaterialDamageOption === true &&
            premium?.allComputerMaterialDamageOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.assistanceAndCyberExtortionOption === true &&
          premium?.assistanceAndCyberExtortionOption != null)) && (
        <CoverListOption
          label="Assistance et Cyber-extorsion"
          option={
            details?.assistanceAndCyberExtortionOption === true &&
            premium?.assistanceAndCyberExtortionOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.businessInterruptionOption === true &&
          premium?.businessInterruptionOption != null)) && (
        <CoverListOption
          label="Pertes d'exploitation"
          option={
            details?.businessInterruptionOption === true &&
            premium?.businessInterruptionOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.thirdPartyDamageCoverageOption === true &&
          premium?.thirdPartyDamageCoverageOption != null)) && (
        <CoverListOption
          label="Dommages causés aux tiers"
          option={
            details?.thirdPartyDamageCoverageOption === true &&
            premium?.thirdPartyDamageCoverageOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.suppliersDeficiencyOption === true &&
          premium?.suppliersDeficiencyOption != null)) && (
        <CoverListOption
          label="Carence des fournisseurs"
          option={
            details?.suppliersDeficiencyOption === true &&
            premium?.suppliersDeficiencyOption != null
          }
        />
      )}

      {(!displayOnlyCover ||
        (details?.cyberAndTelephoneFraudOption === true &&
          premium?.cyberAndTelephoneFraudOption != null)) && (
        <CoverListOption
          label="Fraude cyber et téléphonique"
          option={
            details?.cyberAndTelephoneFraudOption === true &&
            premium?.cyberAndTelephoneFraudOption != null
          }
        />
      )}
    </div>
  )
}

import { Loader } from '@googlemaps/js-api-loader'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import NoOp from '../../../utils/NoOp'
import useDebounce from '../../../utils/useDebounce'

import poweredByGoogle from './powered_google.png'

import { AutoComplete } from '@olino/design-system'

export interface AddressAutoCompleteProps {
  className?: string
  label: string
  line1: string
  onLine1Change?: (line1: string) => void
  onAddressChange?: (address: { line1: string; city?: string; postalCode?: string }) => void
}

type AutocompleteAddress = {
  mainDescription: string
  subDescription?: string
  placeId?: string
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['places'],
})

const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
  className,
  label,
  onLine1Change = NoOp,
  onAddressChange = NoOp,
  line1,
}) => {
  const [query, setQuery] = useState(line1)

  const [placesServices, setPlacesService] = useState<google.maps.places.PlacesService>()
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>()
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()

  useEffect(() => {
    loader.load().then((google) => {
      setAutocompleteService(new google.maps.places.AutocompleteService())
      setSessionToken(new google.maps.places.AutocompleteSessionToken())
      setPlacesService(new google.maps.places.PlacesService(document.createElement('div')))
    })
  }, [])

  const debouncedQuery = useDebounce(query, 300)

  const onQueryChange = (value: string) => {
    setQuery(value)
    onLine1Change(value)
  }

  const { data, isFetching } = useQuery({
    queryKey: ['addressAutocomplete', debouncedQuery],
    queryFn: async (): Promise<AutocompleteAddress[]> => {
      if (!debouncedQuery || !autocompleteService) return []

      const places = await autocompleteService.getPlacePredictions({
        componentRestrictions: { country: 'fr' },
        input: debouncedQuery,
        sessionToken,
        types: ['address'],
      })

      const predictions: AutocompleteAddress[] = places.predictions.slice(0, 4).map((item) => ({
        mainDescription: item.structured_formatting.main_text,
        subDescription: item.structured_formatting.secondary_text,
        placeId: item.place_id,
      }))

      return predictions
    },
  })

  const handleAddressSelected = async (element: AutocompleteAddress) => {
    if (!element || !placesServices) {
      return
    }

    let newLine1 = ''
    let newCity = ''
    let newPostalCode = ''

    placesServices.getDetails(
      { placeId: element.placeId || '', fields: ['address_components'], sessionToken },
      (details) => {
        details?.address_components?.forEach((entry) => {
          if (entry.types.includes('street_number')) newLine1 = entry.long_name + newLine1
          if (entry.types.includes('route')) newLine1 = `${newLine1} ${entry.long_name}`
          if (entry.types.includes('locality')) newCity = entry.long_name
          if (entry.types.includes('postal_code')) newPostalCode = entry.long_name
        })

        setQuery(newLine1)
        onAddressChange({ line1: newLine1, city: newCity, postalCode: newPostalCode })
      }
    )
  }

  return (
    <AutoComplete
      hideSelectedValueBox
      bottomAdornment={
        <div className="w-full bg-grey-50 py-4">
          <img className="mx-auto h-3.5" src={poweredByGoogle} alt="powered by google" />
        </div>
      }
      className={className}
      value={{ mainDescription: line1 }}
      options={data || []}
      onChange={(value) => value && handleAddressSelected(value)}
      getOptionKey={(option) => option.placeId || ''}
      getOptionLabel={(option) => option.mainDescription}
      label={label}
      query={query}
      onQueryChange={onQueryChange}
      render={(item) => (
        <div className="flex flex-col">
          <span>{item.mainDescription}</span>
          <span className="text-xs text-grey-600">{item.subDescription}</span>
        </div>
      )}
      loading={isFetching || debouncedQuery !== query}
    />
  )
}

export default AddressAutoComplete

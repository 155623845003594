import React, { useMemo } from 'react'
import saveAs from 'file-saver'
import { toast, type Id } from 'react-toastify'

import NoOp from '../../utils/NoOp'
import links from '../../constants/links'
import {
  type ProductEnum,
  QuotationDocument,
  useGetQuotationDocumentQuery,
} from '../../generated/graphql'
import { useQuotation } from '../context'
import b64toBlob from '../../utils/b64toBlob'
import { PRODUCTS } from '../../constants/products'

import { Checkbox } from '@olino/design-system'

interface Props {
  value?: boolean
  onChange?: (value: boolean) => unknown
  className?: string
}

const DocumentDownloadText = ({
  name,
  product,
  document,
}: {
  name: string
  product: ProductEnum
  document: QuotationDocument
}) => {
  const { quotation } = useQuotation()

  const [toastId, setToastId] = React.useState<Id | undefined>(undefined)

  const { refetch } = useGetQuotationDocumentQuery(
    {
      quotationId: quotation.id,
      product,
      document,
    },
    {
      enabled: false,

      onSuccess: (data) => {
        if (toastId)
          toast.update(toastId, {
            type: 'info',
            render: `Votre document est prêt`,
            isLoading: false,
            autoClose: 2500,
          })

        saveAs(
          b64toBlob(data.getQuotationDocument, 'application/pdf'),
          `${name} - ${PRODUCTS[product].name}.pdf`
        )
      },
    }
  )

  return (
    <button
      className="underline"
      type="button"
      onClick={() => {
        refetch()
        setToastId(toast.loading('Génération du document'))
      }}
    >
      {name}
    </button>
  )
}

export const CheckoutAgreement: React.FC<Props> = ({
  value = false,
  onChange = NoOp,
  className,
}) => {
  const { quotation } = useQuotation()

  const product = useMemo(() => quotation.details.products?.at(0), [quotation])

  if (!product) return null

  return (
    <div className={className}>
      <Checkbox value={value} onChange={onChange} />
      <div className="text-grey-400 [&_a]:underline">
        Vous reconnaissez avoir reçu, lu et accepté les{' '}
        <DocumentDownloadText
          name="Conditions particulières"
          product={product}
          document={QuotationDocument.Quotation}
        />
        , les{' '}
        <DocumentDownloadText
          name="Conditions générales"
          product={product}
          document={QuotationDocument.GeneralTerms}
        />
        , la{' '}
        <DocumentDownloadText
          name="fiche d'information IPID"
          product={product}
          document={QuotationDocument.Ipid}
        />
        . Vous reconnaissez avoir pris connaissance et accepter respectivement
        <a target="_blank" rel="noreferrer" href={links.POLITIQUE_CONFIDENTIALITE}>
          les politiques de confidentialité de Olino
        </a>
        , du porteur de risque Hiscox, ainsi que{' '}
        <a target="_blank" rel="noreferrer" href={links.CGU}>
          les Conditions Générales de Olino
        </a>
        . En fournissant vos données de paiement et en confirmant ce paiement, vous autorisez Olino
        et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre
        banque pour débiter votre compte des montants indiqués.
      </div>
    </div>
  )
}

import { useQuotation } from '../context'
import formatPrice from '../../utils/formatPrice'

import { OnboardingTitle } from './OnboardingTitle'

import { Hint, Select } from '@olino/design-system'

const guaranteeAmounts = [
  100_000, 200_000, 300_000, 400_000, 500_000, 750_000, 1_000_000, 1_500_000, 2_000_000, 3_000_000,
]

export const PricingRCPGuaranteeAmountSlider = () => {
  const { quotation, updateQuotation } = useQuotation()

  return (
    <div className="flex flex-col gap-3 md:flex-row md:items-center">
      <OnboardingTitle.H3Pricing>
        Plafond de garantie <span className="hidden md:inline-block">:</span>
        <Hint className="visible ml-1 md:hidden">
          La <span className="font-semibold">limite de garantie</span> est la somme maximum
          d'indemnisation que l'assurance vous versera en cas de sinistre.
        </Hint>
      </OnboardingTitle.H3Pricing>

      <Select
        className="min-w-[180px]"
        options={guaranteeAmounts}
        value={quotation.details.publicLiability?.guaranteeAmount || 100_000}
        getOptionLabel={(value) => formatPrice(value, 0)}
        onChange={(value) =>
          updateQuotation({
            details: {
              publicLiability: {
                guaranteeAmount: value,
              },
            },
          })
        }
      />

      <Hint className="hidden md:visible">
        La <span className="font-semibold">limite de garantie</span> est la somme maximum
        d'indemnisation que l'assurance vous versera en cas de sinistre.
      </Hint>
    </div>
  )
}

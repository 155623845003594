import usePartner from '../utils/usePartner'
import cn from '../utils/cn'

import { Logo } from '@olino/design-system'

const PartnerLogo = ({ className }: { className?: string }) => {
  const { partner } = usePartner()

  const partnerLogo = partner?.displayLogo ? partner?.logo?.url : undefined

  return (
    <div className={cn('flex h-10 flex-row items-center justify-center gap-2 lg:h-12', className)}>
      {partnerLogo ? (
        <>
          <div className="flex flex-1 items-center justify-end">
            <div className="flex h-full items-center justify-center overflow-hidden rounded-lg bg-white px-2 py-1">
              <img
                src={partnerLogo}
                alt={`Logo de ${partner?.name}`}
                className="h-8 object-contain"
              />
            </div>
          </div>
          <div className="h-3/4 w-px bg-white" />

          <div className="flex flex-1 items-center justify-start">
            <Logo variant="text" className="h-auto max-h-8 fill-white sm:h-8 sm:max-h-none" />
          </div>
        </>
      ) : (
        <Logo variant="text" className="h-full fill-white" />
      )}
    </div>
  )
}

export default PartnerLogo
